import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
  Button,
  Stepper,
  Step,
  StepConnector,
  StepLabel,
  Card, CardContent, Divider
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { vectorImg, scrollImg, backArrowImg } from "./assets";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import Sidebar from "../../../components/src/Sidebar";
import OrderDetailsController, {
  OrderSeller,
  Props,
} from "./OrderDetailsController.web";
import { ColorlibStepIcon } from "../../../components/src/CommonStepper";
import { Noimageavailable } from "../../categoriessubcategories/src/assets";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    left: "calc(-50% + 6px)",
    right: "calc(50% + 6px)",
  },
  active: {
    "& $line": {
      backgroundColor:"#CCBEB1"
    },

  },
  completed: {
    "& $line": {
      backgroundColor:"#CCBEB1"
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#CBD5E1",
    borderRadius: 1,
  },
})(StepConnector);

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    body1: {
      fontFamily: "Lato, sans-serif",
      color: "#375280",
    },
  },
});

export default class OrderDetails extends OrderDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Header navigation={this.props.navigation} />
        <MainBox data-test-id="mainGrid">
          <Box className="nav-header">
            <Typography className="nav-steps" onClick={this.proceedToHomePage} data-test-id="proceedToHomePage">Home</Typography>
            <img src={vectorImg} />
            <Typography className="nav-steps">My Account</Typography>
            <img src={vectorImg} />
            <Typography className="nav-steps" onClick={this.proceedToOrderManagementPage} data-test-id="proceedToOrderManagementPage">My Order</Typography>
            <img src={vectorImg} />
            <Typography className="nav-steps-active">Order Details</Typography>
          </Box>
          <Grid container className="content-block">
            <Grid item lg={3} md={3}>
              <Box>
                <Typography className="user-name">
                  <img src={scrollImg} />
                  {this.state.profileName.split(' ')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')}
                </Typography>
              </Box>
              <Typography className="welcome-text">
                Welcome to your Account
              </Typography>
              <Sidebar navigation={this.props.navigation}/>
            </Grid>
            <Grid
              item
              xl={8}
              lg={8}
              md={8}
              sm={12}
              xs={12}
            >
              <Box className="details-header">
                <Box className="header-left-block">
                  <img src={backArrowImg} onClick={this.proceedToOrderManagementPage} className="back-arrow" />
                  <Typography className="section-name">
                    Order Details
                  </Typography>
                </Box>
                {<Button className={((this.state.orderDetailsList as OrderSeller)?.attributes?.status !== "in_transit") ? "track-button-opacity" : "track-button"} onClick={()=>this.proceedToTrackOrderPage(({ id: (this.state.orderDetailsList as OrderSeller).id, props: this.props, screenName: "TrackOrder" }))}   disabled={((this.state.orderDetailsList as OrderSeller)?.attributes?.status !== "in_transit")} data-test-id="proceedToTrackOrderPage">Track Order</Button>}
              </Box>
              <Box className="order-data">
                <Box>
                  <Typography className="order-id">
                    Order ID : #{(this.state.orderDetailsList as OrderSeller)?.attributes?.order_number}
                  </Typography>
                  <Typography className="ReturnPurchase-date-weight">
                    Purchase date:{" "}
                    <span className="purchase-date">{this.getFormatDate((this.state.orderDetailsList as OrderSeller)?.attributes?.created_at)}</span>
                  </Typography>
                </Box>
                <Box>
                  <Typography className="total-string">
                    Total: <span className="total-sum">${(this.state.orderDetailsList as OrderSeller)?.attributes?.total}</span>
                  </Typography>
                </Box>
              </Box>
              <Stepper
                alternativeLabel
                activeStep={this.state.activeStep}
                connector={<ColorlibConnector />}
                data-test-id="Stepper"
                className="stepperContainer"
              >
                {[{ orderStatus: 'Order Placed', description: `We have received your order on ${(this.state.orderDetailsList as OrderSeller)?.attributes?.placed_at ? this.getFormatMonth((this.state.orderDetailsList as OrderSeller)?.attributes?.placed_at) : ' '}`}, { orderStatus: 'Order confirmed', description: `Your order has been confirmed. ${(this.state.orderDetailsList as OrderSeller)?.attributes?.confirmed_at ? this.getFormatMonth((this.state.orderDetailsList as OrderSeller)?.attributes?.confirmed_at) : ' '}` }, { orderStatus: 'Order processing', description: `Your order is processing. ${(this.state.orderDetailsList as OrderSeller)?.attributes?.process_at ? this.getFormatMonth((this.state.orderDetailsList as OrderSeller)?.attributes?.process_at) : ' '}` }, { orderStatus: 'Order shipped', description: `Your order is shipped. ${(this.state.orderDetailsList as OrderSeller)?.attributes?.shipped_at ? this.getFormatMonth((this.state.orderDetailsList as OrderSeller)?.attributes?.shipped_at) : ' '}` }, { orderStatus: 'Out for delivery', description: `Your order is out for delivery. ${(this.state.orderDetailsList as OrderSeller)?.attributes?.in_transit_at ? this.getFormatMonth((this.state.orderDetailsList as OrderSeller)?.attributes?.in_transit_at) : ' '}` }, { orderStatus: 'Order Delivered', description: `Your order has been delivered. ${(this.state.orderDetailsList as OrderSeller)?.attributes?.delivered_at ? this.getFormatMonth((this.state.orderDetailsList as OrderSeller)?.attributes?.delivered_at) : ' '}` }].map((label: { orderStatus: string, description: string }) => (
                  <Step>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      <Box>
                        <Box className="stepTitle">{label?.orderStatus}</Box>
                        <Box className="stepDescription">{label?.description}</Box>
                      </Box>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Box className="confirmedBoxContainer">
                <Box className="containerPopup">
                  <Typography variant="body2" className="titlePopup">
                    {this.getFormatMonthDayTime((this.state.orderDetailsList as OrderSeller)?.attributes?.confirmed_at)}
                  </Typography>
                  <Typography variant="body2" className="titlePopup2">
                    Your order has been confirmed. {this.getFormatMonth((this.state.orderDetailsList as OrderSeller)?.attributes?.confirmed_at)}
                  </Typography>
                </Box>
                <Box className="confirmedBox"></Box>
              </Box>
              <Card className="cardContainer">
                <CardContent>
                  {(this.state.orderDetailsList as OrderSeller)?.attributes?.order_items.map((item, index, array) => (
                    <>
                      <Grid key={index} container spacing={2} alignItems="center">
                        <Grid item>
                          <img src={item.attributes.catalogue_variant_front_image ? item.attributes.catalogue_variant_front_image : Noimageavailable} alt={item.attributes.catalogue_name} className="cardTitleImg" />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h6" className="cardTitle">{item.attributes.catalogue_name}</Typography>
                          <Typography variant="body2" className="cardTitle">Color: <span className="cardTitleValue"> {item.attributes.catalogue_variant_color}</span></Typography>
                          <Typography variant="body2" className="cardTitle">Size: <span className="cardTitleValue">{item.attributes.catalogue_variant_size}</span></Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body2" align="right" className="cardTitle">Qty: <span className="cardTitleValue">{item.attributes.quantity}</span></Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body2" align="right" className="cardTitle">${item.attributes.total_price}</Typography>
                        </Grid>
                      </Grid>
                      {index < array.length - 1 && <Divider className="DividerMargin" />}
                    </>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </MainBox>
        <Footer />
      </ThemeProvider>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const MainBox = styled(Box)({
  paddingTop: "27px",
  background: "#F8F8F8",
  "& .containerPopup": {
    display: "flex",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#F6F6F6",
    borderRadius: "8px",
    zIndex: 2,
    position: 'relative',
  },
  "& .titlePopup": {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "left",
    color: "#94A3B8",
  },
  "& .stepperContainer": {
    backgroundColor: "#F6F6F6"
  },
  "& .confirmedBoxContainer": {
    position:"relative",
    maxWidth: "60%",
    borderRadius: "8px",
    border: "1px solid rgb(226, 232, 240)",
    marginLeft: 130,
    marginTop: 20
  },
  "& .ReturnPurchase-date-weight":{
    fontWeight: 800
  },
  "& .confirmedBox": {
    width: 50,
    height: 50,
    position: "absolute",
    backgroundColor: "#F6F6F6",
    transform: "rotate(45deg)",
    top: -10,
    left: "20%",
    zIndex: 1,
    border: "1px solid rgb(226, 232, 240)",
  },
  "& .titlePopup2": {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 700,
    textAlign: "left",
    color: "#375280",
    marginLeft: "36px"
  },
  "& .cardTitle": {
    fontFamily: "Lato",
    fontSize: "22px",
    fontWeight: 700,
    color: "#375280"
  },
  "& .cardContainer": {
    marginTop: '50px',
    marginBottom: '100px',
    boxShadow: "none"
  },
  "& .cardTitleImg": {
    width: '102px',
    height: '102px',
    borderRadius: '3px',
    marginRight: "15px"
  },
  "& .MuiCardContent-root": {
    padding: "40px"
  },
  "& .DividerMargin": {
    margin: '24px 0'
  },
  "& .cardTitleValue": {
    fontFamily: "Lato",
    fontSize: "22px",
    fontWeight: 700,
    color: "#94A3B8"
  },
  "& .stepTitle": {
    fontFamily: "Lato",
    fontSize: "15px",
    fontWeight: 1000,
    textAlign: "center",
    color: "#375280"
  },
  "& .stepDescription": {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 400,
    textAlign: "center",
    color: "#94A3B8"
  },
  "& .nav-header": {
    gap: "10px",
    display: "flex",
    alignItems: "center",
    marginLeft: "40px",
  },
  "& .nav-steps": {
    lineHeight: "22px",
    fontStyle: "normal",
    fontSize: "14px",
    padding: "4px 6px 4px 6px",
    color: "#475569",
  },
  "& .nav-steps-active": {
    fontSize: "14px",
    padding: "4px 6px 4px 6px",
    fontStyle: "normal",
    fontWeight: 1000,
    lineHeight: "22px",
    color: "#475569",
  },
  "& .content-block": {
    marginTop: "2rem",
  },
  "& .user-name": {
    alignItems: "center",
    display: "flex",
    lineHeight: "33.5px",
    marginLeft: "40px",
    fontWeight: 800,
    gap: "15px",
    fontStyle: "normal",
    fontSize: "28px",
  },
  "& .welcome-text": {
    marginBottom: "37px",
    marginLeft: "40px",
    color: "#807D7E",
    lineHeight: "33.5px",
    fontStyle: "normal",
    fontSize: "14px",
  },
  "& .details-header": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .header-left-block": {
    display: "flex",
    gap: "1.5rem",
    alignItems: "center",
  },
  "& .back-arrow": {
    background: "#FFF",
    borderRadius: "100px",
    padding: "0.5rem",
    boxShadow: "0px 2px 8px 0px #00000014",
    cursor: "pointer",
  },
  "& .section-name": {
    fontSize: "1.25rem",
    fontWeight: 800,
  },
  "& .track-button": {
    backgroundColor: "#CCBEB1",
    borderRadius: "2px",
    color: "#FFF",
    fontSize: "1.125rem",
    fontWeight: 700,
    padding: "0.5rem 1.25rem",
    textTransform: "none" as "none",
    fontFamily: "Lato, sans-serif",
  },
  "& .track-button-opacity": {
    backgroundColor: "#CCBEB1",
    borderRadius: "2px",
    color: "#FFF",
    fontSize: "1.125rem",
    fontWeight: 700,
    padding: "0.5rem 1.25rem",
    textTransform: "none" as "none",
    fontFamily: "Lato, sans-serif",
    opacity: "50%"
  },
  "& .return-button": {
    backgroundColor: "#ffffff",
    borderRadius: "2px",
    border: "1px solid rgb(204, 190, 177)",
    color: "rgb(55, 82, 128)",
    fontSize: "1.125rem",
    fontWeight: 700,
    padding: "0.5rem 1.25rem",
    textTransform: "none" as "none",
    fontFamily: "Lato, sans-serif",
  },
  "& .order-data": {
    margin: "2.5rem 0 3rem",
    padding: "1.5rem 2.5rem",
    backgroundColor: "#FFF",
    borderRadius: "2px",
    display: "flex",
    justifyContent: "space-between",
  },
  "& .order-id": {
    fontSize: "1.25rem",
    fontWeight: 800,
    lineHeight: "1.5rem",
    marginBottom: "0.75rem",
  },
  "& .purchase-date": {
    fontWeight: 400,
    color: "#807D7E",
  },
  "& .total-string": {
    fontSize: "1.25rem",
    lineHeight: "1.5rem",
    color: "#94A3B8",
  },
  "& .total-sum": {
    color: "#375280",
    fontWeight: 700,
  },
});

// Customizable Area End
