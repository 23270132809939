import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../../../framework/src/Utilities";
interface IStylishClientBtn {
  id: number;
  btnTitle: string;
  isRedirect: boolean;
  redirectComponentName: string;
}
interface StylistForm {
  id: string;
  type: string;
  attributes: {
    stylist_id: number;
    gender: string;
    colour: string;
    detail: string;
    min_price: number;
    max_price: number;
    status: string;
    buyer_name: string;
    buyer_profile: string | null;
    stylist_name: string;
    stylist_profile: string | null;
    images: {
      url: string;
    }[];
    created_at: string;
  };
};

export interface IStylistRequestResponse {
  id: number;
  requestId: string;
  requestTime: string;
  requesterName: string;
  requesterGender: string;
  budget: string;
  requestStatus: string;
}
// Customizable Area End

export const configJSON = require("../../config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  isAlert: boolean;
  message: string;
  severity: "error" | "success" | "info";
  stylishClientBtnData: IStylishClientBtn[];
  tableHeadName: string[];
  tableBody: IStylistRequestResponse[];
  isSROpen: boolean;
  isAccepted: boolean;
  isRequestOpen: boolean;
  latestRequestsData: StylistForm[];
  requestDataLatest: {
    id: string | number;
    type: string;
    attributes: {
      stylist_id: number;
      gender: string;
      colour: string;
      detail: string;
      min_price: number;
      max_price: number;
      status: string | undefined;
      buyer_name: string;
      buyer_profile: string | null;
      stylist_name: string;
      stylist_profile: string | null;
      images: {
        url: string;
      }[];
      created_at: string;
    };
  } | undefined;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StylishClientsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getLatestRequestsId: string = "";
  getLatestRejectsId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      requestDataLatest: {
        id: "",
        type: "",
        attributes: {
          stylist_id: 0,
          gender: "",
          colour: "",
          detail: "",
          min_price: 0,
          max_price: 0,
          status: "",
          buyer_name: "",
          buyer_profile: "",
          stylist_name: "",
          stylist_profile: "",
          images: [
            {
              url: "",
            },
          ],
          created_at: "",
        },
      },
      isAlert: false,
      message: "",
      severity: "info",
      stylishClientBtnData: StylishClientBtnData,
      tableHeadName: latestRequestTableHead,
      isSROpen: false,
      isAccepted: true,
      isRequestOpen: false,
      tableBody: latestRequestTableBody,
      latestRequestsData: []
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getLatestRequestsId !== null &&
      this.getLatestRequestsId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonLatestRequests = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ latestRequestsData: responseJsonLatestRequests && responseJsonLatestRequests.data ,isSROpen:false})
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getLatestRejectsId !== null &&
      this.getLatestRejectsId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonRejectRequests = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ latestRequestsData: responseJsonRejectRequests && responseJsonRejectRequests.data, isRequestOpen: false ,isSROpen:responseJsonRejectRequests.data.attributes.status === "accepted" ? true : false}, () => {
        setTimeout(() => this.latestRequestsClients(),3000);
      })
    }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    this.latestRequestsClients()
    // Customizable Area End
  }

  handleClientChat = () => {
    this.setState((prevState) => ({
      ...prevState,
      message: "This screen is under development",
      severity: "info",
      isAlert: true,
    }));
  };

  handleStylishClientsRedirect = (redirect: string) => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), redirect);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };

  onAlertSCSnackClose = () => {
    this.setState((prevState) => ({ ...prevState, isAlert: false }));
  };

  handleRequestClose = (stylistId?: number) => {
    const requestData = this.state.latestRequestsData.find(
      (request) => request.attributes.stylist_id === stylistId
    );
    this.setState((prevState) => ({
      ...prevState,
      isRequestOpen: !prevState.isRequestOpen,
      requestDataLatest: requestData,
    }));
  };
  

  latestRequestsClients = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLatestRequestsId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.latestRequestsGet}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  latestCustomForms = async (updateData: string, requestRejectDataId?: number | string) => {
    const token = await getStorageData("auth-token");
    const formData = new FormData();
    formData.append("status", updateData);
    const header = {
      token: token
    };
    const requestMessageRejectList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLatestRejectsId = requestMessageRejectList.messageId;
    requestMessageRejectList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.sellerDetailsAPIMethodPUT
    );
    requestMessageRejectList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.customForms}/${requestRejectDataId}/update_status`
    );
    requestMessageRejectList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageRejectList.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessageRejectList.id, requestMessageRejectList);
  }
  // Customizable Area End
}
// Customizable Area Start
const StylishClientBtnData = [
  {
    id: 1,
    btnTitle: "My Clients",
    isRedirect: true,
    redirectComponentName: "StylishMyClients",
  },
  {
    id: 2,
    btnTitle: "Styling Requests",
    isRedirect: true,
    redirectComponentName: "StylingRequests",
  },
  {
    id: 3,
    btnTitle: "Call Back Requests",
    isRedirect: false,
    redirectComponentName: "",
  },
  {
    id: 4,
    btnTitle: "Create Client Specific Wishlist",
    isRedirect: false,
    redirectComponentName: "",
  },
];

const latestRequestTableHead = [
  "Request ID",
  "Request Time",
  "Name & Gender",
  "Budget",
  "Status",
  "Action"
];

const latestRequestTableBody = [
  {
    id: 1,
    requestId: "#4564-4511",
    requestTime: "3:30 PM",
    requesterName: "Emilia Clark",
    requesterGender: "Female",
    budget: "$50 - $150",
    requestStatus: "New Request"
  },
  {
    id: 2,
    requestId: "#4564-4512",
    requestTime: "3:32 PM",
    requesterName: "Armonion",
    requesterGender: "Male",
    budget: "$30 - $90",
    requestStatus: "New Request"
  },
  {
    id: 3,
    requestId: "#4564-4513",
    requestTime: "3:45 PM",
    requesterName: "Armando",
    requesterGender: "Male",
    budget: "$80 - $120",
    requestStatus: "New Request"
  }
]
// Customizable Area End
