// Customizable Area Start
import React from "react"
import {
    Box,
    Grid,
    InputAdornment,
    Paper,
    TextField,
    Typography,
    styled,
    MenuItem,Badge, Menu, Button, Backdrop, Fade, Modal,ClickAwayListener ,IconButton, 
} from "@material-ui/core";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import SearchSharpIcon from "@material-ui/icons/SearchSharp";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import { setStorageData,removeStorageData,getStorageData } from "../../framework/src/Utilities";
import { shopping, faceLogo, search } from "../../blocks/email-account-registration/src/assets";
import { admin, logout, usericon } from "../../blocks/customisableuserprofiles2/src/assets";
import AuthenticateUser from "../src/AuthenticateUser";
import i18n from "./i18next/i18n";
const frame = require('../../components/src/frame.svg');
const faq = require('../../components/src/image_Icon.svg');


interface S {
    openMenu: boolean;
    anchorEl: HTMLElement | null;
    open1: boolean,
    numberOrder:number | null;
    auth:string;
    modalOpen: boolean;
}
export interface Props {
    navigation?: any;
    id?: string;
}
// Customizable Area End

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}

export default class Header extends BlockComponent<Props, S, SS>{
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {
            openMenu: false,
            anchorEl: null,
            open1: false,
            numberOrder:0,
            auth:"", 
            modalOpen: false
        };
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        // Customizable Area End
    }

    headerRedirect = (block: string) => {
        this.props.navigation.navigate(block)
    };
    headerNavigation = async (pageLink: string, name?: string) => {
        await setStorageData("navigationGender", name)
        const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pageLink);
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(toNavigate);
    };
    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget });
        document.addEventListener('mousedown', this.handleDocumentClick);
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
        document.removeEventListener('mousedown', this.handleDocumentClick);
    };

    handleClose1 = () => {
        this.setState({ open1: false });
    };
    logout = async () => {
        ["orderNumber" ,"auth-token" ,"sms-token","userRole"].map(async value=>{
            await removeStorageData(value)
        })
        this.setState({ open1: false });
        const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), 'Home');
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(toNavigate);
    }
    handleDocumentClick(event:MouseEvent) {
        if (this.state.anchorEl && !this.state.anchorEl.contains(event.target as Node)) {
            this.handleClose();
        }
    }
    handleOpenLogoutModel = () => {
        this.setState({ open1: true,anchorEl: null });
    };
    async componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleDocumentClick);
    }
    async componentDidMount() {
        const authtokenData = await getStorageData('auth-token');
        this.setState({auth:authtokenData})
        const orderNumber = await getStorageData('orderNumber')
        if (orderNumber !== null || orderNumber !== "0") {
            this.setState({ numberOrder: orderNumber });
        } else {
            await removeStorageData("orderNumber");
        }
    }
    async componentDidUpdate() {
        const orderNumber = await getStorageData('orderNumber');
        if (orderNumber !== this.state.numberOrder) {
            if (orderNumber === null || orderNumber === "0") {
                await removeStorageData("orderNumber");
                this.setState({ numberOrder: null });
            } else {
                this.setState({ numberOrder: orderNumber });
            }
        }
    }
   

    renderDialog = () => {
        return (
           <AuthenticateUser 
                auth={this.state.auth} 
                title={this.translate("Log in to view stylists.")}
                description={this.translate("View list of stylists")}
                btnText={this.translate("Log In or Sign up")}
                navigation={this.props.navigation}
                onClose={this.closeModal}
            />
        )
    }

    handleHeaderButtonClick = () => {
        if(this.state.auth === null) {
            this.setState({ modalOpen: true})
          } else {
            this.headerNavigation("HiredStylist")
        }
    }

    closeModal = () => {
        this.setState({modalOpen: false});
    };
    
    
    translate = (key: string) => {
        return i18n.t(key, { ns: "header" } )
    }

    render() {
        // Customizable Area Start
        return (
            <>
            <StyledWrapper>
                <Grid className="headerMainGrid" container dir={i18n.dir()}>
                    <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="topContent"
                    >
                        <Typography
                            align="center"
                            style={{ fontWeight: 400 }}
                            className="topContentText"
                        >
                            {this.translate("TopTitle")}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xl={4}
                        lg={4}
                        md={2}
                        sm={4}
                        xs={5}
                        className="logoSection"
                    >
                        <MenuRoundedIcon
                            className="menu"
                            onClick={() => {
                                this.setState({ openMenu: !this.state.openMenu });
                            }}
                        />

                        {this.state.openMenu && (
                            <Paper className="menuItems" style={{right: i18n.dir() === "rtl" ? "0" : "unset", left: i18n.dir() === "rtl" ? "unset" : "0"}}>
                                <Typography
                                    className="menuLink"
                                >
                                    {this.translate("Home")}
                                </Typography>
                                <Typography
                                    className="menuLink"
                                >
                                    {this.translate("Men")}
                                </Typography>
                                <Typography
                                    className="menuLink"
                                >
                                    {this.translate("Women")}
                                </Typography>
                                <Typography
                                    className="menuLink"
                                >
                                    {this.translate("Kids")}
                                </Typography>
                                <Typography
                                    className="menuLink"
                                >
                                    {this.translate("Stores")}
                                </Typography>
                                <Typography
                                    className="menuLink"
                                >
                                    {this.translate("Stylist")}
                                </Typography>
                            </Paper>
                        )}
                        <Box onClick={() => {
                            this.headerRedirect("LandingPage")
                        }}>
                            <img src={faceLogo} alt="fashLogo" className="logo" />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xl={4}
                        lg={4}
                        md={5}
                        sm={4}
                        xs={3}
                        className="linkSection"
                        dir={i18n.dir()}
                    >
                        <Typography
                            className="link"
                            onClick={() => {
                                this.headerRedirect("LandingPage")
                            }}
                        >
                            {this.translate("Home")}
                        </Typography>
                        <Typography
                            className="link"
                            onClick={() => {
                                this.headerNavigation("CategoriessubcategoriesWebPage", "Men")
                            }}
                        >
                            {this.translate("Men")}
                        </Typography>
                        <Typography className="link" onClick={() => {
                            this.headerNavigation("CategoriessubcategoriesWebPage", "Women")
                        }}>
                            {this.translate("Women")}
                        </Typography>
                        <Typography className="link" onClick={() => {
                            this.headerNavigation("CategoriessubcategoriesWebPage", "Kids")
                        }}>
                            {this.translate("Kids")}
                        </Typography>
                        <Typography className="link" >
                            {this.translate("Stores")}
                        </Typography>
                        <Typography className="link" onClick={this.handleHeaderButtonClick}>
                            {this.translate("Stylist")}
                        </Typography>
                        <TextField
                            variant="outlined"
                            placeholder={this.translate("Search")}
                            className="searchBoxTab"
                            dir={i18n.dir()}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={search} />
                                    </InputAdornment>
                                ),
                                // style: {
                                //     display: "flex",
                                //     maxWidth: "270px",
                                //     height: "40px",
                                //     justifyContent: "flex-start",
                                //     borderRadius: "2px",
                                //     border: "1px solid var(--Neutrals-Cool-gray-300, #CBD5E1)",
                                // },
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        xl={4}
                        lg={4}
                        md={5}
                        sm={4}
                        xs={4}
                        className="profileSection"
                    >
                        <TextField
                            variant="outlined"
                            placeholder={this.translate("Search")}
                            className="searchBox"
                            dir={i18n.dir()}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={search} className="searchBoxIcon" />
                                    </InputAdornment>
                                ),
                                style: {
                                    display: "flex",
                                    maxWidth: "170px",
                                    height: "40px",
                                    justifyContent: "flex-start",
                                    borderRadius: "2px",
                                },
                            }}
                        />
                        <SearchSharpIcon className="searchIcon" />
                        <CustomBadge badgeContent={this.state.numberOrder} showZero onClick={()=>this.headerRedirect("ProductdetailsWeb")}>
                            <img src={shopping} alt="shopping" />
                        </CustomBadge>
                        <div>
                            <ClickAwayListener onClickAway={this.handleClose}>
                                <Box aria-controls="simple-menu" className="profileBox" aria-haspopup="true" onClick={this.handleClick}>
                                    <IconButton onClick={this.handleClick} aria-controls="simple-menu" aria-haspopup="true">
                                        <AccountCircleOutlinedIcon style={{color: "#375280"}}/>
                                        <ExpandMoreOutlinedIcon style={{color: "#375280"}}/>
                                    </IconButton>

                                        <Custommenu
                                                style={{ marginTop: '40px', }}
                                                id="simple-menu"
                                                keepMounted
                                                anchorEl={this.state.anchorEl}
                                                open={Boolean(this.state.anchorEl)}
                                                onClose={this.handleClose}
                                                dir={i18n.dir()}
                                            >
                                               <Box style={{ padding: '5px 15px' }} dir={i18n.dir()}>
                                                {this.state.auth === null ? (
                                                    <>
                                                    <MenuItem className="itemtext" dir={i18n.dir()} onClick={() => { this.headerRedirect("EmailAccountRegistration")}}> <img src={usericon} style={{ marginRight: i18n.dir() === 'rtl' ? '0':'10px', marginLeft: i18n.dir() === 'rtl' ? '10px': '0' }} />Sign Up</MenuItem>
                                                    <MenuItem className="itemtext" dir={i18n.dir()} onClick={() => { this.headerRedirect("EmailAccountLoginBlock")}}> <img src={admin} style={{ marginRight: i18n.dir() === 'rtl' ? '0':'10px', marginLeft: i18n.dir() === 'rtl' ? '10px': '0' }} />Sign In</MenuItem>
                                                    </>
                                                ) : (
                                                    <>
                                                        <MenuItem className="itemtext" dir={i18n.dir()} onClick={() => { this.headerRedirect("Customisableuserprofiles2") }}> <img src={usericon} style={{ marginRight: i18n.dir() === 'rtl' ? '0':'10px', marginLeft: i18n.dir() === 'rtl' ? '10px': '0' }} />My Account</MenuItem>
                                                        <MenuItem className="itemtext" dir={i18n.dir()} onClick={() => { this.headerRedirect("Contactus") }}> <img src={frame} style={{ marginRight: i18n.dir() === 'rtl' ? '0':'10px', marginLeft: i18n.dir() === 'rtl' ? '10px': '0' }} />Contact Us</MenuItem>
                                                        <MenuItem className="itemtext" dir={i18n.dir()} onClick={() => { this.headerRedirect("Faqs") }}> <img src={faq} style={{ marginRight: i18n.dir() === 'rtl' ? '0':'10px', marginLeft: i18n.dir() === 'rtl' ? '10px': '0' }} />FAQs</MenuItem>
                                                        <MenuItem className="itemtextlogout" dir={i18n.dir()} onClick={this.handleOpenLogoutModel}>{this.state.auth === null ? "" : <img src={logout} style={{ marginRight: i18n.dir() === 'rtl' ? '0':'10px', marginLeft: i18n.dir() === 'rtl' ? '10px': '0' }} />}{this.state.auth === null ? "Sign In" : "Logout"}</MenuItem>
                                                    </>
                                                )}
                                                </Box>
                                            </Custommenu>
                                </Box>
                            </ClickAwayListener>
                        </div>
                        <StyledDrawer
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={this.state.open1}
                            onClose={this.handleClose1}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                            dir={i18n.dir()}
                        >
                            <Fade in={this.state.open1} style={{ borderRadius: '10px', maxWidth: "320px", backgroundColor: "white" }}>
                                <div style={{ textAlign: "center", padding: "10px 30px 30px 30px" }}>
                                    <Box id="transition-modal-title" style={{ fontFamily: 'Lato', width: "300px", fontSize: '18px', color: "#375280", fontWeight: 900, marginLeft: "10px" }}>{this.state.auth === null ? "" : this.translate("Log Out")}</Box>
                                    <h2 id="transition-modal-title" style={{ fontFamily: 'Lato', width: "300px", fontSize: '18px', color: "#375280", fontWeight: 600, marginLeft: "10px" }}>{this.state.auth === null ? this.translate("accessProfile") : this.translate("accessProfile")}</h2>
                                    <div style={{ marginTop: "15px", display: "flex", gap: "20px", justifyContent: "space-between" }}>
                                    <StyledButton style={{ fontSize: "18px", lineHeight: "20px" }} onClick={() => this.logout()}>{this.translate("Log Out")}</StyledButton>
                                    <StyledButton1 style={{ fontSize: "18px" }} onClick={this.handleClose1}>{this.translate("Cancel")}</StyledButton1>
                                    </div>
                                </div>
                            </Fade>
                        </StyledDrawer>
                    </Grid>
                </Grid>
            </StyledWrapper>
            {this.state.modalOpen && this.renderDialog()}
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start

const Custommenu = styled(Menu)({
    width:'260px',
    height:'395px',
    "& .MuiMenu-paper": {
        top: "46px !important"
    },
    "& .itemtext": {
        color: "#375280",
        padding: '10px',
        borderBottom: '1px solid #E2E8F0',
        fontFamily: 'Lato',
        fontWeight: 500,
        fontSize: '16px',
    },
    "& .itemtextlogout":{
        color: "#F87171",
        padding: '10px',
        fontFamily: 'Lato',
        fontWeight: 500,
        fontSize: '16px',
    }
});

const StyledWrapper = styled(Box)({
    "& .headerMainGrid": {
        borderBottom: "1px solid var(--Neutrals-Cool-gray-200, #E2E8F0)",
    },
    "& .topContent": {
        padding: "3px 10px 5px 10px !important",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        backgroundColor: "#334155",
    },
    "& .topContentText": {
        color: "#fff !important",
        fontFamily: "Lato , sans-serif;",
        fontSize: "14px !important",
        fontStyle: "normal !important",
    },
    "& .logoSection": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        padding: "20px",
    },
    "& .menu": {
        display: "none !important",
        color:
            "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)) !important",
        margin: "5px 10px 5px 10px !important",
        cursor: "pointer",
    },
    "& .linkSection": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        flexWrap: "wrap",
    },
    "& .link": {
        display: "block !important",
        margin: "0 10px 0 10px !important",
        cursor: "pointer",
        color:
            "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)) !important",
        fontFamily: "Lato , sans-serif;",
        fontSize: "16px !important",
        fontStyle: "normal !important",
        fontWeight: 500,
        lineHeight: "18px !important"
    },
    "& .profileSection": {
        padding: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexWrap: "wrap",
    },
    "& .menuItems": {
        display: "none !important",
        position: "absolute",
        padding: "10px",
        top: "103px",
        width: "150px",
        left: "0",
        alignItems: "center",
        justifyContent: "flex-end",
        flexDirection: "column",
    },
    "& .menuLink": {
        margin: "10px !important",
        cursor: "pointer",
        color:
            "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)) !important",
        fontFamily: "Lato, sans-serif;",
        fontSize: "16px !important",
        fontStyle: "normal !important",
        fontWeight: 500,
        lineHeight: "18px !important",
        "&:hover": {
            textDecoration: "underline",
        },
    },
    "& .searchBox": {
        margin: "5px 60px 5px 10px !important",
        marginLeft: `${i18n.dir() === "ltr"? "24px !important" : "5px" }`,
        display: "block !important",
    },
    "& .searchIcon": { display: "none !important" },
    "& .searchBoxTab": { display: "none !important" },
    "& .searchBoxIcon": {
        color:
            "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)) !important",
    },
    "& .cartIcon": {
        margin: "5px 10px 5px 10px",
        cursor: "pointer",
    },
    "& .profileBox": {
        margin: "5px 10px 5px 10px !important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color:
            "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)) !important",
        cursor: "pointer",
        fontWeight: 100,
    },
    "@media screen and (min-width: 600px) and (max-width: 959px)": {
        "& .link": { display: "none !important" },
        "& .searchBox": { display: "none !important" },
        "& .searchBoxTab": { display: "block !important" },

        "& .cartIcon": {
            margin: "5px",
        },
        "& .menu": { display: "block !important" },
        "& .menuItems": {
            display: "flex !important",
        },
        "& .profileBox": {
            margin: "5px !important",
        },
        "& .logo": {
            width: "100%",
        },
    },
    "@media screen and (min-width: 0px) and (max-width: 599px)": {
        "& .link": { display: "none !important" },
        "& .topContent": {
            padding: "3px 5px 5px 5px !important",
        },
        "& .topContentText": { fontSize: "10px !important" },
        "& .": { display: "none !important" },
        "& .searchBoxTab": { display: "block !important" },
        "& .logoSection": { padding: "10px 5px 10px 5px" },
        "& .linkSection": { padding: "10px 5px 10px 5px" },
        "& .profileSection": { padding: "10px 5px 10px 5px" },
        "& .menu": { display: "block !important" },
        "& .menuItems": {
            display: "flex !important",
            top: "84px",
        },
        "& .cartIcon": {
            margin: "5px !important",
        },
        "& .profileBox": {
            margin: "5px !important",
        },
        "& .logo": {
            width: "100%",
        },
    }
})

export const StyledDrawer = styled(Modal)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "none",
}));

export const StyledButton = styled(Button)(({ theme }) => ({
    fontWeight: 800,
    backgroundColor: "#d16262", color: '#fff',
    border: "1px solid #d16262",
    textTransform: "none",
    width: "150px",
    height: "45px",
    borderRadius: "2px",
    '&:hover': {
        backgroundColor: "#d16262",
    },
}));

export const StyledButton1 = styled(Button)(({ theme }) => ({
    fontWeight: 800,
    color: "#375280",
    width: "150px",
    height: "45px",
    textTransform: "none",
    border: '1px solid #c6c2b9',
    '&:hover': {
        backgroundColor: "#fff"
    },
}));

export const StyledButtonSigein = styled(Button)(({ theme }) => ({
    fontWeight: 800,
    color: "#fff",
    width: "150px",
    height: "45px",
    textTransform: "none",
    border: '1px solid #c6c2b9',
    backgroundColor:"#CCBEB1",
    '&:hover': {
        backgroundColor: "#CCBEB1"
    },
}));
const CustomBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#cdc1b6',
        color: '#375280',
        fontSize: '10px',
        height: '16px',
        minWidth: '16px',
        padding: '0 4px',
        borderRadius: '50%',
    },
}));
// Customizable Area End
