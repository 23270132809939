// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { brandImage } from "./assets";
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End


export const configJSON = require("./config");

interface AnalyticsData {
  analyticsType: string,
  type: string
}

interface StoreReportProduct {
    filter_range: string,
    max_value: string,
    average_order_value: string,
    sales_volume: number,
    sold_units: 0,
    returned_units: 0,
    graph_data: {
        total_revenue: {
          "jan": number
        }
}
} 
interface VolumeReportStore {
    filter_range: string,
    selected_month: string,
    total_sold_units: number,
    sold_units: number,
    returned_units: number
}

interface WeeklyData {
  Mon: string;
  Tue: string;
  Wed: string;
  Thu: string;
  Fri: string;
  Sat: string;
  Sun: string;
}

interface MonthlyData {
  Jan: number;
  Feb: number;
  Mar: number;
  Apr: number;
  May: number;
  Jun: number;
  Jul: number;
  Aug: number;
  Sep: number;
  Oct: number;
  Nov: number;
  Dec: number;
}

interface TotalRevenue {
  current_week: WeeklyData;
  previous_week: WeeklyData;
  current_month: MonthlyData;
  previous_month: MonthlyData;
}

interface GrowthResponse {
  selected_month: string;
  filter_range: string;
  sales_revenue: string;
  profit: boolean;
  loss: boolean;
  profit_percentage: string;
  loss_percentage: string;
  total_revenue: TotalRevenue;
}

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  analyticsData: AnalyticsData[],
  activePage: string,
  storeActivePage: string,
  companynameData: {companyIcon: string,
    companyName: string
  }[],
  productChecked: string,
  storeChecked: string,
  growthSelectStore: boolean;
  growthSelectProduct: boolean;
  token: string;
  totalRevenue: string;
  storeReportProduct: StoreReportProduct;
  reportProductPeriod: string;
  volumeStore: VolumeReportStore;
  leftIndex: number;
  rightIndex: number;
  pieChartData : {
    label: string,
    value: number,
    color: string
}[];
growthResponse: any,
productSelected: any;
storeSelected: any;
catalogueProduct: any;
storesData: any;
checkedAllStore: string;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StylistAnalyticsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTotalRevenueApiCallID: string = "";
  getSalesReportApiCallID: string = "";
  getSalesReportProductApiCallID: string = "";
  getVolumeReportStoreApiCallID: string = "";
  getGrowthReportStoreApiCallID: string = "";
  getCatalogueProductCallID: string = "";
  getStoreCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      leftIndex: 0,
    rightIndex: 6,
      analyticsData: [{
        analyticsType: "Sales Revenue Report",
        type: "store"
      },{
        analyticsType: "Sales Volume Report Store",
        type: "volume"

      },{
        analyticsType: "Sales Volume Report Product",
        type: "volumeProduct"

      },{
        analyticsType: "Sales Growth Report",
        type: "growth"

      }],
      activePage: "",
      storeActivePage: "salesStore",
      companynameData: [{
        companyIcon: brandImage,
        companyName: "Fashion Fusion Hub"
      },{
        companyIcon: brandImage,
        companyName: "Trendy"
      },{
        companyIcon: brandImage,
        companyName: "H&M"
      },{
        companyIcon: brandImage,
        companyName: "Zara"
      },{
        companyIcon: brandImage,
        companyName: "The Souled Store"
      }],
      productChecked:"",
      storeChecked: "",
      growthSelectStore: false,
      growthSelectProduct: false,
      token: "",
      totalRevenue: "0",
      storeReportProduct: {filter_range: "",
      max_value: "",
      average_order_value: "",
      sales_volume: 0,
      sold_units: 0,
      returned_units: 0,
      graph_data: {
          total_revenue: {
            "jan": 0
          }
      }},
      growthResponse: {},
  reportProductPeriod: "monthly",
  volumeStore: {
    filter_range: "",
    selected_month: "",
    total_sold_units: 0,
    sold_units: 0,
    returned_units: 0
    },
    pieChartData: [{
      label: "Units Sold",
      value: 50,
      color: "#375280"
  },
  {
      label: "Returned Units",
      value: 20,
      color: "#F59E0B", 
  },
  ],
  productSelected: {},
  storeSelected: {},
  catalogueProduct: [],
  storesData: [],
  checkedAllStore: "true"
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      runEngine.debugLog("API Message Recived", message);
      if (apiRequestCallId === this.getTotalRevenueApiCallID) {
        if(responseJson?.data){
          this.setState({
           totalRevenue: responseJson?.data?.total_revenue
          })
        }
      }else if(apiRequestCallId === this.getSalesReportApiCallID){
        if(responseJson?.data){
          this.setState({
            storeReportProduct: responseJson.data
          });
          this.getGraphData(responseJson?.data?.graph_data?.total_revenue)
        }
      }else if(apiRequestCallId === this.getVolumeReportStoreApiCallID){
        if(responseJson?.data){
          const updatedData =  [{
            label: "Units Sold",
            value: responseJson.data.sold_units,
            color: "#375280"
        },
        {
            label: "Returned Units",
            value: responseJson.data.returned_units,
            color: "#F59E0B", 
        },
        ]
          this.setState({
            volumeStore: responseJson?.data,
            pieChartData: updatedData
          })
        }
    }   else {
        this.handleApiCall(apiRequestCallId, message)
    }
      }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    const token = await getStorageData("auth-token");
    this.setState({
      token: token
    });
    this.setState({leftIndex: new Date().getMonth() > 6 ? 6 : 0, rightIndex: new Date().getMonth()> 6 ? 12 : 6})
   this.getTotalRevenue(token);
   this.getSalesReport("monthly");
   this.getVolumeStore("monthly");
   this.getGrowthStore("monthly");
    // Customizable Area End
  }

  getGraphData = (totalRevenue: any) => {
    if(Object.keys(totalRevenue).length > 1){
      const graphData = Object.values(totalRevenue);
      return graphData
    }
    return [0]
  };

  handleApiCall = (apiRequestCallId : string, message: Message) => {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallId === this.getCatalogueProductCallID){
      if(responseJson?.data){
        this.setState({
          catalogueProduct: responseJson.data
        });
      }
    }
    else if(apiRequestCallId === this.getStoreCallID){
      if(responseJson?.data){
        this.setState({
          storesData: responseJson?.data
        })
      }
    }else if(apiRequestCallId === this.getSalesReportProductApiCallID){
      if(responseJson?.data){
        this.setState({
          storeReportProduct: responseJson.data
        });
        this.getGraphData(responseJson?.data?.graph_data?.total_revenue)
      }
    }else if(apiRequestCallId === this.getGrowthReportStoreApiCallID){
      this.setState({
        growthResponse: responseJson.data
      });
    }
  }
  getStores = async() => {

    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
    };
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStoreCallID = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getStoreEndPoint}?approved=true`
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  getProductCatalogue = async() => {

    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
    };
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCatalogueProductCallID = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getCatalogueProductsEndPoint}?my_catalogues=true&page=1&per_page=10`
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  getGraphDataDirect = () => {
    if(Object.keys(this.state.storeReportProduct.graph_data.total_revenue).length > 1){
      const graphData = Object.values(this.state.storeReportProduct.graph_data.total_revenue);
      return graphData
    }
    return [0]
  };

  getGraphDataGrowth = (type: string) => {
    if(type === "current" && this.state.growthResponse && Object.keys(this.state.growthResponse)?.length > 1){
      const arrayObj = this.state.growthResponse?.total_revenue?.current_month && Object.keys(this.state.growthResponse.total_revenue?.current_month);
      if(arrayObj?.length > 0){
        const graphData = Object.values(this.state.growthResponse.total_revenue.current_month[arrayObj[0]]);
        return graphData as number[]
      }
    }else if(type === "prev" && this.state.growthResponse &&  Object.keys(this.state.growthResponse).length > 1){
      const arrayObj = this.state.growthResponse?.total_revenue?.previous_month && Object.keys(this.state.growthResponse.total_revenue?.previous_month);
      if(arrayObj?.length > 0){
        const graphData = Object.values(this.state.growthResponse.total_revenue.previous_month[arrayObj[0]]);
        return graphData as number[]
      }
    }
    return [0]
  }

  getGraphDataGrowthWeekly = (type: string) => {
    if(type === "current" && this.state.growthResponse &&  Object.keys(this.state.growthResponse).length > 1 ){
        const arrayObj = this.state.growthResponse?.total_revenue?.current_week && Object.keys(this.state.growthResponse.total_revenue?.current_week);
        if(arrayObj?.length > 0){
            const graphData = Object.values(this.state.growthResponse.total_revenue.current_week[arrayObj[0]]);
            return graphData as number[]
          }
        }else if(type === "prev" && this.state.growthResponse && Object.keys(this.state.growthResponse).length > 1){
        const arrayObj = this.state.growthResponse?.total_revenue?.previous_week && Object.keys(this.state.growthResponse?.total_revenue?.previous_week);
      if(arrayObj?.length > 0){
        const graphData = Object.values(this.state.growthResponse.total_revenue.previous_week[arrayObj[0]]);
        return graphData as number[]
      }
    }
    return [0]
  }
  handleArrowClick = (type: string) => {
    if(type === 'left') {
      this.setState({leftIndex: 0, rightIndex: 6})
    }
    if(type === 'right') {
      this.setState({leftIndex: 6, rightIndex: 12})
    }
  }
  handleRedirect = (redirect:string) =>{
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), redirect);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  }

  handleSelectStoreClose = () => {
    this.setState({
      growthSelectStore: false
    })
  };

  handlePeriodChangeStore = (value: string) => {
    this.setState({
      reportProductPeriod: value
    }, () => (this.state.storeActivePage === "salesStore" ?  this.getSalesReportStore() :  this.getSalesReportProduct()));
  }

  handlePeriodChangeVolume = (value: string) => {
    this.setState({
      reportProductPeriod: value
    }, () => this.state.activePage === "volume"? this.getVolumeStoreId(): this);
    this.getVolumeStoreId()
  }

  handlePeriodChangeGrowth = (value: string) => {
    this.setState({
      reportProductPeriod: value
    });
    this.getGrowthStore(value)
  }

  getTotalRevenue = async(Authtoken: string) => {
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: Authtoken,
    };
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTotalRevenueApiCallID = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getTotalRevenueEndPoint}`
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  getSalesReport = async(filterRange: string) => {
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
    };
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSalesReportApiCallID = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getSalesReportProductEndPoint}?filter_range=${filterRange}`
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };

  getSalesReportProduct = async() => {
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
    };
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSalesReportProductApiCallID = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getSalesReportProdEndPoint}${this.state.productChecked}?filter_range=${this.state.reportProductPeriod}`
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  getSalesVolumeProduct = async() => {
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
    };
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVolumeReportStoreApiCallID = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getVolumeStoreProdEndPoint}${this.state.productChecked}?filter_range=${this.state.reportProductPeriod}`
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  getSalesReportStore = async() => {
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
    };
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSalesReportApiCallID = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getSalesReportProductEndPoint}?filter_range=${this.state.reportProductPeriod}&id=${this.state.storeChecked}`
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  getGrowthStore = async(filterRange: string) => {
    const currentMonth = new Date().getMonth();

    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
    };
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getGrowthReportStoreApiCallID = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getGrowthStoreEndPoint}?filter_range=${filterRange}&month=${configJSON.monthLabel[currentMonth]}`
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  getVolumeStore = async(filterRange: string) => {
    const currentMonth = new Date().getMonth();

    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
    };
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVolumeReportStoreApiCallID = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getVolumeStoreEndPoint}?filter_range=${filterRange}&month=${configJSON.monthLabel[currentMonth]}`
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  getVolumeStoreId = async() => {
    const currentMonth = new Date().getMonth();

    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
    };
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVolumeReportStoreApiCallID = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getVolumeStoreEndPoint}?filter_range=${this.state.reportProductPeriod}&month=${configJSON.monthLabel[currentMonth]}&id=${this.state.storeChecked}`
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  handleGrowthDialogOpen = () => {
    this.getStores();
    this.setState({growthSelectStore: true});
  }

  handleStoreDialogOpen = () => {
    this.getProductCatalogue();
    this.setState({growthSelectProduct: true});
  }

  handlegrowthSelectProduct = () => {
    this.setState({
      growthSelectProduct: false
    })
  }

  handlePageChange = (active: string) => {
    if( active === "volumeProduct"){
      this.setState({storeActivePage: "salesProduct", activePage: active})
      this.getVolumeStore("monthly")
    }else{
      this.setState({
        activePage: active,
        storeActivePage: "salesStore"
      })
    }

    if(active === "store"){
      this.setState({
        productSelected: {},
        storeChecked: "",
        checkedAllStore: "true"
      }, () => {this.getSalesReport("monthly")})
    }else if(active === "volume" || active === "volumeProduct"){
      this.setState({
        productSelected: {},
        storeChecked: "",
        checkedAllStore: "true"
      }, () => {this.getVolumeStore("monthly")})
      
    }else if(active === "growth"){
      this.getGrowthStore("monthly");
    }
  };

  onChangeHandler = (value: string) => {
   this.setState({ productChecked: value});
  };

  handleStoreSubmit = () => {
    const storeData = this.state.storesData.find((element: any) => element.id === this.state.storeChecked)
    if(storeData || this.state.checkedAllStore == "true"){
      this.setState({
        growthSelectStore:false,
        storeSelected: storeData, checkedAllStore: ""
      });
      if(this.state.activePage === "store"){
        this.getSalesReportStore();
      }

      if(this.state.activePage === "volume"){
        this.getVolumeStoreId();
      }
    }
  }
   
  onChangeHandlerStore = (value: string) => {
    this.setState({
      storeChecked: value,
      checkedAllStore: ""
    })
  };

  handleAllSelectStore  = (value: string) => {
    this.setState({
      checkedAllStore: value,
      storeChecked: "",
    })
  }

  handleProductSelect = () => {
    const productReport = this.state.catalogueProduct.find((element: any)=> element.id === this.state.productChecked);
    if(productReport){
      this.setState({
        productSelected: productReport,
        growthSelectProduct: false
      });
      if(this.state.activePage === "store"){
        this.getSalesReportProduct();
      }
      if(this.state.activePage === "volumeProduct"){
       this.getSalesVolumeProduct();
      }
    }
  }
  
  handleStoreTab = (path: string) => {
    this.setState({
      storeActivePage: path
    });
  }
  // Customizable Area End
}
// Customizable Area Start

export const saleCount = [
    {label:"Total Sales",value:"$ 24,00"},
    {label:"Impression",value:"300"},
    {label:"Total Orders",value:"350"},
    {label:"Visitors",value:"3000"}
]

export const pieChartData = [{
    label: "Units Sold",
    value: 50,
    color: "#375280"
},
{
    label: "Returned Units",
    value: 20,
    color: "#F59E0B", 
},
]

export const lineChartData = [{
    label: "Current Period",
    color: "#059669", 
},
{
    label: "Last Period",
    color: "#F87171", 
}
]

export const monthLabel = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]
// Customizable Area End
