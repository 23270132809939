import React from "react";
// Customizable Area Start
import {
    Box, Grid
} from "@material-ui/core";
import SellersAllOrderDetailsController, {
    Props,
} from "./SellersAllOrderDetailsController.web";
import SellerSideBar from "../../../../components/src/Seller/SellerSideBar";
import SellerHeader from "../../../../components/src/Seller/SellerHeader";
// Customizable Area End

export default class SellersAllOrderDetailsWebPage extends SellersAllOrderDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Box style={{ display: 'flex' }}>
                    <SellerSideBar navigation={this.props.navigation} />
                    <Grid container>
                        <Grid item lg={12} md={12}>
                            <SellerHeader navigation={this.props.navigation} />
                            <Box data-testid="order_number">All Orders</Box>
                        </Grid>
                    </Grid>
                </Box>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End