export const enSidebar = {
    "My Orders": "My Orders",
    "Wishlist": "Wishlist",
    "My Profile": "My Profile",
    "Payment History": "Payment History",
    "Save Cards": "Save Cards",
    "Stylist": "Stylist",
    "Address": "Address",
    "Language and Currency": "Language and Currency",
    "Logout": "Logout",
    "Profile": "Profile",
    "Home": "Home",
    "Welcome to your Account": "Welcome to your Account",
    "Dashboard": "Dashboard",
    "Catalogue": "Catalogue",
    "Orders": "Orders",
    "Stores": "Stores",
    "Analytics and Insights": "Analytics and Insights",
    "Earnings": "Earnings",
    "Your Activity": "Your Activity",
    "Get Help": "Get Help",
    "Coming Soon !!!": "Coming Soon !!!",
    "Log Out": "Log Out",
    "Cancel": "Cancel",
    "You need to sign in to access Profile": "You need to sign in to access Profile",
    "Are you sure you want to log out your account?": "Are you sure you want to log out your account?",
    "Sign In": "Sign In",
    "Clients":	"Clients",
    "Sales Report":	"Sales Report",
    "Inventory Management":	"Inventory Management",
    "Portfolio":	"Portfolio",
    "Explore as Buyer": "Explore as Buyer"
}

export const arSidebar = {
    "My orders": "أوامري",
    "Wishlist": "قائمة الرغبات",
    "My Profile": "ملفي الشخصي",
    "Payment History": "تاريخ الدفع",
    "Save Cards": "حفظ البطاقات",
    "Stylist": "المصممون",
    "Address": "عنوان",
    "Language and Currency": "اللغة والعملة",
    "Logout": "تسجيل الخروج",
    "Profile": "حساب تعريفي",
    "Home": "الصفحة الرئيسية",
    "Welcome to your Account": "مرحبًا بك في حسابك",
    "Dashboard": "لوحة القيادة",
    "Catalogue": "كتالوج",
    "Orders": "طلبات",
    "Stores": "متاجر",
    "Analytics and Insights": "التحليلات والرؤى",
    "Earnings": "الأرباح",
    "Your Activity": "نشاطك",
    "Get Help": "احصل على المساعدة",
    "Coming Soon !!!": "قريباً !!!",
    "Log Out": "تسجيل الخروج",
    "Cancel": "يلغي",
    "You need to sign in to access Profile": "تحتاج إلى تسجيل الدخول للوصول إلى الملف الشخصي",
    "Are you sure you want to log out your account?": "هل أنت متأكد أنك تريد تسجيل الخروج من حسابك؟",
    "Sign In": "تسجيل الدخول",
    "Clients":	"العملاء",
    "Sales Report":	"تقرير المبيعات",
    "Inventory Management":	"إدارة المخزون",
    "Portfolio": "مَلَفّ",
    "Explore as Buyer": "استكشف كمشتري"
}
