// Customizable Area Start
import React from "react"
import {
    Box,
    Grid,
    InputAdornment,
    Paper,
    TextField,
    Typography,
    styled,Snackbar
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { wishlist, stylist, setting, saveCard, reviews, paymentHistory, myProfile, myOrder, loyality, address, logout, sidebarimg } from "../../blocks/customisableuserprofiles2/src/assets";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { setStorageData, removeStorageData ,getStorageData } from "../../framework/src/Utilities";
import Alert from '@material-ui/lab/Alert';
import i18n from "./i18next/i18n";

interface S {
    openMenu: boolean;
    selectedItem: string | null;
    isAlert:boolean;
    basePathe:string;
}
export interface Props {
    navigation?: any;
    id?: string;
}
// Customizable Area End

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}

export default class Sidebar extends BlockComponent<Props, S, SS>{
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {
            openMenu: false,
            selectedItem: "",
            isAlert:false,
            basePathe:""
        };
        // Customizable Area End
    }
    handleItemClick = (item: string) => {
        localStorage.setItem("selectedItem", item)
        this.setState({
            selectedItem: item
        });
    };
    headerNavigation = async (pageLink: string, name: string) => {
        await setStorageData("selectedItem", name)
        const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pageLink);
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(toNavigate);
        this.setState({
            selectedItem: name
        });
    };
    logoutNavigation = async (pageLink: string, name: string) => {
        ["orderNumber" ,"auth-token" ,"sms-token"].map(async value=>{
            await removeStorageData(value)
        })
        const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pageLink);
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(toNavigate);
        this.setState({
            selectedItem: name
        });
    }
    notNavigation = async (name:string) => {
        await setStorageData("selectedItem", name)
        this.setState({ selectedItem: name,isAlert: true,basePathe:name });
    }
    oncloseAlert = () => {
        this.setState({ isAlert: false });
    };
    hendelUrl = async () => {
        const baseUrl = new URL(document.URL).href.split('/').pop();
        await setStorageData("selectedItem", baseUrl)
        const selectedItemUrl = await getStorageData("selectedItem")
        this.setState({basePathe:selectedItemUrl})
    }
    async componentDidMount() {
        this.hendelUrl()
    }
    transSideB = (keySB: string) => {
        return i18n.t(keySB, { ns: "sidebar" } )
    }
    render() {
        // Customizable Area Start
        const { selectedItem } = this.state;
        const isItemSelected = selectedItem !== "";
        return (
            <StyledWrapper>
                <Grid container>
                    <Grid
                        style={{ marginLeft: "40px" }}
                        dir={i18n.dir()}
                    >
                        <Box className="link"  onClick={() => this.headerNavigation("OrderManagementPage","OrderManagementPage")}
                            style={{ backgroundColor: this.state.basePathe === "OrderManagementPage" ? "#F1F5F9" : "transparent", textDecoration: "none",position: "relative" }}> 
                            <div
                                className="wishlist-icon"
                                style={{
                                    backgroundImage: this.state.basePathe === "OrderManagementPage" ? `url(${sidebarimg})` : "none",
                                    width: "2px",
                                    height: "54px",
                                    marginLeft: "-7px",
                                    position: "absolute"
                                }}
                            />
                            <Typography className="link" >
                                <img src={myOrder} />
                                {this.transSideB("My orders")}
                            </Typography>
                        </Box>
                        <Box className="link" onClick={() => this.headerNavigation("Wishlist","Wishlist")}
                            style={{ backgroundColor: this.state.basePathe === "Wishlist" ? "#F1F5F9" : "transparent", textDecoration: "none", position: "relative" }}>
                            <div
                                className="wishlist-icon"
                                style={{
                                    backgroundImage: this.state.basePathe === "Wishlist" ? `url(${sidebarimg})` : "none",
                                    width: "2px",
                                    height: "54px",
                                    marginLeft: "-7px",
                                    position: "absolute"
                                }}
                            />
                            <Typography className="link" >
                                <img src={wishlist} />
                                <span>{this.transSideB("Wishlist")}</span>
                            </Typography>
                        </Box>
                        <Box className="link" onClick={() => this.headerNavigation("Customisableuserprofiles2","profile")}
                            style={{ backgroundColor: this.state.basePathe === "profile" ? "#F1F5F9" : "transparent", textDecoration: "none",position: "relative" }}>
                            <div
                                className="wishlist-icon"
                                style={{
                                    backgroundImage: this.state.basePathe === "profile" ? `url(${sidebarimg})` : "none",
                                    width: "2px",
                                    height: "54px",
                                    marginLeft: "-7px",
                                    position: "absolute"
                                }}
                            />
                            <Typography className="link" >
                                <img src={myProfile} />
                                {this.transSideB("My Profile")}
                            </Typography>
                        </Box>
                        <Box className="link" onClick={() => this.headerNavigation("PaymentHistoryWeb","PaymentHistoryWeb")}
                            style={{ backgroundColor: this.state.basePathe === "PaymentHistoryWeb" ? "#F1F5F9" : "transparent", textDecoration: "none",position: "relative" }}>
                            <div
                                className="wishlist-icon"
                                style={{
                                    backgroundImage: this.state.basePathe === "PaymentHistoryWeb" ? `url(${sidebarimg})` : "none",
                                    width: "2px",
                                    height: "54px",
                                    marginLeft: "-7px",
                                    position: "absolute"
                                }}
                            />
                            <Typography className="link">
                                <img src={paymentHistory} />
                                {this.transSideB("Payment History")}
                            </Typography>
                        </Box>
                        <Box className="link" onClick={() => this.headerNavigation("Savedcards","Savedcards")}
                            style={{ backgroundColor: this.state.basePathe === "Savedcards" ? "#F1F5F9" : "transparent", textDecoration: "none",position: "relative" }}>
                            <div
                                className="wishlist-icon"
                                style={{
                                    backgroundImage: this.state.basePathe === "Savedcards" ? `url(${sidebarimg})` : "none",
                                    width: "2px",
                                    height: "54px",
                                    marginLeft: "-7px",
                                    position: "absolute"
                                }}
                            />
                            <Typography className="link" >
                                <img src={saveCard} />
                                {this.transSideB("Save Cards")}
                            </Typography>
                        </Box>
                        {/* <Box className="link" onClick={() => this.headerNavigation("Loyaltysystem","loyality")}
                            style={{ backgroundColor: localStorage.getItem("selectedItem") === "loyality" ? "#F1F5F9" : "transparent", textDecoration: "none",position: "relative" }}>
                            <div
                                className="wishlist-icon"
                                style={{
                                    backgroundImage: localStorage.getItem("selectedItem") === "loyality" ? `url(${sidebarimg})` : "none",
                                    width: "2px",
                                    height: "54px",
                                    marginLeft: "-7px",
                                    position: "absolute"
                                }}
                            />
                            <Typography className="link" >
                                <img src={loyality} />
                                Loyalty Points
                            </Typography>
                        </Box> */}
                        <Box className="link" onClick={() => this.notNavigation("stylist")}
                            style={{ backgroundColor: this.state.basePathe === "stylist" ? "#F1F5F9" : "transparent", textDecoration: "none",position: "relative" }}>
                            <div
                                className="wishlist-icon"
                                style={{
                                    backgroundImage: this.state.basePathe === "stylist" ? `url(${sidebarimg})` : "none",
                                    width: "2px",
                                    height: "54px",
                                    marginLeft: "-7px",
                                    position: "absolute"
                                }}
                            />
                            <Typography className="link" >
                                <img src={stylist} />
                                {this.transSideB("Stylist")}
                            </Typography>
                        </Box>
                        <Box 
                            className="link" onClick={() => this.headerNavigation("AddAddress","AddAddress")}
                            style={{ backgroundColor: this.state.basePathe === "AddAddress" ? "#F1F5F9" : "transparent", textDecoration: "none", position: "relative"}}>
                            <div
                                className="wishlist-icon"
                                style={{
                                    backgroundImage: this.state.basePathe === "AddAddress" ? `url(${sidebarimg})` : "none",
                                    width: "2px",
                                    height: "54px",
                                    marginLeft: "-7px",
                                    position: "absolute"
                                }}
                            />
                            <Typography className="link" >
                                <img src={address} />
                                {this.transSideB("Address")}
                            </Typography>
                        </Box>
                        <Box
                            className="link" onClick={() => this.headerNavigation("CurrencyLanguage","Setting")}
                            style={{ backgroundColor: this.state.basePathe === "Setting" ? "#F1F5F9" : "transparent", textDecoration: "none",position: "relative" }}>
                            <div
                                className="wishlist-icon"
                                style={{
                                    backgroundImage: this.state.basePathe === "Setting" ? `url(${sidebarimg})` : "none",
                                    width: "2px",
                                    height: "54px",
                                    marginLeft: "-7px",
                                    position: "absolute"
                                }}
                            />
                            <Typography className="link" >
                                <img src={setting} />
                                {this.transSideB("Language and Currency")}
                            </Typography>
                        </Box>
                        <Box
                            className="link" onClick={() => this.logoutNavigation("Home","logout")}
                            style={{ backgroundColor: localStorage.getItem("selectedItem") === "logout" ? "#F1F5F9" : "transparent", textDecoration: "none", position: "relative"}}>
                            <div
                                className="wishlist-icon"
                                style={{
                                    backgroundImage: localStorage.getItem("selectedItem") === "logout" ? `url(${sidebarimg})` : "none",
                                    width: "2px",
                                    height: "54px",
                                    marginLeft: "-7px",
                                    position: "absolute"
                                }}
                            />
                            <Typography className="link1">
                                <img src={logout} />
                                {this.transSideB("Logout")}
                            </Typography>
                        </Box>
                    </Grid>

                </Grid>
                <Snackbar
                    open={this.state.isAlert}
                    autoHideDuration={3000}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    onClose={this.oncloseAlert}
                    data-testid="alertTestId"
                ><Alert variant="filled" severity="info" style={{width:'300px',marginTop:'10px'}}>Coming Soon !!!</Alert></Snackbar>
            </StyledWrapper>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const StyledWrapper = styled(Box)({

    "& .link": {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        color: "#334155",
        fontFamily: "Lato , sans-serif",
        fontSize: "18px !important",
        fontStyle: "normal !important",
        fontWeight: 700,
        lineHeight: "21.6px !important",
        padding: "8px 20px 8px 7px",
        gap: "10px"
    },
    "& .link1": {
        color: "#DC2626",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        fontFamily: "Lato , sans-serif",
        fontSize: "18px !important",
        fontStyle: "normal !important",
        fontWeight: 700,
        lineHeight: "21.6px !important",
        padding: "8px 20px 8px 7px",
        gap: "10px"

    },

})
// Customizable Area End