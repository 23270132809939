// Customizable Area Start
import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import StylistAnalyticsController, {
  lineChartData,
  Props,
  configJSON
} from "./StylistAnalyticsController";
import { Grid, styled, Box, Typography, Dialog,
  RadioGroup,
  FormControlLabel,
 } from "@material-ui/core";
import RadioButton from "../../../components/src/RadioButton";
import SellerSidebar from "../../../components/src/Seller/SellerSideBar";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import { backBtnIcon, button_close, downloadIcon, drownwardArrow, product1, product2, product3, product4,  rightArrowPng, rightArrowSvg, search, upwardArrowSvg } from "./assets";
import UpdatedBarChart from "../../../components/src/Chart/UpdatedBarChart";
import TwoLineChart from "../../../components/src/Chart/LineChart";
import GrowthPieChart from "../../../components/src/Chart/GrowthPieChart";


// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class StylistAnalytics extends StylistAnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  rearrangedPieChartData = [...this.state.pieChartData.slice(1), this.state.pieChartData[0]];
  renderProductTab = () => {
    const {productSelected, storeReportProduct} = this.state;
    return (
      <>
       <Box className="storeHeader" data-test-id={`clickTabProduct`} onClick={this.handleStoreDialogOpen}>
            <Box className="allStoresTile">Select Product</Box>
            <Box style={{ width: "16px", height: "16px" }}><img src={rightArrowPng} style={{ width: "100%", height: "100%" }} alt="" /></Box>
          </Box>
      <Box className="productContainer">
      <Box style={{ minWidth: "111px", height: "100px", borderRadius: "2px" }}>
        <img src={productSelected?.attributes?.primary_image} style={{ width: "100%", height: "100%", backgroundSize: "cover", backgroundRepeat: "no-repeat" }} alt="prduct image" />
      </Box>
      <Box style={{ display: "flex", justifyContent: "space-between", width: "100%"}}>
        <Box style={{display: "flex", flexDirection: "column", gap: "22px"}}>
          <Typography style={{ fontFamily: "Lato", fontSize: "18px", fontWeight: 500, color: "#375280" }}>{productSelected?.attributes?.name}</Typography>
          <Box style={{ display: "flex", gap: "15px" }}>
            <Box className="soldUnits">
              <Typography className="salesRevenueTitle">{storeReportProduct?.sold_units} Sold Units</Typography>
            </Box>
            <Box className="returnUnits">
              <Typography style={{ fontFamily: "Lato", fontSize: "16px", fontWeight: 500, color: "#DC2626" }}>{storeReportProduct?.returned_units} Returned Units</Typography>
            </Box>
          </Box>
        </Box>
        <Typography style={{ fontFamily: "Lato", fontWeight: 700, fontSize: "18px", color: "#059669" }}>$ {productSelected?.attributes?.primary_price}</Typography>
      </Box>
    </Box>
      </>
    )
  }

  renderBarGraph = () => {
    return (
      <>
       <Box className="revenuepart">
            <Box className="salesRevenueReport">Sales Revenue Report Store</Box>
            <Box style={{ width: "30px", height: "30px" }}><img src={downloadIcon} style={{ width: "100%", height: "100%" }} alt="" /></Box>
          </Box>
       <Box className="chartContainer">
            <Box className="chartInner">
              <Box style={{ width: "95%" }}>
                <Box className="salesRevenueTitle">Sales Revenue</Box>
                <Box className="revenueAmount">$ {this.state.storeReportProduct?.max_value}</Box>
              </Box>

              <Box style={{ display: "flex", height: "22px", gap: "12px" }}>
                <Box className="salesRevenueTitle">Period:</Box>
                <select className="optionClassName" value={this.state.reportProductPeriod} data-test-id="monthlyChange" onChange={(event) => {this.handlePeriodChangeStore(event.target.value)}}>
                  <option value="monthly">Monthly</option>
                  <option value="weekly">Weekly</option>
                </select>
              </Box>
            </Box>

            {Object.values(this.state.storeReportProduct?.graph_data?.total_revenue)?.length > 1 && <BarChartContainer>
              <Grid container>
                <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
                    <Box className="revenue-chart-container">
                      <UpdatedBarChart
                        labels={this.state.reportProductPeriod === "monthly" ? configJSON.monthLabel : configJSON.weekLabel}
                        datasets={[
                          {
                            label: "Revenue",
                            data: this.getGraphDataDirect(),
                            backgroundColor: "#F59E0B",
                          },
                        ]}
                        barThickness={30}
                        xTickColor="#375280"
                        yTickColor="#375280"
                        viewingMonth="Aug"
                      />
                    </Box>
                </Grid>
              </Grid>
            </BarChartContainer>}

            <Box style={{ height: "86px", display: "flex", gap: "16px" }}>
              <Box className="revenueMainContainer">
                <Typography className="averageRevenue">Average Order Value</Typography>
                <Typography className="averageAmt">$ {this.state.storeReportProduct?.average_order_value ? Number(this.state.storeReportProduct?.average_order_value).toFixed(0) : 0}</Typography>
              </Box>
              <Box className="revenueMainContainer">
                <Typography className="averageRevenue">Sales Volume</Typography>
                <Typography className="averageAmt">{this.state.storeReportProduct?.sales_volume}</Typography>
              </Box>
            </Box>
          </Box>
      </>
    )
  }
  renderStoreReport = () => {
    console.log("storeSelected0", this.state.storeSelected)
    return (
      <>
        <Box style={{ width: "100%" }}>
          <Box className="title-container">
            <img src={backBtnIcon} data-test-id="back-btn-test-id" alt="backIcon"
              onClick={() => {this.setState({activePage: "", productSelected: {}})
            }}
            />
            <Typography className="title">  Analytics & Insights</Typography>
          </Box>   

          <Box  className="primaryContainerSales" >
            {[{ name: "Sales by Store", path: "salesStore" }, { name: "Sales by Product", path: "salesProduct" }].map((element, index) => (
              <Box style={{ width: "50%", height: "50px",
               borderBottom: this.state.storeActivePage === element.path ? "4px solid #375280" : "", display: "flex", justifyContent: "center", alignItems: "center" }}
               data-test-id={`storeTab-${index}`} onClick={() => this.handleStoreTab(element.path)}
                key={index}>
                <Typography style={{ fontFamily: "Lato", cursor: "pointer",
                 color: this.state.storeActivePage === element.path ? "#375280" : "#94A3B8",
                 fontSize: "16px", fontWeight: 500, 
                  }}>
                  {element.name}</Typography>
              </Box>
            ))}    
            </Box>

        {(this.state.storeActivePage  === "salesProduct" &&  Object.keys(this.state.productSelected).length === 0 )   && ( 
          <Box className="storeHeader"  data-test-id={`clickTabProduct`}
           onClick={this.handleStoreDialogOpen}>
            <Box className="allStoresTile">Select Product</Box>  
          <Box style={{ width: "16px", height: "16px" }}>
            <img src={rightArrowPng}   style={{ width: "100%", height: "100%" }} alt="" />
            </Box>
        </Box>
        )}
         {(this.state.storeActivePage=== "salesProduct" && Object.keys(this.state.productSelected).length > 0 ) &&  this.renderProductTab() }
         {(this.state.storeActivePage=== "salesStore" && (
          <>
          <Box className="storeHeader"  data-test-id="storeClick" 
            onClick={this.handleGrowthDialogOpen}>
               <Box className="allStoresTile">{Object.keys(this.state.storeSelected)?.length > 0 ? this.state.storeSelected?.attributes?.store_name : "All Stores"}</Box> 
               <Box style={{ width: "16px", height: "16px" }}><img src={rightArrowPng} style={{ width: "100%", height: "100%" }} alt="" /></Box>
          </Box>   </>
))}

         {(this.state.storeActivePage === "salesStore" || Object.keys(this.state.productSelected).length > 0) && this.renderBarGraph() }
        </Box>
      </>
    )
  }

  renderVolumeReport = (tabFrom: string) => {
    return (
      <Box style={{ width: "100%" }}>
          <Box className="title-container">
            <img
              src={backBtnIcon}
              data-test-id="back-btn-test-id"
              alt="backIcon"
              onClick={() => {this.setState({activePage: ""})}}
            />
            <Typography className="title">
              Analytics & Insights
            </Typography>
          </Box>


         {(this.state.storeActivePage === "salesProduct" && Object.keys(this.state.productSelected).length === 0 ) && (
          <Box className="storeHeader" data-test-id={`clickTabProduct`} onClick={this.handleStoreDialogOpen}>
          <Box className="allStoresTile">Select Product</Box>
          <Box style={{ width: "16px", height: "16px" }}><img src={rightArrowPng} style={{ width: "100%", height: "100%" }} alt="" /></Box>
        </Box>
        )}
         {(this.state.storeActivePage=== "salesProduct" && Object.keys(this.state.productSelected).length > 0 ) &&  this.renderProductTab() }
         {(this.state.storeActivePage=== "salesStore" && (
          <>
          <Box className="storeHeader" data-test-id="storeClick" onClick={this.handleGrowthDialogOpen}>
            <Box className="allStoresTile">{Object.keys(this.state.storeSelected)?.length > 0 ? this.state.storeSelected?.attributes?.store_name : "All Stores"}</Box>
            <Box style={{ width: "16px", height: "16px" }}><img src={rightArrowPng} style={{ width: "100%", height: "100%" }} alt="" /></Box>
          </Box>
          </>

         ))}
         {(this.state.storeActivePage === "salesStore" || Object.keys(this.state.productSelected).length > 0) && (
          <ChartContainer>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} sm={12} lg={12} xl={12} style={{marginBottom: "10px"}}>
                    <Box style={{ width: "95%", height: "44px", marginTop: "20px", padding: "16px 16px 0px 16px", display: "flex", justifyContent: "space-between"}}>
                      <Box style={{fontFamily: "Lato", fontSize: "18px",height:"50px", fontWeight: 700, color: "#375280"}}>Sales Volume Report</Box>
                      <Box style={{width: "30px", height: "30px"}}>
                        <img src={downloadIcon} alt="downloadIcon" style={{height: "100%", width: "100%"}}  />
                      </Box>
                    </Box>
                    <Box className="chart-outer-container">
                      <div className="pie-chart-container">
                        <Box style={{minHeight: "88px", width: "100%",   display: "flex", flexDirection:  "column", gap: 32}}>
                          <Box style={{width: "100%", display: "flex", justifyContent: "space-between", minHeight: "28px"}}>  
                              <Box style={{minWidth: "93px", height: "23px",
                              fontFamily: "Lato", fontSize: "20px", fontWeight: 600, color: "#375280"}}>
                                Sales Volume</Box>   
                        
                            <Box style={{ display: "flex", height: "23px", gap: "12px" , alignItems: "center"}}>   
                              <Box style={{fontFamily: "Lato", fontSize: "16px", fontWeight: 600 , color: "#375280"}}>Period:</Box>  
                              <select className="optionClassName" value={this.state.reportProductPeriod} data-test-id="monthlyChangeVolume" onChange={(event) => {this.handlePeriodChangeVolume(event.target.value)}}> 
                                 <option value="monthly">Monthly</option>  
                                <option value="weekly">Weekly</option>  
                              </select>  
                            </Box></Box>

                          <Box style={{ height: "28px", display: "flex", gap: 20, justifyContent: "center", alignItems: "center"}}>
                            <Box style={{width: "24px", height: "24px"}}>
                              <img src={rightArrowSvg}  
                              style={{height: "100%", width: "100%", rotate: "180deg"}} 
                              onClick={() => this.handleArrowClick("left")}
                              data-test-id="handleArrowClick"
                               alt="backIcon"
                               />     </Box>
                            <Box style={{display: "flex", gap: 10, width:  "fit-content",  overflow: "hidden"}}>   
                            {configJSON.monthLabel.map((element: string, index: number)=> ((this.state.leftIndex <= index) && (index < this.state.rightIndex) && <Box
                             style={{minWidth: "44px", minHeight: "28px",
                             justifyContent: "center",
                              backgroundColor: new Date().getMonth() === index ? '#375280' : "#E2E8F0", display: "flex",
                                alignItems: "center"}} key={index}>

                             <Typography style={{ color: new Date().getMonth() === index ? "#fff" : "#375280", fontFamily: "Lato",
                              fontSize: "14px", fontWeight: 500}}>{element}</Typography>
                            </Box>))}  
                                </Box>

                            <Box style={{width: "24px", height: "24px"}} >    
                              <img   src={rightArrowSvg}  
                              style={{height: "100%", width: "100%"}} data-test-id="handleArrowClickRight" 
                              alt="backIcon"    
                               onClick={() => this.handleArrowClick("right")}/>   
                               </Box>
                          </Box>
                        </Box>
                        <div className="pie-chart" style={{ position: "relative", height: "fit-content" }}>
                          <GrowthPieChart
                            labels={this.rearrangedPieChartData.map((item) => item.label)}
                            dataSetsVal={[this.state.volumeStore.returned_units, this.state.volumeStore.sold_units]}
                            backgroundColor={this.rearrangedPieChartData.map((item) => item.color)}
                          />
                          <Box className="responsivePosition">
                            <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 8 }}>
                              <Typography style={{ fontFamily: "Lato", fontSize: "16px", fontWeight: 400, color: "#375280" }}>Total Units Sold</Typography>
                              <Typography style={{ fontFamily: "Lato", fontSize: "32px", fontWeight: 900, color: "#375280" }}>{this.state.volumeStore.total_sold_units}</Typography>
                            </Box>
                          </Box>
                        </div>
                        <div className="pie-chart-label-container">
                          {this.state.pieChartData.map(
                            (item: {
                              label: string;
                              color: string;
                              value: number;
                            }, index: number) => (
                              <Box className="pie-chart-label" key={index}>
                                <Box
                                  style={{ backgroundColor: item.color }}
                                  className="color-picker"
                                />
                                <Box className="label-name">
                                  <Typography>{item.label}</Typography>
                                  <Typography>{item.value}</Typography>
                                </Box>
                              </Box>
                            )
                          )}
                        </div>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </ChartContainer>
         ) }

       </Box>
    )
  }

  renderGrowthReport = () => {
    return (
      <Box style={{ width: "100%" }}>
          <Box className="title-container">
            <img
              src={backBtnIcon}
              data-test-id="back-btn-test-id"
              alt="backIcon"
              onClick={() => {this.setState({activePage: ""})}}
            />
            <Typography className="title">
              Analytics & Insights
            </Typography>
          </Box>

          <ChartContainer>
                <Box>
                  <Box style={{marginBottom: "10px"}}>
                    <Box style={{ width: "95%", height: "44px", marginTop: "20px", padding: "16px 16px 0px 16px", display: "flex", justifyContent: "space-between"}}>
                      <Box style={{fontFamily: "Lato", fontSize: "18px",height:"50px", fontWeight: 700, color: "#375280"}}>Sales Growth Report</Box>
                      <Box style={{width: "30px", height: "30px"}}>
                        <img src={downloadIcon} alt="downloadIcon" style={{height: "100%", width: "100%"}}  />
                      </Box>
                    </Box>
                    <Box className="chart-outer-container">
                      <Box style={{display: "flex", gap: "27px", flexDirection: "column", padding: "14px"}}>
                        <Box style={{minHeight: "88px", width: "100%", display: "flex", flexDirection: "column", gap: 32}}>
                          <Box style={{width: "100%", display: "flex", justifyContent: "space-between", minHeight: "28px"}}>
                              <Box style={{display: "flex", flexDirection: "column", gap: 10}}>
                                 <Typography style={{minWidth: "93px", height: "23px",fontFamily: "Lato", fontSize: "20px", fontWeight: 600, color: "#375280"}}>Sales Revenue</Typography>
                                 <Typography style={{fontFamily: "Lato", fontSize: "20px", fontWeight: 900, color: "#059669"}}>$ {this.state.growthResponse?.sales_revenue}</Typography>
                                 <Box style={{display: "flex", gap: 6}}>
                                  {this.state.growthResponse?.profit ? (
                                    <>
                                    <img src={upwardArrowSvg} alt="upwardArrow" style={{width: "24px", height: "24px"}}/>
                                    <Typography style={{fontFamily: "Lato", fontSize: "14px", fontWeight: 500, color: "#059669"}}>{this.state.growthResponse?.profit_percentage} higher than last period</Typography>
                                    </>
                                  ): (
                                    <>
                                    <img src={drownwardArrow} alt="drownwardArrow" style={{width: "24px", height: "24px"}}/>
                                    <Typography style={{fontFamily: "Lato", fontSize: "14px", fontWeight: 500, color: "#F87171"}}>{this.state.growthResponse?.loss_percentage} lower than last period</Typography>
                                    </>
                                  )}
                                 </Box>
                                </Box>
                        
                            <Box style={{ display: "flex", height: "23px", gap: "12px" , alignItems: "center"}}>
                              <Box style={{fontFamily: "Lato", fontSize: "16px", fontWeight: 600, color: "#375280"}}>Period:</Box>
                              <select className="optionClassName" value={this.state.reportProductPeriod} data-test-id="monthlyChangeGrowth" onChange={(event) => {this.handlePeriodChangeGrowth(event.target.value)}}>
                                <option value="monthly">Monthly</option>
                                <option value="weekly">Weekly</option>
                              </select>
                            </Box>
                          </Box>

                          <Box style={{ height: "28px", display: "flex", gap: 20, justifyContent: "center", alignItems: "center"}}>
                            <Box style={{width: "24px", height: "24px"}}>
                              <img src={rightArrowSvg}  alt="backIcon" style={{height: "100%", width: "100%", rotate: "180deg"}} data-test-id="handleArrowClick" onClick={() => this.handleArrowClick("left")}/>
                            </Box>
                            <Box style={{display: "flex", gap: 10, width: "fit-content", overflow: "hidden"}}>
                            {configJSON.monthLabel.map((element: string, index: number)=> ((this.state.leftIndex <= index) && (index < this.state.rightIndex) && <Box style={{minWidth: "44px", minHeight: "28px", backgroundColor: new Date().getMonth() === index ? '#375280' : "#E2E8F0", display: "flex", justifyContent: "center", alignItems: "center"}} key={index}>
                             <Typography style={{ color: new Date().getMonth() === index ? "#fff" : "#375280", fontFamily: "Lato", fontSize: "14px", fontWeight: 500}}>{element}</Typography>
                            </Box>))}
                            </Box>

                            <Box style={{width: "24px", height: "24px"}} >
                              <img src={rightArrowSvg}  alt="backIcon" style={{height: "100%", width: "100%"}} data-test-id="handleArrowClickRight"  onClick={() => this.handleArrowClick("right")}/>
                            </Box>
                          </Box>
                        </Box>
                        
                        <Box className="line-chart-container">
                        <TwoLineChart
                          labels={this.state.reportProductPeriod === "monthly" ? configJSON.monthLabel : configJSON.weekLabel}
                          datasets={[
                            {
                              data: this.state.reportProductPeriod === "monthly" ? this.getGraphDataGrowth('current') : this.getGraphDataGrowthWeekly('current'),
                              borderColor: "#059669",
                              backgroundColor: "#059669",
                              borderWidth: 2,
                              fill: false,
                            },
                            {
                              data: this.state.reportProductPeriod === "monthly" ? this.getGraphDataGrowth('prev') : this.getGraphDataGrowthWeekly('prev'),
                              borderColor: "#F87171",
                              backgroundColor: "#F87171",
                              borderWidth: 2,
                              fill: false,
                            },
                          ]}
                          xTickColor="#375280"
                          yTickColor="#375280"
                          highlightLastTick
                        />
                        </Box>

                        <Box style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
                        {lineChartData.map(
                          (item: { label: string; color: string }, index: number) => (
                            <Box style={{display: "flex", width: "fit-content", alignItems: "center", gap: "10px"}} key={index}>
                              <Box
                                style={{ backgroundColor: item.color , width: "12px", height: "12px", borderRadius: "50%"}}
                              />
                              <Typography style={{color: item.color}}>{item.label}</Typography>
                            </Box>
                          )
                        )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </ChartContainer>
       </Box>
    )
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container className="grid-seller-store-profile">
          <Grid
            item
            xl={2}
            md={3}
            className="sidebar-seller-store-analytics"
            lg={2}
            sm={3}
            xs={3}
          >
            <SellerSidebar
              className="sidebar-seller-store-analytics"
              navigation={this.props.navigation}
            />
          </Grid>
          <Grid
            item
            xl={10}
            md={9}
            className="header-seller-store-analytics"
            lg={10}
            sm={9}
            xs={9}
          >
            <SellerHeader navigation={this.props.navigation} />
            <StylistAnalyticsContainer>
              {this.state.activePage === "" && (
                <>
                  <Typography className="dashboardTitle">Analytics Dashboard</Typography>
                  <Box className="primaryContainer">
                    <Box className="innerContainer">
                      <Box>
                        <Typography className="revenueNumber">${this.state.totalRevenue}</Typography>
                      </Box>
                      <Box>
                        <Typography className="revenueTitle">Total Revenue</Typography>
                      </Box>
                    </Box>

                    <Box>
                      <hr className="divider" />
                    </Box>

                    <Box className="secondaryContainer">
                      {this.state.analyticsData.map((element, index) => (
                        <Box className="flexItemContainer" data-test-id={`analyticsDas-${index}`} onClick={() => this.handlePageChange(element.type)} key={index}>
                          <Box>
                            <Typography className="flexTitle">{element.analyticsType}</Typography>
                          </Box>
                          <Box>
                            <img src={rightArrowPng} alt="flexImage" className="arrowImg" />
                          </Box>
                        </Box>
                      ))}

                    </Box>
                  </Box>
                </>
              )}

              {this.state.activePage === "store" && this.renderStoreReport()}
              {this.state.activePage === "volume" && this.renderVolumeReport("volume")}
              {this.state.activePage === "volumeProduct" && this.renderVolumeReport("volumeProduct")}
              {this.state.activePage === "growth" && this.renderGrowthReport()}
              <DialogContainerSuccess data-test-id="growthClose" open={this.state.growthSelectStore}
               onClose={()=>this.handleSelectStoreClose()}
               >
                  <Box style={{width: "35.36px", height: "35.36px", position: "absolute", top: "2px", right: "2px"}} onClick={this.handleSelectStoreClose}>
                    <img src={button_close} alt="closeIcon" style={{width: "100%", height: "100%"}} />
                  </Box>
                <Box style={{width: "520px", display: "flex", justifyContent: "center", flexDirection: "column", gap: 32}}>
                  <Box style={{fontFamily: "Lato", fontSize: "30px", fontWeight: 500, color: "#375280",  display: "flex", justifyContent: "center"}}>Select Store</Box>
                  <Box style={{width: "100%", height: "52px", border: "1px solid #CBD5E1", display: "flex", alignItems: "center", gap: 10}}>  
                       <Box style={{width: "24px", height: "24px", marginLeft: "10px"}}>  
                      <img src={search} 
                      alt="searcg" style={{width: "100%",
                       height: "100%"}}/>
                    </Box>  
                      <Typography style={{fontFamily: "Lato", fontSize: "16px", fontWeight: 500, color: "#94A3B8"}}>Search Store</Typography>
                  </Box>

                  <Box>
                    {this.state.storesData.length > 0 && (
                  <StyledRadio
                      aria-labelledby="language-selection"
                      name="language-selection"
                      value={this.state.checkedAllStore} 
                      row
                      data-test-id="handleAllSelectStore"
                      style={{ justifyContent: "space-between", width: "520px", display: "flex", flexDirection: "column", gap: 16 }}
                      onChange={(event, value) => this.handleAllSelectStore(value)}>
                        <Box style={{minHeight: "40px", width: "100%", display: "flex",  gap: 10}}>
                            <FormControlLabel   
                              value={"true"} control={<RadioButton variant="outlined" />}
                              label={"All Store"}
                              className={`radioButton ${    
                                this.state.checkedAllStore === "true" ? "activeRadioButton": ""  
                              }`}  
                              style={{  
                                justifyContent: 'space-between', 
                                  flexDirection: 'row-reverse', alignItems: 'center' ,
                                width:"100%",display: 'flex', 
                                color:"#375280", marginLeft: "0px", marginRight: "0px", fontWeight: 900
                              }}
                            />
                         </Box>   
                    </StyledRadio>
                    )}
                  </Box>
   
                    {this.state.storesData?.length > 0 && this.state.storesData?.map((element:any, index: number)=> (
                      <StyledRadio
                      aria-labelledby="language-selection"
                      name="language-selection"
                      value={this.state.storeChecked} data-test-id="storeSelect"
                      row
                      key={index}
                      style={{ justifyContent: "space-between", width: "520px", display: "flex", flexDirection: "column", gap: 16 }}
                      onChange={(event, value) =>this.onChangeHandlerStore(value)
                      }>
                        <Box style={{minHeight: "40px", width: "100%", display: "flex",  gap: 10}}>
                             <img src={element?.attributes?.image} style={{width: "40px", height: "40px"}}/>  
                            <FormControlLabel   
                            key={element.companyName}
                              value={element?.id} control={<RadioButton variant="outlined" />}
                              label={element?.attributes?.store_name} data-test-id={`radio-${element?.id}`}
                              className={`radioButton ${    
                                element.id === this.state.storeChecked
                                  ? "activeRadioButton": ""  
                              }`}  
                              style={{  
                                justifyContent: 'space-between', 
                                  flexDirection: 'row-reverse', alignItems: 'center' ,
                                width:"100%",display: 'flex', 
                                color:"#375280", marginLeft: "0px", marginRight: "0px", fontWeight: 900
                              }}
                            />
                         </Box>   
                        {index !== this.state.storesData?.length -1 && (<hr style={{border: "1px solid #D9D9D9", width: "100%"}}/> )}
                    </StyledRadio>
                    ))}

                  <Box style={{display: "flex", gap: 10}}>
                      <Box style={{width: "48%", height: "54px", border: "1px solid #CCBEB1", display: "flex", justifyContent: "center", alignItems: "center"}}
                    data-test-id="closeStore" onClick={this.handleSelectStoreClose}>  
                      <Typography style={{fontFamily: "Lato", fontSize: "18px", fontWeight: 500, color: "#375280", cursor: "pointer"}}>Back</Typography>
                      </Box>  
                    <Box style={{width: "48%", height: "54px", border: "1px solid #CCBEB1", backgroundColor: "#CCBEB1",  display: "flex", justifyContent: "center", alignItems: "center"}} data-test-id="handleStoreSubmit" onClick={this.handleStoreSubmit}>
                    <Typography style={{fontFamily: "Lato", fontSize: "18px", fontWeight: 500, color: "#FFFFFF", cursor: "pointer"}}>Confirm</Typography>
                    </Box>
                  </Box>
                </Box>
            </DialogContainerSuccess>

            <DialogSelectProduct data-test-id="growthProduct" open={this.state.growthSelectProduct}
               onClose={()=>this.handlegrowthSelectProduct()}
               >
                <Box style={{width: "520px", display: "flex", justifyContent: "center", flexDirection: "column", gap: 32}}>
                  <Box style={{fontFamily: "Lato", fontSize: "30px", fontWeight: 500, color: "#375280",  display: "flex", justifyContent: "center"}}>Select Product</Box>
                  <Box style={{width: "100%", height: "52px", border: "1px solid #CBD5E1", display: "flex", alignItems: "center", gap: 10}}>
                    <Box style={{width: "24px", height: "24px", marginLeft: "10px"}}>
                      <img src={search} alt="searcg" style={{width: "100%", height: "100%"}}/>
                    </Box>
                    <Typography style={{fontFamily: "Lato", fontSize: "16px", fontWeight: 500, color: "#94A3B8"}}>Search Product</Typography>
                  </Box>

                  {this.state.catalogueProduct.map((element: any, index: number)=> (
                      <StyledRadio
                      row
                      aria-labelledby="language-selection"
                      name="language-selection"
                      style={{ justifyContent: "space-between", width: "520px", display: "flex", flexDirection: "column", gap: 16 }}
                      value={this.state.productChecked}
                      data-test-id="productSelection"
                      onChange={(event, value) => 
                        this.onChangeHandler(value)
                      }
                      key={index}
                    >
                        <>
                        <Box style={{minHeight: "40px", width: "100%", display: "flex",  gap: 10}}>
                          <img src={element.attributes.primary_image} style={{width: "40px", height: "40px"}}/>
                            <FormControlLabel
                              key={element.id}
                              value={element.id}
                              control={<RadioButton variant="outlined" />}
                              label={element.attributes.name}
                              data-test-id={`radio-${element.companyName}`}
                              className={`radioButton ${
                                element.id === this.state.productChecked
                                  ? "activeRadioButton"
                                  : ""
                              }`}
                              style={{
                                justifyContent: 'space-between', 
                                display: 'flex', 
                                flexDirection: 'row-reverse', 
                                alignItems: 'center' ,
                                width:"100%",
                                color:"#375280", marginLeft: "0px", marginRight: "0px", fontWeight: 900
                              }}
                            />
                        </Box>
                        {index !== this.state.catalogueProduct.length -1 && (
                        <hr style={{border: "1px solid #D9D9D9", width: "100%"}}/>
                      )}
                        </>

                    </StyledRadio>
                      ))}

                  <Box style={{display: "flex", gap: 10}}>
                    <Box style={{width: "48%", height: "54px", border: "1px solid #CCBEB1", display: "flex", justifyContent: "center", alignItems: "center"}}
                    data-test-id="closeProduct"
                    onClick={this.handlegrowthSelectProduct}>
                      <Typography style={{fontFamily: "Lato", fontSize: "18px", fontWeight: 500, color: "#375280"}}>Back</Typography>
                    </Box>
                    <Box style={{width: "48%", height: "54px", border: "1px solid #CCBEB1", backgroundColor: "#CCBEB1",  display: "flex", justifyContent: "center", alignItems: "center"}} data-test-id="handleProductSelect" onClick={this.handleProductSelect}>
                    <Typography style={{fontFamily: "Lato", fontSize: "18px", fontWeight: 500, color: "#FFFFFF"}}>Confirm</Typography>
                    </Box>
                  </Box>
                </Box>
            </DialogSelectProduct>
            </StylistAnalyticsContainer>
          </Grid>
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
export const popularProduct = [
  {
    image: product1,
    name: "Black Leather Boots (Women)",
    value: "$24,000",
    orderNo: "#4564-4512",
  },
  {
    image: product2,
    name: "Pink Midi Skirt with Pockets (Women)",
    value: "$20,000",
    orderNo: "#4564-4513",
  },
  {
    image: product3,
    name: "Oversized Brown T-shirt (Both)",
    value: "$4,000",
    orderNo: "#4564-4514",
  },
  {
    image: product4,
    name: "Leather Watch with round Dial (Men)",
    value: "$12,000",
    orderNo: "#4564-4515",
  },
];

const DialogContainerSuccess = styled(Dialog)({
  "& .MuiDialog-paper": {
    padding: "37px 57px",
    "& .content-container": {
      display: "flex",
      flexDirection: "column",
      gap: 30,
      alignItems: "center",
      justifyContent: "center",
      "& .text-container": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 400,
        fontSize: 30,
        color: "#0F172A",
        textAlign: "center"
      },
    },
    "@media(max-width:520px)": {
      padding: "20px 40px",
      "& .content-container": {
        "& .text-container": {
          fontSize: "22px !important",
        },
      },
    },
  }});

  const DialogSelectProduct = styled(Dialog)({
    "& .MuiDialog-paper": {
      padding: "37px 57px",
      "& .content-container": {
        display: "flex",
        flexDirection: "column",
        gap: 30,
        alignItems: "center",
        justifyContent: "center",
        "& .text-container": {
          fontFamily: "Lato, sans-serif",
          fontWeight: 400,
          fontSize: 30,
          color: "#0F172A",
          textAlign: "center"
        },
      },
      "& .MuiDialog-paperWidthSm": {
        maxWidth: "608px"
      },
      "@media(max-width:520px)": {
        padding: "20px 40px",
        "& .content-container": {
          "& .text-container": {
            fontSize: "22px !important",
          },
        },
      },
    }});

  const StyledRadio = styled(RadioGroup)({
    "& .MuiTypography-body1": {
      fontWeight: 500,
      fontSize: "20px",
      fontFamily: "Lato"
    }
  }) 

const BarChartContainer = styled(Box)({
  width: "100%",
  "& .MuiGrid-root.MuiGrid-container": {
    height: "max-content",
    "& .MuiGrid-root.MuiGrid-item": {
        "& .revenue-chart-container":{
          width: "100%",
          marginTop: 30
      },
    },
  },
});

const ChartContainer = styled(Box)({
  width: "100%",
  marginLeft: "10px",
  "& .MuiGrid-root.MuiGrid-container": {
    height: "max-content",
    "& .MuiGrid-root.MuiGrid-item": {
      "& .chart-outer-container": {
        padding: 30,
        border: "1px solid #E2E8F0",
        marginTop: 10,
        height: "80%",
        "& .chart-title": {
          fontFamily: "Lato, sans-serif",
          fontWeight: 700,
          fontSize: 20,
          color: "#375280",
        },
        "& .line-chart-container": {
          width: "100%",
        },
        "& .revenue-chart-container":{
          width: "100%",
          marginTop: 30
        },
        "& .pie-chart-container": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "57px",
          "& .pie-chart": {
            width: "fit-content",
          },
          "& .pie-chart-label-container": {
            display: "flex",
            gap: 30,
            width: "100%",
            justifyContent: "space-between"
          },
          "& .pie-chart-label": {
            display: "flex",
            gap: 12,
            width: "fit-content",
            color: "#375280",
            fontSize: 16,
            "& .label-name": {
              display: "flex",
              width: "100%",
              gap: 10,
              marginTop: 2,
            },
            "& .color-picker": {
              minWidth: 20,
              borderRadius: 2,
              maxHeight: 20,
            },
          },
          "@media(max-width:800px)": {
            flexDirection: "column",
            gap: 20,
            "& .pie-chart-label-container": {
              width: 320,
              alignItems: "center",
            },
          },
        },
        "& .line-chart-labels-container": {
          display: "flex",
          gap: 20,
          padding: "20px 0px",
          justifyContent: "space-between",
          "& .line-chart-label": {
            display: "flex",
            gap: 12,
            justifyContent: "center",
            alignItems: "center",
            color: "#375280",
            fontSize: 16,
            "& .color-picker": {
              width: 20,
              height: 24,
              borderRadius: 2,
            },
          },
        },
        "& .responsivePosition": { width: "155px", position: "absolute", top: "40%", left: "25%" },
        "@media(max-width:600px)": {
          "& .line-chart-container": {
            width: "100%",
            overflowX: "auto",
          },
        },
      },
    },
  },
});

const StylistAnalyticsContainer = styled(Box)({
  padding: "30px",
  minHeight: "calc(100vh - 85px)",
  width: "95%",
  "& .dashboardTitle": { fontFamily: "Lato", fontSize: "24px", fontWeight: 700, color: "#375280" },
  "& .primaryContainer": { width: "100%", minHeight: "448px", display: "flex", flexDirection: "column", gap: 32, marginTop: "20px" },
  "& .innerContainer": { width: "100%", minHeight: "114px", border: "1px solid #E2E8F0", gap: 8, display: "flex", flexDirection: "column", alignItems: "center" },
  "& .revenueNumber": { fontFamily: "Lato", fontSize: "40px", fontWeight: 800, color: "#059669", marginTop: "10px" },
  "& .revenueTitle": { fontFamily: "Lato", fontSize: "16px", fontWeight: 700, color: "#375280" },
  "& .divider": { border: "0.5px solid #B1B1B1", opacity: "50%" },
  "& .secondaryContainer": { width: "100%", minHeight: "240px", display: "flex", gap: "20px", flexWrap: "wrap", justifyContent: "space-between" },
  "& .flexItemContainer": { width: "43.7%", minHeight: "40px", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "26px", border: "1px solid #E2E8F0" },
  "& .flexTitle": { fontFamily: "Lato", fontSize: "22px", fontWeight: 700, color: "#375280", cursor: "pointer" },
  "& .arrowImg": { width: "20px", height: "20px" },
  "& .storeHeader": { width: "97.5%", height: "25px", padding: "16px", marginTop: "20px", marginLeft: "10px", boxShadow: "0px 2px 8px 4px #00000014", display: "flex", alignItems: "center" },
  "& .revenuepart" : { width: "97.5%", height: "52px", padding: "16px", marginTop: "20px", marginLeft: "10px", display: "flex", alignItems: "center", backgroundColor: "#FFFFFF" },
  "& .chartContainer": { width: "95.5%", height: "fit-content", padding: "32px", marginTop: "20px", marginLeft: "10px", boxShadow: "0px 2px 8px 4px #00000014", display: "flex", flexDirection: "column", gap: 24 , 
    "& .chartInner": { width: "100%", height: "62px", backgroundColor: "#FFFFFF", display: "flex" },
    "& .salesRevenueTitle": { fontFamily: "Lato", fontSize: "16px", fontWeight: 500, color: "#375280" },
    "& .revenueAmount": { fontFamily: "Lato", fontSize: "20px", fontWeight: 900, color: "#059669" }
  },
  "& .salesRevenueTitle": { fontFamily: "Lato", fontSize: "16px", fontWeight: 500, color: "#375280" },
  "& .productContainer": { width: "97.5%", height: "120px", padding: "16px", marginTop: "20px", marginLeft: "10px",boxShadow: "0px 2px 8px 4px #00000014", display: "flex", gap: 24 },
  "& .soldUnits": { width: "119px", height: "34px", padding: "6px 20px", borderRadius: "6px", backgroundColor: "#E2E8F0", display: "flex", justifyContent: "center", alignItems: "center" },
  "& .returnUnits": { width: "144px", height: "34px", padding: "6px 20px", borderRadius: "6px", backgroundColor: "#FEE2E2", display: "flex", justifyContent: "center", alignItems: "center" },
  "& .primaryContainerSales": { width: "405px", height: "52px", marginTop: "20px", marginLeft: "10px", borderBottom: "1px solid #E2E8F0", display: "flex" },
  "& .allStoresTile": { fontFamily: "Lato", fontSize: "18px", fontWeight: 700, color: "#375280", width: "98%" },
  "& .salesRevenueReport": { fontFamily: "Lato", fontSize: "18px", fontWeight: 700, color: "#375280", width: "98%" },
  "& .optionClassName": { width: "77px", backgroundColor: "#FFFFFF", color: "#375280", fontFamily: "Lato", fontSize: "14px", fontWeight: 400, border: "1px solid #CCBEB1" },
  "& .averageRevenue": { fontFamily: "Lato", fontSize: "14px", fontWeight: 400, color: "#334155" },
  "& .averageAmt": { fontFamily: "Lato", fontSize: "24px", fontWeight: 700, color: "#375280" },
  "& .revenueMainContainer": { height: "98px", width: "50%", alignItems: "center", display: "flex", justifyContent: "center", flexDirection: "column", backgroundColor: "#F8F8F8" },

  "@media (min-width: 800px) and (max-width: 1300px)": {
    "& .flexItemContainer": {
      width: "39%", minHeight: "40px", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "26px", border: "1px solid #E2E8F0"
    }, "& .storeHeader": {
      width: "91%"
    }, 
    "& .revenuepart": {
      width: "91%"
    },
    "& .chartContainer": {
      width: "87%"
    },
    "& .productContainer": {
      width: "91%"
    },
    "& .soldUnits": {
      width: "100px"
    },
    "& .returnUnits": {
      width: "120px"
    },
  },

  "@media only screen and (max-width: 800px)": {
    "& .flexItemContainer": {
      width: "100%", minHeight: "40px", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "26px", border: "1px solid #E2E8F0"
    },
    "& .storeHeader": {
      width: "85%"
    },
    "& .revenuepart": {
      width: "85%"
    },
    "& .chartContainer": {
      width: "80%"
    },
    "& .productContainer": {
      width: "85%"
    },
    "& .soldUnits": {
      width: "90px",
    },
    "& .returnUnits": {
      width: "100px",
    },
  },
  "& .title-container": {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 20,
    "& .title": {
      fontFamily: "Lato, sans-serif",
      fontWeight: 700,
      fontSize: 24,
      color: "#375280",
    },
  },
});

// Customizable Area End
