import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
interface DeliveryAddressAttributes {
  name: string;
  country_code: string;
  phone_number: string;
  contact_number: string;
  street: string;
  zip_code: string;
  area: string;
  block: string;
  city: string;
  house_or_building_number: string;
  address_name: string;
  is_default: boolean;
  latitude: number;
  longitude: number;
}

interface DeliveryAddress {
  id: string;
  type: string;
  attributes: DeliveryAddressAttributes;
}

interface PaymentDetail {
  id: number;
  status: string;
  created_at: string;
  updated_at: string;
  charge_id: string;
  merchant_id: string | null;
  order_id: string;
  amount: number;
  currency: string;
  customer_id: string;
  reason: string;
  account_id: number;
  order_management_order_id: number;
  refund_id: string | null;
  refund_amount: string | null;
  refund_reason: string | null;
  seller_order_id: string | null;
  last_four_card_digit: string;
  payment_type: string;
  deleted: boolean;
}
interface OrderSellerSide {
  id: string;
  type: "order_seller_side";
  attributes: {
    total: string;
    account: string;
    sub_total: string;
    status: string;
    confirmed_at: string;
    in_transit_at: string | null;
    refunded_at: string | null;
    delivered_at: string | null;
    returned_at: string | null;
    placed_at: string;
    deliver_by: string | null;
    created_at: string;
    updated_at: string;
    order_deliver_time: string | null;
    cancelled_at: string | null;
    delivery_addresses: DeliveryAddress;
    order_deliver_date: string | null;
    order_return_date: string | null;
    order_status_id: number;
    order_return_time: string | null;
    order_number: string;
    payment_detail: PaymentDetail;
  };
}

interface ContactNumber {
  country_code: string;
  phone_number: string;
}

interface StoreOperatingHours {
  open: string;
  close: string;
  is_open: boolean;
}

interface StoreInfo {
  id: string;
  type: string;
  attributes: {
    store_name: string;
    description: string;
    address: string;
    area: string;
    block: string;
    mall_name: string;
    floor: string;
    unit_number: number;
    city: string;
    zipcode: string;
    driver_instruction: string;
    average_shipping_time: string;
    payment_mode: string[];
    store_operating_hours: {
      monday: StoreOperatingHours;
      tuesday: StoreOperatingHours;
      wednesday: StoreOperatingHours;
      thursday: StoreOperatingHours;
      friday: StoreOperatingHours;
      saturday: StoreOperatingHours;
      sunday: StoreOperatingHours;
    };
    status: string;
    latitude: number;
    longitude: number;
    is_open: boolean;
    image: string | null;
    email: string;
    contact_number: ContactNumber;
    expected_delivery_time: string;
  };
}

interface CatalogueVariantColor {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

interface CatalogueVariantSize {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

interface CatalogueVariant {
  id: string;
  type: string;
  attributes: {
    id: number;
    catalogue_id: number;
    catalogue_variant_color_id: number;
    catalogue_variant_color: CatalogueVariantColor;
    catalogue_variant_size_id: number;
    catalogue_variant_size: CatalogueVariantSize;
    price: string;
    stock_qty: number;
    on_sale: boolean | null;
    sale_price: string | null;
    discount_price: string | null;
    length: number | null;
    breadth: number | null;
    height: number | null;
    created_at: string;
    updated_at: string;
    sku: string;
    deactivate: boolean;
    low_stock_threshold: number;
    is_listed: boolean;
    front_image: string;
    back_image: string;
    side_image: string;
    pair_it_with: any[];
  };
  store_info: StoreInfo;
}

interface SubSubCategory {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    image: string;
  };
}

interface SubCategory {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    image: string;
  };
}

interface Category {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    status: string;
    created_at: string;
    updated_at: string;
    image: string;
  };
}

interface Catalogue {
  id: string;
  type: string;
  attributes: {
    name: string;
    brand: string | null;
    tags: { data: any[] };
    reviews: any[];
    sku: string | null;
    description: string;
    manufacture_date: string | null;
    length: number | null;
    breadth: number | null;
    height: number | null;
    stock_qty: number | null;
    availability: string | null;
    weight: number | null;
    price: string | null;
    recommended: boolean | null;
    on_sale: boolean | null;
    sale_price: string | null;
    discount: string | null;
    is_wishlist: boolean | null;
    product_number: string | null;
    primary_image: string;
    primary_price: string;
    gender: string;
    brand_name: string;
    material: string;
    fit: string;
    prodcut_care: string;
    list_the_product: string;
    fit_discription: string;
    is_published: boolean;
    category: Category;
    sub_category: SubCategory;
    sub_sub_category: SubSubCategory;
    service: any | null;
    average_rating: number;
    catalogue_variants: CatalogueVariant[];
    catalogue_variants_with_store: CatalogueVariant[];
  };
}

export interface OrderItemSeller {
  id: string;
  type: string;
  attributes: {
    id: number;
    order_management_order_id: number;
    variant_availability: string;
    quantity: number;
    unit_price: string;
    total_price: string;
    old_unit_price: string | null;
    status: string;
    catalogue_id: number;
    catalogue_variant_id: number;
    order_status_id: number;
    placed_at: string;
    confirmed_at: string;
    in_transit_at: string;
    delivered_at: string;
    cancelled_at: string | null;
    refunded_at: string | null;
    manage_placed_status: boolean;
    manage_cancelled_status: boolean;
    created_at: string;
    updated_at: string;
    reason_of_rejection: string | null;
    rejected_at: string | null;
    accept_order_upload_time: string | null;
    item_return_date: string;
    item_return_time: string | null;
    order_statuses: any | null;
    catalogue: Catalogue;
    catalogue_variant: CatalogueVariant;
  };
}

export interface SellerOrderSeller {
  id: string;
  type: "seller_order_seller";
  attributes: {
    id: number;
    status: string;
    accept_order_upload_time: string | null;
    order_items: OrderItemSeller[];
    order_management_order: OrderSellerSide;
  };
}

interface OrderManagementOrderAttributes {
  in_transit_at: string | null;
  delivered_at: string | null;
  cancelled_at: string | null;
  refunded_at: string | null;
  confirmed_at: string;
  returned_at: string | null;
  placed_at: string;
  deliver_by: string | null;
  status: string;
  order_status_id: number;
  created_at: string;
  total: string;
  sub_total: string;
  updated_at: string;
  order_deliver_time: string | null;
  account: string;
  order_number: string;
  order_deliver_date: string | null;
  order_return_date: string | null;
  order_return_time: string | null;
  delivery_addresses: DeliveryAddress;
  payment_detail: PaymentDetail;
}

interface OrderManagementOrder {
  id: string;
  type: string;
  attributes: OrderManagementOrderAttributes;
}

interface OrderItemAttributes {
  status: string;
  placed_at: string;
  confirmed_at: string;
  in_transit_at: string | null;
  delivered_at: string | null;
  cancelled_at: string | null;
  rejected_at: string | null;
  process_at: string;
  shipped_at: string | null;
  return_at: string | null;
  return_cancel_at: string | null;
  return_pick_at: string | null;
  quantity: number;
  unit_price: string;
  total_price: string;
  reason_of_rejection: string | null;
  name: string;
  brand_name: string;
  catalogue_variant_color: string;
  catalogue_variant_sku: string;
  store_name: string;
  catalogue_variant_size: string;
  catalogue_variant_front_image: string;
  catalogue_variant_back_image: string;
  catalogue_variant_side_image: string;
  driver_name: string | null;
  driver_latitude: number | null;
  driver_longitude: number | null;
  driver_phone_number: string | null;
  otp: string | null;
}
interface CatalogueAttributes {
  name: string;
  // Add other properties of the catalogue if necessary
}
interface OrderItem {
  id: string;
  type: string;
  attributes: OrderItemAttributes;
}
interface SellerOrderAttributes {
  id: number;
  status: string;
  accept_order_upload_time: string | null;
  order_items: OrderItem[];
  order_management_order: OrderManagementOrder;
}

interface SellerOrder {
  id: string;
  type: string;
  attributes: SellerOrderAttributes;
}
// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  apiRecall: Function
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  page: number;
  tableAllOrder: SellerOrder[];
  rejectOpen: boolean;
  numberData: string;
  short: string[],
  visible: boolean,
  reloadTime: number,
  isLoading: boolean,
  isAlert: boolean,
  rejectId: number,
  confirmId: number,
  metaData: {
    total_pages: number,
    current_page: number,
    total_record: number,
    prev_page: number,
    next_page: number
  },
  metaDataProcess: {
    total_pages: number,
    current_page: number,
    total_record: number,
    prev_page: number,
    next_page: number
  },
  activeTab: string,
  readyForPage: number,
  SellerOrdersProcessedView: SellerOrderSeller[],
  SellerOrdersProcessedViewProcess: SellerOrderSeller[],
  SellerUnderProcess: SellerOrderSeller[],
  SellerRefunded: SellerOrderSeller[],
  isAlertReadyForCollection: boolean,
  isLoadingReady: boolean,
  descriptionReson:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SellersAllOrderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMy_OrdersCallId: string = "";
  getAllOrderCallId: string = "";
  getRejectOrderCallId: string = "";
  getTimeOrderCallId: string = "";
  getSellerOrdersProcessedApiCallingId: string = ""
  getSellerReturnnInProcessedApiCallingId: string = "";
  getSellerUnderProcessApiCallingId: string = "";
  getSellerAcceptApiCallId: string = "";
  getSellerRejectApiCallId: string = "";
  getSellerRefundedApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      descriptionReson:"",
      txtInputValue: "",
      page: 1,
      tableAllOrder: [],
      rejectOpen: false,
      numberData: "",
      short: ["Out of Stock", "Pricing Error", "Order Processing Error", "Payment Processing Issue", "Order Limit Exceeded", "Violation of Purchase Policy"],
      visible: false,
      reloadTime: 45,
      isLoading: true,
      isAlert: false,
      rejectId: 0,
      confirmId: 0,
      metaData: {
        total_pages: 0,
        current_page: 0,
        total_record: 0,
        prev_page: 0,
        next_page: 0
      },
      metaDataProcess: {
        total_pages: 0,
        current_page: 0,
        total_record: 0,
        prev_page: 0,
        next_page: 0
      },
      readyForPage: 1,
      SellerOrdersProcessedView: [],
      SellerOrdersProcessedViewProcess: [],
      SellerUnderProcess: [],
      SellerRefunded: [],
      activeTab: 'Return Request',
      isAlertReadyForCollection: false,
      isLoadingReady: true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getRejectOrderCallId !== null &&
      this.getRejectOrderCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonCategory = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJsonCategory) {
        this.setState({ isLoading: true })
        this.props.apiRecall()
      }
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.manageUpdateResponce(apiRequestCallId, responseJson)
    this.manageUpdateResponce1(apiRequestCallId, responseJson)

    this.manageUpdateResponce2(apiRequestCallId, responseJson)
    this.manageUpdateResponce3(apiRequestCallId, responseJson)
    this.manageUpdateResponce4(apiRequestCallId, responseJson)

    // Customizable Area End
  }

  // Customizable Area Start
  navigateToReturnDetail = ({ productDetailId, props, screenName }: { productDetailId: string | undefined, props: unknown, screenName: string }) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    productDetailId && message.addData(getName(MessageEnum.NavigationScreenNameMessage), productDetailId);
    runEngine.sendMessage(message.id, message);
  }
  handleCancel = () => {
    this.setState({ visible: false });
  };


  handleOk = (confirmid: number) => {
    this.setState({ visible: true, confirmId: confirmid });
  };

  handleSingleChangeNumber = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ numberData: event.target.value as string });
  };
  handleOpen1 = (rejectid: number) => {
    this.setState({ rejectOpen: true, rejectId: rejectid })
  };

  handleClose1 = () => {
    this.setState({ rejectOpen: false })
  };
  dateFormetTime = (created_at: string) => {
    if (!created_at) return "";
    const date = new Date(created_at);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${minutesStr} ${ampm}`;
  }
  async componentDidMount() {
    this.allOrderData();
    this.getSellerOrdersReturnApi();
    this.getSellerOrdersInprocessApi();
    this.getSellerUnderprocessApi();
    this.getSellerRefundedApi();
  }
  manageUpdateResponce = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getSellerOrdersProcessedApiCallingId) {
      this.setState({metaData:responseJson.meta,  SellerOrdersProcessedView: responseJson.data, isLoadingReady: false, isAlertReadyForCollection: responseJson.error === "No orders found." ? true : false })

    }
  }

  manageUpdateResponce1 = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getSellerReturnnInProcessedApiCallingId) {
      this.setState({metaData:responseJson.meta,  SellerOrdersProcessedViewProcess: responseJson.data,  isLoadingReady: false,isAlertReadyForCollection: responseJson.error === "No orders found." ? true : false })

    }

  }

  manageUpdateResponce2 = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getSellerUnderProcessApiCallingId) {
      this.setState({metaData:responseJson.meta, SellerUnderProcess: responseJson.data, isLoadingReady: false, isAlertReadyForCollection: responseJson.error === "No orders found." ? true : false })

    }
  }

  manageUpdateResponce3 = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getSellerRefundedApiCallId) {
      this.setState({metaData:responseJson.meta, SellerRefunded: responseJson.data, isLoadingReady: false, isAlertReadyForCollection: responseJson.error === "No orders found." ? true : false })

    }
  }
  manageUpdateResponce4 = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getSellerAcceptApiCallId) {
      if(responseJson){
        this.setState({ visible: false });
      }

    }
  }
  rejectData = () => {
    this.setState({ rejectOpen: false })
    this.rejectOrderData()
  }
  timeData = () => {
    this.setState({ visible: false })
    this.timeOrderData()
  }
  allOrderData = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllOrderCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTableAllOrder}&page=${this.state.page}&per_page=10`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  rejectOrderData = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRejectOrderCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressToAddressMethod
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateAccept}?seller_order_id=${this.state.rejectId}&type=return_reject&reason_of_rejection=${this.state.numberData}&rejection_detail=${this.state.descriptionReson}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  timeOrderData = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTimeOrderCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressToAddressMethod
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRejectOrder}?seller_order_id=${this.state.confirmId}&type=accept&accept_order_upload_time=${this.state.reloadTime}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  getSellerOrdersReturnApi = async () => {
    const token = await getStorageData("auth-token");

    const headers = {
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSellerOrdersProcessedApiCallingId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSellerReturnRefund}?type=return_request&page=${this.state.readyForPage}&per_page=10`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  getSellerOrdersInprocessApi = async () => {
    const token = await getStorageData("auth-token");

    const headers = {
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSellerReturnnInProcessedApiCallingId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSellerReturnRefund}?type=return_in_process&page=${this.state.readyForPage}&per_page=10`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getSellerUnderprocessApi = async () => {
    const token = await getStorageData("auth-token");

    const headers = {
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSellerUnderProcessApiCallingId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSellerReturnRefund}?type=refund_under_process&page=${this.state.readyForPage}&per_page=10`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getSellerRefundedApi = async () => {
    const token = await getStorageData("auth-token");

    const headers = {
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSellerRefundedApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSellerReturnRefund}?type=refunded&page=${this.state.readyForPage}&per_page=10`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  AcceptRequest = async (sellerId:any) => {
    const token = await getStorageData("auth-token");

    const headers = {
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSellerAcceptApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateAccept}?type=return_accept&seller_order_id=${sellerId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  changeDisc = (value: string) => {
    this.setState({ descriptionReson: value })
  }


  // Customizable Area End
}
