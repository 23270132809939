import { IBlock } from "../../../../../../framework/src/IBlock";
import { Message } from "../../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../../../../framework/src/Messages/MessageEnum";
import { ErrorMessage } from "../../../CreateSellerStoreController";
import { apiCall } from "../../../../../../components/src/APICall";
import { getStorageData } from "framework/src/Utilities";
import { logoutSellerNavigation } from "../../../../../../components/src/Seller/logOut";

interface IDataDownloadTemplates {
  id: string;
  type: string;
  attributes: {
    id: number;
    file_name: string;
    file: string;
  };
}

interface IDownloadTemplatesResp {
  data: IDataDownloadTemplates[];
}

// Customizable Area End

export const configJSON = require("../../../config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  handleRedirect: (redirect: string) => void;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  downloadTemplates: IDataDownloadTemplates[];
  isLoading: boolean;
  message: string;
  severity: "error" | "success";
  isAlert: boolean;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DownloadTemplatesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDownloadTemplatesAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      downloadTemplates: [],
      isLoading: true,
      message: "",
      severity: "success",
      isAlert: false,
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseData && !responseData.errors && !responseData.error && !responseData.message) {
        this.apiDownloadTemplatesSuccess(apiRequestCallId, responseData);
      } else if (responseData && (responseData.error || responseData.errors || responseData.message)) {
        this.apiDownloadTemplatesFailer(responseData);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  apiDownloadTemplatesSuccess = async (
    apiRequestCallId: string,
    responseJson: IDownloadTemplatesResp
  ) => {
    if (apiRequestCallId === this.getDownloadTemplatesAPICallId) {
      if (responseJson.data)
        this.handleapiDownloadTemplatesResp(responseJson.data);
    }
  };

  handleapiDownloadTemplatesResp = (response: IDataDownloadTemplates[]) => {
    this.setState((prevState) => ({
      ...prevState,
      downloadTemplates: response,
      isLoading: false,
    }));
  };

  apiDownloadTemplatesFailer = (responseJson: ErrorMessage & {message: string}) => {
    if(responseJson.message === "You are not an authorized user or proper role basic"){
      this.handleRedirectToHome("You are not an authorized person, Please Login with valid User Credential");
    }
    else if (responseJson.errors[0].token) {
      this.handleRedirectToHome(responseJson.errors[0].token);
    }
  };

  handleRedirectToHome = (message: string) => {
    this.setState((prevState) => ({
      ...prevState,
      isAlert: true,
      message: message,
      severity: "error",
      isLoading: false,
    }), () => {
      setTimeout(() => {
        this.props.handleRedirect("Home");
        logoutSellerNavigation();
      }, 2000);
    });
  }

  onAlertDTSnackClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      isAlert: !prevState.isAlert,
    }));
  };

  async componentDidMount() {
    // Customizable Area Start
    const userData = await getStorageData("userRole", true);
    if(userData.userType==="buyer") history.back();
    else this.downLoadTemplates();
    // Customizable Area End
  }

  downLoadTemplates = async () => {
    this.getDownloadTemplatesAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getSellerDownloadTemplatesEndPoint,
      token: await this.getTokenStorage(),
    });
  };

  getTokenStorage = async () => {
    return await getStorageData("auth-token");
  };

  handleDownloadTemplates = (file: string, fileName: string) => {
    const a = document.createElement("a");
    a.href = file;
    a.download = "";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    this.setState((prevState) =>({...prevState, isAlert: true, message: `File ${fileName} will be downloaded Please check in your downloads folder`, severity: "success"}))
  };
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
