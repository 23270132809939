import { enHeader, arHeader } from "./Headerlang";
import { enSidebar, arSidebar } from "./siderbarLang";

export const availableResources = {
  en: {
    header: enHeader,
    sidebar: enSidebar,
  },
  ar: {
    header: arHeader,
    sidebar: arSidebar,
  },
};
