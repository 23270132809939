// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  styled,
  Snackbar,
  Tooltip,
  Fade,
  Backdrop,
} from "@material-ui/core";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import {
  setStorageData,
  removeStorageData,
  getStorageData,
} from "../../../framework/src/Utilities";
import Alert from "@material-ui/lab/Alert";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import {
  logout,
  sidebarimg,
} from "../../../blocks/customisableuserprofiles2/src/assets";
import {
  supportHelp,
  analytics,
  catalogue,
  sale,
  earnings,
  boardDashboard,
  order,
  userActivity,
  portfolio,
  inventory,
} from "../Seller/assets";
import { faceLogo } from "../../../blocks/email-account-registration/src/assets";
import { store } from "../../../blocks/customform/src/assets";
import {clients} from "../Seller/assets"
import {
  StyledButton,
  StyledButton1,
  StyledButtonSigein,
  StyledDrawer,
} from "../Header";
import i18n from "../i18next/i18n";

interface S {
  openMenu: boolean;
  selectedItem: string | null;
  isAlert: boolean;
  anchorEl: HTMLElement | null;
  open1: boolean;
  auth: string;
  basePathe:string;
}
export interface Props {
  navigation?: any;
  id?: string;
  className?: string;
}
// Customizable Area End

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class StylishSidebar extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      openMenu: false,
      selectedItem: "",
      isAlert: false,
      anchorEl: null,
      open1: false,
      auth: "",
      basePathe:""
    };
    // Customizable Area End
  }
 

  headerNavigation = async (pageLink: string, name: string) => {
    await setStorageData("selectedItem", name);
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pageLink);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
    this.setState({
      selectedItem: name,
    });
  };

  notNavigation = async (name: string) => {
    await setStorageData("selectedItem", name);
    this.setState({ selectedItem: name, isAlert: true });
  };

  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };

  headerRedirect = (block: string) => {
    this.props.navigation.navigate(block);
  };

  handleOpen1 = () => {
    this.setState({ open1: true, anchorEl: null });
  };
  hendelUrl = async () => {
    const baseUrl = new URL(document.URL).href.split('/').pop();
    await setStorageData("selectedItem", baseUrl)
    const selectedItemUrl = await getStorageData("selectedItem")
    this.setState({ basePathe: selectedItemUrl })
  }
  async componentDidMount() {
    this.hendelUrl()
  }
  handleClose1 = () => {
    this.setState({ open1: false });
  };
  
  logout = async () => {
    ["orderNumber", "auth-token", "sms-token","userRole"].map(async (value) => {
      await removeStorageData(value);
    });
    this.setState({ open1: false });
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  transStaylistSide = (skeys: string) => {
    return i18n.t(skeys, { ns: "sidebar"})
  }

  render() {
    // Customizable Area Start
    const { selectedItem } = this.state;
    const isItemSelected = selectedItem !== "";
    return (
      <>
        <StyledWrapper>
          <Box className="sidebar-container">
            <Box className="sidebar-inner-container" dir={i18n.dir()}>
              <Box
                className="logo-container"
              >
                <img src={faceLogo} alt="fashLogo" className="logo" />
              </Box>
              <Box
                className="link"
                onClick={() => this.headerNavigation('StylishDashboard', "StylishDashboard")}
                style={{
                  backgroundColor:
                    this.state.basePathe === "StylishDashboard"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state.basePathe === "StylishDashboard"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className="link">
                  <Tooltip title="Dashboard">
                    <img src={boardDashboard} />
                  </Tooltip>
                  <span className="link-text">{this.transStaylistSide("Dashboard")}</span>
                </Typography>
              </Box>
              <Box
                className="link"
                onClick={() => this.headerNavigation('StylishCatalogue', "StylishCatalogue")}
                style={{
                  backgroundColor:
                  this.state.basePathe === "StylishCatalogue"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state.basePathe === "StylishCatalogue"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className="link">
                  <Tooltip title="Catalogue">
                    <img src={catalogue} />
                  </Tooltip>
                  <span className="link-text">{this.transStaylistSide("Catalogue")}</span>
                </Typography>
              </Box>
              <Box
                className="link"
                onClick={() => this.notNavigation("SellersAllOrderDetailsPage")}
                style={{
                  backgroundColor:
                  this.state?.basePathe === "SellersAllOrderDetailsPage"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state?.basePathe === "SellersAllOrderDetailsPage"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className="link">
                  <Tooltip title="Orders">
                    <img src={order} />
                  </Tooltip>
                  <span className="link-text">{this.transStaylistSide("Orders")}</span>
                </Typography>
              </Box>
              <Box
                className="link"
                onClick={() => this.headerNavigation('StylishClients', "StylishClients")}
                style={{
                  backgroundColor:
                  this.state.basePathe === "StylishClients"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state.basePathe === "StylishClients"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className="link">
                  <Tooltip title="Stores">
                    <img src={clients} />
                  </Tooltip>
                  <span className="link-text">{this.transStaylistSide("Clients")}</span>
                </Typography>
              </Box>
              <Box
                className="link"
                onClick={() => this.headerNavigation("PhotoLibrary", "PhotoLibrary")}
                style={{
                  backgroundColor:
                  this.state.basePathe === "PhotoLibrary"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state.basePathe === "PhotoLibrary"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className="link">
                  <Tooltip title="Stores">
                    <img src={portfolio} />
                  </Tooltip>
                  <span className="link-text">{this.transStaylistSide("Portfolio")}</span>
                </Typography>
              </Box>
              <Box
                className="link"
                onClick={() => this.headerNavigation("InventoryManagement", "InventoryManagement")}
                style={{
                  backgroundColor:
                  this.state.basePathe === "InventoryManagement"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state.basePathe === "InventoryManagement"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className="link">
                  <Tooltip title="Stores">
                    <img src={inventory} />
                  </Tooltip>
                  <span className="link-text">{this.transStaylistSide("Inventory Management")}</span>
                </Typography>
              </Box>
              <Box
                className="link"
                onClick={() => this.notNavigation("AnalyticsInsights")}
                style={{
                  backgroundColor:
                  this.state.basePathe === "AnalyticsInsights"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state.basePathe ===
                      "AnalyticsInsights"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className="link">
                  <Tooltip title="Analytics and Insights">
                    <img src={analytics} />
                  </Tooltip>
                  <span className="link-text">{this.transStaylistSide("Analytics and Insights")}</span>
                </Typography>
              </Box>
              <Box
                className="link"
                onClick={() => this.notNavigation("salesReport")}
                style={{
                  backgroundColor:
                  this.state.basePathe === "salesReport"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state.basePathe === "salesReport"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className="link">
                  <Tooltip title="Sales Report">
                    <img src={sale} />
                  </Tooltip>
                  <span className="link-text">{this.transStaylistSide
                  ("Sales Report")}</span>
                </Typography>
              </Box>
              <Box
                className="link"
                onClick={() => this.headerNavigation("EarningsAnalyticsWebPage","EarningsAnalyticsWebPage")}
                style={{
                  backgroundColor:
                  this.state.basePathe === "EarningsAnalyticsWebPage"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state.basePathe === "EarningsAnalyticsWebPage"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className="link">
                  <Tooltip title="Earnings">
                    <img src={earnings} />
                  </Tooltip>
                  <span className="link-text">{this.transStaylistSide("Earnings")}</span>
                </Typography>
              </Box>
              <Box
                className="link"
                onClick={() => this.headerNavigation('MyActivity', "MyActivity")}
                style={{
                  backgroundColor:
                    this.state.basePathe === "MyActivity"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state.basePathe === "MyActivity"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className="link">
                  <Tooltip title="Your Activity">
                    <img src={userActivity} />
                  </Tooltip>
                  <span className="link-text">{this.transStaylistSide("Your Activity")}</span>
                </Typography>
              </Box>
              <Box
                className="link"
                onClick={() => this.headerNavigation("SellerHelp", "SellerHelp")}
                style={{
                  backgroundColor:
                  this.state.basePathe === "SellerHelp"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state.basePathe === "SellerHelp"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className="link">
                  <Tooltip title="Get Help">
                    <img src={supportHelp} />
                  </Tooltip>
                  <span className="link-text">{this.transStaylistSide("Get Help")}</span>
                </Typography>
              </Box>
              <hr style={{border: "1px solid #94A3B8"}}/>
              <Box
                className="link"
                onClick={() => this.notNavigation("exploreBuyer")}
                style={{
                  backgroundColor:
                  this.state.basePathe === "exploreBuyer"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state.basePathe === "exploreBuyer"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className="link">
                  <Tooltip title="Get Help">
                    <img src={store}/>
                  </Tooltip>
                  <span className="link-text">{this.transStaylistSide("Explore as Buyer")}</span>
                </Typography>
                  <KeyboardArrowRightIcon className="link-text" style={{transform : `${i18n.dir()==="rtl" ? "rotate(180deg)" : "rotate(0deg)"}`}} />
              </Box>
            </Box>

            <Box
              className="link"
              onClick={() => this.handleOpen1()}
              style={{
                backgroundColor:
                this.state.basePathe === "logout"
                    ? "#F1F5F9"
                    : "transparent",
                textDecoration: "none",
                padding: "0px 34px 32px"
              }}
              dir={i18n.dir()}
            >
              <div
                className="wishlist-icon"
                style={{
                  backgroundImage:
                  this.state.basePathe === "logout"
                      ? `url(${sidebarimg})`
                      : "none",
                }}
              />
              <Typography className="link1" dir={i18n.dir()}>
                <Tooltip title="Logout">
                  <img src={logout} />
                </Tooltip>
                <span className="link-text">{this.transStaylistSide("Logout")}</span>
              </Typography>
            </Box>
          </Box>


          <StyledDrawer
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.open1}
            onClose={this.handleClose1}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            dir={i18n.dir()}
          >
            <Fade
              in={this.state.open1}
              style={{
                borderRadius: "10px",
                maxWidth: "320px",
                backgroundColor: "white",
              }}
            >
              <div
                style={{ textAlign: "center", padding: "10px 30px 30px 30px" }}
              >
                <Box
                  id="transition-modal-title"
                  style={{
                    fontFamily: "Lato",
                    width: "300px",
                    fontSize: "18px",
                    color: "#375280",
                    fontWeight: 900,
                    marginLeft: "10px",
                  }}
                >
                  {this.state.auth === null ? "" : this.transStaylistSide("Log Out")}
                </Box>
                <h2
                  id="transition-modal-title"
                  style={{
                    fontFamily: "Lato",
                    width: "300px",
                    fontSize: "18px",
                    color: "#375280",
                    fontWeight: 600,
                    marginLeft: "10px",
                  }}
                >
                  {this.state.auth === null
                    ? this.transStaylistSide("You need to sign in to access Profile")
                    : this.transStaylistSide("Are you sure you want to log out your account?")}
                </h2>
                <div
                  style={{
                    marginTop: "40px",
                    display: "flex",
                    gap: "20px",
                    justifyContent: "space-between",
                  }}
                >
                  {this.state.auth === null ? (
                    <StyledButton1
                      style={{ fontSize: "18px" }}
                      onClick={this.handleClose1}
                    >
                      {this.transStaylistSide("Cancel")}
                    </StyledButton1>
                  ) : (
                    <StyledButton
                      style={{ fontSize: "18px", lineHeight: "20px" }}
                      onClick={() => this.logout()}
                    >
                      {this.transStaylistSide("Logout")}
                    </StyledButton>
                  )}
                  {this.state.auth === null ? (
                    <StyledButtonSigein
                      style={{ fontSize: "18px" }}
                      onClick={() =>
                        this.headerRedirect("EmailAccountLoginBlock")
                      }
                    >
                      {this.transStaylistSide("Sign In")}
                    </StyledButtonSigein>
                  ) : (
                    <StyledButton1
                      style={{ fontSize: "18px" }}
                      onClick={this.handleClose1}
                    >
                      {this.transStaylistSide("Cancel")}
                    </StyledButton1>
                  )}
                </div>
              </div>
            </Fade>
          </StyledDrawer>
          <Snackbar
            open={this.state.isAlert}
            autoHideDuration={3000}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
            onClose={this.oncloseAlert}
            data-test-id="alertTestId"
            dir={i18n.dir()}
          >
            <Alert
              variant="filled"
              severity="info"
              style={{ width: "300px", marginTop: "10px" }}
            >
              Coming Soon !!!
            </Alert>
          </Snackbar>
        </StyledWrapper>
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start

const StyledWrapper = styled(Box)({
  "& .sidebar-container": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRight: "1px solid #E2E8F0",
    height: "100%",
    "& .sidebar-inner-container": {
      padding: "40px 25px",
    }
  },
  "& .logo-container": {
    paddingBottom: 30,
  },
  "& .link": {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    color: "#94A3B8",
    fontFamily: "Lato , sans-serif",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontWeight: 400,
    lineHeight: "21.6px !important",
    padding: `${i18n.dir() === 'ltr' ? "8px 7px 8px 20px " : "8px 20px 8px 7px"}`,
    gap: "10px",
    "& .wishlist-icon": {
      width: "2px",
      height: "40px",
      marginLeft: "-7px",
      position: "absolute",
    },
    "& .link-text": {
      padding: 0,
      "@media (max-width: 750px)": {
        display: "none",
      },
    },
  },
  "& .link1": {
    color: "#DC2626",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontFamily: "Lato , sans-serif",
    fontSize: "18px !important",
    fontStyle: "normal !important",
    fontWeight: 700,
    lineHeight: "21.6px !important",
    padding: `${i18n.dir() === 'ltr' ? "8px 7px 8px 20px " : "8px 20px 8px 7px"}`,
    gap: "10px",
  },
});
// Customizable Area End
