import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  ErrorMessage,
  FormError,
  FormErrorTouched,
  Step3State,
} from "./CreateSellerStoreController";
import {
  DayOpenClose,
  MyStoreAPIResponse,
  MyStoreList,
} from "./MySellerStoreController";
import { apiCall } from "../../../components/src/APICall";
import { getStorageData } from "framework/src/Utilities";
import { ErrorMessageResponse } from "./SellerStoreController";
import { ChangeEvent } from "react";
import dayjs from "dayjs";
import { logoutSellerNavigation } from "../../../components/src/Seller/logOut";
export interface StoreByIdAPIResponse {
  data: MyStoreList;
}

export interface StoreOperatingHours {
  monday: DayOpenClose;
  tuesday: DayOpenClose;
  wednesday: DayOpenClose;
  thursday: DayOpenClose;
  friday: DayOpenClose;
  saturday: DayOpenClose;
  sunday: DayOpenClose;
}

interface IUpdateResponse {
  store_operating_hours: StoreOperatingHours;
  average_shipping_time: string;
  meta: {
    message: string;
  };
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  storeIdResp: MyStoreList;
  operatingHours: string[];
  errorsMessage: {
    errorStoreUpload?: string;
    storeUploadError?: boolean;
  };
  storeProfile: {
    selectedFile: File | null;
    previewUrl: string;
  };
  isImageDialog: boolean;
  initialTime: Step3State;
  isTimeDialogOpen: boolean;
  isLoading: boolean;
  alertState: {
    isAlert: boolean;
    message: string;
    severity: "error" | "warning" | "info" | "success";
  };
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SellerStoreProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  storeByIdAPICallId: string = "";
  storeStatusUpdateAPICallId: string = "";
  storeUpdateImgAPICallId: string = "";
  storeUpdateTimingsAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      storeIdResp: {} as MyStoreList,
      operatingHours: [],
      errorsMessage: {
        errorStoreUpload: "",
        storeUploadError: false,
      },
      storeProfile: {
        selectedFile: null,
        previewUrl: "",
      },
      isImageDialog: false,
      isLoading: true,
      initialTime: {
        monFrom: null,
        monTo: null,
        tueFrom: null,
        tueTo: null,
        wedFrom: null,
        wedTo: null,
        thuFrom: null,
        thuTo: null,
        friFrom: null,
        friTo: null,
        satFrom: null,
        satTo: null,
        sunFrom: null,
        sunTo: null,
        averageTime: "",
      },
      isTimeDialogOpen: false,
      alertState: {
        isAlert: false,
        message: "",
        severity: "success",
      },
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors && !responseJson.error) {
        this.apiSuccess(apiRequestCallId, responseJson);
      } else if (responseJson && (responseJson.errors || responseJson.error)) {
        this.apiFailure(responseJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    const storeId = +(await this.getStoreId());
    this.getStoreByIdApi(storeId);
    // Customizable Area End
  }

  apiSuccess = async (
    apiRequestCallId: string,
    responseJson: StoreByIdAPIResponse & MyStoreAPIResponse & IUpdateResponse
  ) => {
    if (apiRequestCallId === this.storeByIdAPICallId) {
      if (responseJson) {
        const attributes = responseJson.data.attributes;
        const dateData = attributes.store_operating_hours;
        this.setState((prevState) => ({
          ...prevState,
          storeIdResp: responseJson.data,
          operatingHours: this.displayOperatingHours(
            attributes.store_operating_hours as StoreOperatingHours
          ),
          storeProfile: {
            ...prevState.storeProfile,
            previewUrl: attributes.image,
          },
          isLoading: false,
          initialTime: {
            ...prevState.initialTime,
            monFrom: this.convertToISOTime(dateData.monday.open),
            monTo: this.convertToISOTime(dateData.monday.close),
            tueFrom: this.convertToISOTime(dateData.tuesday.open),
            tueTo: this.convertToISOTime(dateData.tuesday.close),
            wedFrom: this.convertToISOTime(dateData.wednesday.open),
            wedTo: this.convertToISOTime(dateData.wednesday.close),
            thuFrom: this.convertToISOTime(dateData.thursday.open),
            thuTo: this.convertToISOTime(dateData.thursday.close),
            friFrom: this.convertToISOTime(dateData.friday.open),
            friTo: this.convertToISOTime(dateData.friday.close),
            satFrom: this.convertToISOTime(dateData.saturday.open),
            satTo: this.convertToISOTime(dateData.saturday.close),
            sunFrom: this.convertToISOTime(dateData.sunday.open),
            sunTo: this.convertToISOTime(dateData.sunday.close),
            averageTime: attributes.average_shipping_time,
          },
        }));
      }
    }
    if (apiRequestCallId === this.storeStatusUpdateAPICallId) {
      if (responseJson.data) {
        this.setState((prevState) => ({
          ...prevState,
          storeIdResp: {
            ...prevState.storeIdResp,
            attributes: {
              ...prevState.storeIdResp.attributes,
              is_open: !prevState.storeIdResp.attributes.is_open,
            },
          },
          alertState: {
            ...prevState.alertState,
            severity: "success",
            message: "Store status Update Successfully",
            isAlert: true,
          },
        }));
      }
    }
    if (apiRequestCallId === this.storeUpdateImgAPICallId) {
      if (responseJson) {
        this.setState((prevState) => ({
          ...prevState,
          storeIdResp: responseJson.data,
          operatingHours: this.displayOperatingHours(
            responseJson.data.attributes
              .store_operating_hours as StoreOperatingHours
          ),
          storeProfile: {
            ...prevState.storeProfile,
            previewUrl: responseJson.data.attributes.image,
          },
          isImageDialog: !prevState.isImageDialog,
          alertState: {
            ...prevState.alertState,
            isAlert: true,
            severity: "success",
            message: "Store profile Update Successfully"
          },
        }));
      }
    }
    if (apiRequestCallId === this.storeUpdateTimingsAPICallId) {
      if (responseJson) {
        const updateStoreData = responseJson.store_operating_hours;
        this.setState((prevState) => ({
          ...prevState,
          initialTime: {
            ...prevState.initialTime,
            monFrom: this.convertToISOTime(updateStoreData.monday.open),
            monTo: this.convertToISOTime(updateStoreData.monday.close),
            tueFrom: this.convertToISOTime(updateStoreData.tuesday.open),
            tueTo: this.convertToISOTime(updateStoreData.tuesday.close),
            wedFrom: this.convertToISOTime(updateStoreData.wednesday.open),
            wedTo: this.convertToISOTime(updateStoreData.wednesday.close),
            thuFrom: this.convertToISOTime(updateStoreData.thursday.open),
            thuTo: this.convertToISOTime(updateStoreData.thursday.close),
            friFrom: this.convertToISOTime(updateStoreData.friday.open),
            friTo: this.convertToISOTime(updateStoreData.friday.close),
            satFrom: this.convertToISOTime(updateStoreData.saturday.open),
            satTo: this.convertToISOTime(updateStoreData.saturday.close),
            sunFrom: this.convertToISOTime(updateStoreData.sunday.open),
            sunTo: this.convertToISOTime(updateStoreData.sunday.close),
            averageTime: responseJson.average_shipping_time,
          },
          isTimeDialogOpen: !prevState.isTimeDialogOpen,
          storeIdResp: {
            ...prevState.storeIdResp,
            attributes: {
              ...prevState.storeIdResp.attributes,
              store_operating_hours: responseJson.store_operating_hours,
            },
          },
          alertState: {
            ...prevState.alertState,
            isAlert: !prevState.alertState.isAlert,
            message: responseJson.meta.message,
            severity: "success"
          },
          operatingHours: this.displayOperatingHours(
            responseJson.store_operating_hours
          ),
        }));
      }
    }
  };

  apiFailure = (responseJson: ErrorMessage & ErrorMessageResponse) => {
    if (responseJson.error) {
      this.setState((prevState) => ({
        ...prevState,
        alertState: {
          ...prevState.alertState,
          isAlert: true,
          message: responseJson.error,
          severity: "error",
        },
      }));
    } else if (responseJson.errors[0].token) {
      this.setState((prevState) => ({
        ...prevState,
        alertState: {
          ...prevState.alertState,
          isAlert: true,
          message: responseJson.errors[0].token as string,
          severity: "error",
        },
        isTimeDialogOpen: !prevState.isTimeDialogOpen
      }),() => {
        setTimeout(() => {
          this.handleRedirect("Home");
          logoutSellerNavigation();
        }, 2000);
      });
    }
  };

  getStoreByIdApi = async (storeId: number) => {
    this.storeByIdAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.createStoreEndPoint}/${storeId}`,
      token: await this.getTokenData(),
    });
  };

  getTokenData = async () => {
    return await getStorageData("auth-token");
  };

  getStoreId = async () => {
    return await getStorageData("store-id");
  };

  handleRedirect = (path: string) => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };

  displayOperatingHours(hours: StoreOperatingHours): string[] {
    const days = Object.keys(hours) as (keyof StoreOperatingHours)[];

    return days.map((day) => {
      const { open, close, is_open } = hours[day];
      return `${
        is_open ? `${open} - ${close}` : "Closed"
      } For ${this.capitalize(day)}`;
    });
  }

  capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  handleStoreStatusUpdate = async (id: string, status: boolean) => {
    this.storeStatusUpdateAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpPutMethod,
      endPoint: `${
        configJSON.storeStatusUpdateEndPoint
      }id=${id}&status=${!status}`,
      token: await this.getTokenData(),
    });
  };

  handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      this.setState((prevState) => ({
        ...prevState,
        storeProfile: {
          ...prevState.storeProfile,
          selectedFile: file,
          previewUrl: URL.createObjectURL(file),
        },
        errorsMessage: {
          ...prevState.errorsMessage,
          errorStoreUpload: "",
          storeUploadError: false,
        },
      }));
    }
  };

  validateStoreUpload = () => {
    const { selectedFile } = this.state.storeProfile;
    const maxFileSize = 5 * 1024 * 1024; // 5 MB, change this to your desired size limit
    const validFileTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (!selectedFile) {
      this.setState((prevState) => ({
        ...prevState,
        errorsMessage: {
          ...prevState.errorsMessage,
          errorStoreUpload: "You can't Update Same Image",
          storeUploadError: true,
        },
      }));
      return false;
    } else if (selectedFile.size > maxFileSize) {
      this.setState((prevState) => ({
        ...prevState,
        errorsMessage: {
          ...prevState.errorsMessage,
          errorStoreUpload: `File size should be less than 5 MB`,
          storeUploadError: true,
        },
      }));
      return false;
    } else if (!validFileTypes.includes(selectedFile.type)) {
      this.setState((prevState) => ({
        ...prevState,
        errorsMessage: {
          ...prevState.errorsMessage,
          errorStoreUpload: "Only .png, .jpg, and .jpeg files are allowed",
          storeUploadError: true,
        },
      }));
      return false;
    }

    return true;
  };

  handleSubmit = (submitEvent: React.FormEvent) => {
    submitEvent.preventDefault();

    if (!this.validateStoreUpload()) {
      return;
    }

    this.updateStoreImagApi();
  };

  updateStoreImagApi = async () => {
    const { storeProfile, storeIdResp } = this.state;
    const formData = new FormData();
    if (storeProfile.selectedFile) {
      formData.append("business[image]", storeProfile.selectedFile);
    }
    this.storeUpdateImgAPICallId = await apiCall({
      method: configJSON.httpPatchMethod,
      endPoint: `${configJSON.createStoreEndPoint}/${storeIdResp?.id}`,
      token: await this.getTokenData(),
      isJsonStringify: false,
      body: formData,
    });
  };

  handleEditClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      isImageDialog: !prevState.isImageDialog,
      errorsMessage: {
        ...prevState.errorsMessage,
        errorStoreUpload: "",
        storeUploadError: false,
      },
    }));
  };

  capitalizeFirstLetter = (day: string) =>
    day.charAt(0).toUpperCase() + day.slice(1);

  valueTime = (timeValue: string | null) => {
    return timeValue ? timeValue : null;
  };

  parseValidFormate = (timeString: string | null) => {
    return dayjs(timeString).format("HH:MM");
  };

  handleTimeSubmit = async (values: Step3State) => {
    const storeId = await this.getStoreId();
    const formData = new FormData();

    formData.append(
      "business[store_operating_hours][monday][open",
      this.parseValidFormate(values.monFrom)
    );
    formData.append(
      "business[store_operating_hours][monday][close]",
      this.parseValidFormate(values.monTo)
    );
    formData.append(
      "business[store_operating_hours][tuesday][close]",
      this.parseValidFormate(values.tueTo)
    );
    formData.append(
      "business[store_operating_hours][tuesday][open]",
      this.parseValidFormate(values.tueFrom)
    );
    formData.append(
      "business[store_operating_hours][wednesday][open]",
      this.parseValidFormate(values.wedFrom)
    );
    formData.append(
      "business[store_operating_hours][wednesday][close]",
      this.parseValidFormate(values.wedTo)
    );
    formData.append(
      "business[store_operating_hours][thursday][open]",
      this.parseValidFormate(values.thuFrom)
    );
    formData.append(
      "business[store_operating_hours][thursday][close]",
      this.parseValidFormate(values.thuTo)
    );
    formData.append(
      "business[store_operating_hours][friday][open]",
      this.parseValidFormate(values.friFrom)
    );
    formData.append(
      "business[store_operating_hours][friday][close]",
      this.parseValidFormate(values.friTo)
    );
    formData.append(
      "business[store_operating_hours][saturday][open]",
      this.parseValidFormate(values.satFrom)
    );
    formData.append(
      "business[store_operating_hours][saturday][close]",
      this.parseValidFormate(values.satTo)
    );
    formData.append(
      "business[store_operating_hours][sunday][open]",
      this.parseValidFormate(values.sunFrom)
    );
    formData.append(
      "business[store_operating_hours][sunday][close]",
      this.parseValidFormate(values.sunTo)
    );
    formData.append("business[average_shipping_time]", values.averageTime);

    this.storeUpdateTimingsAPICallId = await apiCall({
      method: configJSON.httpPatchMethod,
      endPoint: `${configJSON.storeUpdateTimingEndPoint}/${storeId}/manage_timings`,
      token: await this.getTokenData(),
      isJsonStringify: false,
      body: formData,
    });
  };

  convertToISOTime = (timeString: string) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    return date.toISOString();
  };

  getErrorAndHelperText = (
    field: string,
    errors: FormError,
    touched: FormErrorTouched
  ) => {
    const isError: boolean = Boolean(errors[field]) && Boolean(touched[field]);
    const helperText: string = isError ? errors[field] ?? "" : "";
    return { isError, helperText };
  };

  handleTimeClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      isTimeDialogOpen: !prevState.isTimeDialogOpen,
    }));
  };

  oncloseAlert = () => {
    this.setState((prevState) => ({
      ...prevState,
      alertState: {
        ...prevState.alertState,
        isAlert: !prevState?.alertState.isAlert,
      },
    }));
  };
  // Customizable Area End
}
// Customizable Area Start
export const btnData = [
  {
    label: "Catalogues",
    path: "SellerCatalogues",
  },
  {
    label: "Analytics & Insights",
    path: "SellerAnalyticsInsights",
  },
  {
    label: "Revenue",
    path: "SellerRevenue",
  },
  {
    label: "Inventory Management",
    path: "InventoryManagement",
  },
  {
    label: "Offers & Discounts",
    path: "SellerOffersDiscounts",
  },
];
// Customizable Area End
