import { IBlock } from "../../../../../../framework/src/IBlock";
import { Message } from "../../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../../../../framework/src/Messages/MessageEnum";
import { apiCall } from "../../../../../../components/src/APICall";
import { getStorageData } from "framework/src/Utilities";
import {
  IInventoryPaginationResponse,
  InventoryState,
} from "../UpdateInventory/UpdateInventoryController";
import { ErrorMessage } from "../../../CreateSellerStoreController";
import { ErrorMessageResponse } from "../../../SellerStoreController";
import { MetaResponse } from "../../Catalogues/SellerCataloguesController";
import { logoutSellerNavigation } from "../../../../../../components/src/Seller/logOut";
import { IAssignFilterState } from "../SellerInventoryManagementController";
export interface ExtractedInventoryItem {
  id: number;
  product_name: string;
  front_image: string;
  sku: string;
  isSelected: boolean;
}

// Customizable Area End

export const configJSON = require("../../../config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  handleAssignStore: (variantsIds: number[]) => void;
  searchValue: string;
  removeData: boolean;
  handleRedirect: (redirect: string) => void;
  filterValue: IAssignFilterState;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  tableHeadName: string[];
  selectedProductIds: number[];
  isAllSelectedProduct: boolean;
  responseInventoryData: InventoryState[];
  responseBodyData: ExtractedInventoryItem[];
  tableBodyData: ExtractedInventoryItem[];
  page: number;
  assignInventoryMeta: MetaResponse;
  warningOpen: boolean;
  newPage: number;
  isAlert: boolean;
  message: string;
  isLoading: boolean;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class InventoryAssignStoreController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getListInventoryAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      tableHeadName: inventoryAssignStoreTableHead,
      responseInventoryData: [],
      responseBodyData: [],
      selectedProductIds: [],
      tableBodyData: [],
      isAllSelectedProduct: false,
      page: 1,
      assignInventoryMeta: {} as MetaResponse,
      warningOpen: false,
      newPage: 1,
      isAlert: false,
      message: "",
      isLoading: true,
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseData && !responseData.errors && !responseData.error) {
        this.apiAssignInventorySuccess(apiRequestCallId, responseData);
      } else if (responseData && (responseData.error || responseData.errors)) {
        this.apiAssignInventoryFailer(responseData);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    const userData = await getStorageData("userRole", true);
    if(userData.userType==="seller") this.fetchDataBySearchValue("", this.props.filterValue, 1);
    else history.back();
    // Customizable Area End
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.searchValue !== this.props.searchValue || prevProps.filterValue !== this.props.filterValue) {
      this.state.selectedProductIds.length === 0
        ? this.fetchDataBySearchValue(this.props.searchValue, this.props.filterValue, 1)
        : this.setState((prevState) => ({
            ...prevState,
            warningOpen: !prevState.warningOpen,
          }));
    }
    if (prevProps.removeData !== this.props.removeData) {
      this.setState((prevState) => ({
        ...prevState,
        selectedProductIds: [],
        tableBodyData: prevState.responseBodyData,
      }));
    }
  }

  apiAssignInventorySuccess = async (
    apiRequestCallId: string,
    responseJson: IInventoryPaginationResponse
  ) => {
    if (apiRequestCallId === this.getListInventoryAPICallId) {
      if (responseJson) this.handleAssignInventoryResp(responseJson);
    }
  };

  onAlertSnackClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      isAlert: !prevState.isAlert,
    }));
  };

  handleAssignInventoryResp = (response: IInventoryPaginationResponse) => {
    this.setState((prevState) => ({
      ...prevState,
      responseInventoryData: response.data,
      responseBodyData: this.extractAssignInventoryData(response.data),
      tableBodyData: this.extractAssignInventoryData(response.data),
      assignInventoryMeta: response.meta,
      isLoading: false,
    }));
  };

  handleAssignInventoryPageChange = async (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    event.preventDefault();
    if (this.state.selectedProductIds.length !== 0) {
      this.setState((prevState) => ({
        ...prevState,
        newPage: newPage,
        warningOpen: !prevState.warningOpen,
      }));
      return;
    }
    this.setState((prevState) => ({
      ...prevState,
      page: newPage,
    }));
    await this.fetchDataBySearchValue(this.props.searchValue, this.props.filterValue, newPage);
  };

  apiAssignInventoryFailer = (
    responseJson: ErrorMessage & ErrorMessageResponse
  ) => {
    if(responseJson.error === "You are not an authorized person, Only seller and Stylist can filter variants." || responseJson.error === "Only seller  and Stylist can search catalogues." || responseJson.errors === "Only seller  and Stylist can search catalogues.")
      {
        this.handleRedirectToHome("You are not an authorized person, Please Login with valid User Credential");
      } else if (responseJson.error) {
      this.setState((prevState) => ({
        ...prevState,
        responseBodyData: [],
        tableBodyData: [],
        assignInventoryMeta: {} as MetaResponse,
        isLoading: false,
      }));
    } else if (responseJson.errors[0].token) {
      this.handleRedirectToHome(responseJson.errors[0].token);
    }
  };

  handleRedirectToHome = (message: string) => {
    this.setState((prevState) => ({
      ...prevState,
      isAlert: true,
      message: message,
      isLoading: false,
    }), () => {
      setTimeout(() => {
        this.props.handleRedirect("Home");
        logoutSellerNavigation();
      }, 2000);
    });
  }

  handleProductCheckboxChange = (id: number, isSelected: boolean) => {
    this.setState(
      (prevState) => {
        const updatedTableBodyData = prevState.tableBodyData.map((product) =>
          product.id === id ? { ...product, isSelected } : product
        );

        const updatedSelectedProductIds = isSelected
          ? [...prevState.selectedProductIds, id]
          : prevState.selectedProductIds.filter(
              (productId) => productId !== id
            );

        return {
          tableBodyData: updatedTableBodyData,
          selectedProductIds: updatedSelectedProductIds,
          isAllSelectedProduct:
            updatedSelectedProductIds.length ===
              prevState.tableBodyData.length &&
            updatedSelectedProductIds.length !== 0,
        };
      },
      () => {
        this.handleAssignStoreCalled(this.state.selectedProductIds);
      }
    );
  };

  handleAllProductCheckboxChange = () => {
    this.setState(
      (prevState) => {
        const isAllSelected = !prevState.isAllSelectedProduct;
        const selectedProductIds = isAllSelected ? this.getAllProductIds() : [];

        const updatedTableBodyData = prevState.tableBodyData.map((item) => ({
          ...item,
          isSelected: isAllSelected,
        }));
        return {
          ...prevState,
          isAllSelectedProduct: isAllSelected,
          selectedProductIds: selectedProductIds,
          tableBodyData: updatedTableBodyData,
        };
      },
      () => {
        this.handleAssignStoreCalled(this.state.selectedProductIds);
      }
    );
  };

  handleAssignStoreCalled = (variantIds: number[]) => {
    this.props.handleAssignStore(variantIds);
  };

  handleCheckedValue = (isChecked: boolean) => {
    return isChecked;
  };

  fetchDataBySearchValue = async (search: string, filter: IAssignFilterState, page: number) => {
    this.setState((prevState) => ({ ...prevState, page, isLoading: true }));
  
    const storeId = +(await this.getStoreId());
    let endPoint = `${configJSON.getFilterVariantInventoryEndPoint}`;
  
    const queryParams = new URLSearchParams({
      search,
      store_id: storeId.toString(),
      per_page: "10",
      page: page.toString(),
    });
  
    if (filter.value.min && filter.value.min !== "") {
      queryParams.append("min_price", filter.value.min);
    }
    if (filter.value.max && filter.value.max !== "") {
      queryParams.append("max_price", filter.value.max);
    }
    if (filter.filterSizes.length !== 0) {
      queryParams.append("variant_size_ids", `[${filter.filterSizes.join(",")}]`);
    }
    if (filter.filterCategory.length !== 0) {
      queryParams.append("category_ids", `[${filter.filterCategory.join(",")}]`);
    }
    if (filter.filterSubCategory.length !== 0) {
      queryParams.append("sub_category_ids", `[${filter.filterSubCategory.join(",")}]`);
    }
  
    endPoint += queryParams.toString();
  
    this.getListInventoryAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint,
      token: await this.getTokenDataStorage(),
    });
  };

  getTokenDataStorage = async () => {
    return await getStorageData("auth-token");
  };

  getStoreId = async () => {
    return await getStorageData("store-id");
  };

  getAllProductIds = () => {
    return this.state.tableBodyData.map((product) => product.id);
  };

  handleYes = async () => {
    this.setState((prevState) => ({
      ...prevState,
      page: prevState.newPage,
      warningOpen: !prevState.warningOpen,
      selectedProductIds: [],
      isAllSelectedProduct: false
    }));
    this.handleAssignStoreCalled([]);
    await this.fetchDataBySearchValue(
      this.props.searchValue,
      this.props.filterValue,
      this.state.newPage
    );
  };

  handleNo = () => {
    this.setState((prevState) => ({
      ...prevState,
      warningOpen: !prevState.warningOpen,
    }));
  };

  extractAssignInventoryData = (
    items: InventoryState[]
  ): ExtractedInventoryItem[] => {
    return items.map((item) => {
      const { id, product_name, is_listed, sku, front_image } = item.attributes;
      return {
        id,
        product_name,
        is_listed,
        front_image,
        sku,
        isSelected: false,
      };
    });
  };
  // Customizable Area End
}
// Customizable Area Start
export const inventoryAssignStoreTableHead = [
  "Product Name",
  "SKU",
  "Select Products",
];
// Customizable Area End
