import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";

import {
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import InventorySetPricesController, {
  Props,
} from "./InventorySetPricesController";
import {
  CustomStyledTableUpdateInventory,
  TableHeaderCell,
  PaginationContainer,
  CustomStyledPagination,
  EditTextField,
} from "../UpdateInventory/UpdateInventory.web";
import {
  BtnContainer,
  NoRecordTypoContainer,
  WarningDialogContainer,
} from "../AssignStore/InventoryAssignStore.web";
import Loader from "../../../../../../components/src/LoaderContainer";
import ReusableSnackbar from "../../../../../../components/src/AlertContainer";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class InventorySetPrices extends InventorySetPricesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderSetPricePagination = () => (
    <>
      {this.state.setPriceInventoryMeta !== undefined ? (
        <PaginationContainer>
          <Box className="pagination-data-container">
            {`${
              (this.state.setPriceInventoryMeta?.current_page - 1) * 10 + 1
            } - 
              ${Math.min(
                this.state.setPriceInventoryMeta?.current_page * 10,
                this.state.setPriceInventoryMeta?.total_record
              )}
            of ${this.state.setPriceInventoryMeta.total_record} results`}
          </Box>
          <CustomStyledPagination
            page={this.state.page}
            count={this.state.setPriceInventoryMeta.total_pages}
            shape="rounded"
            color="primary"
            onChange={this.handleUpdatePriceInventoryPageChange}
            data-test-id="sp-pagination-test-id"
          />
        </PaginationContainer>
      ) : (
        <></>
      )}
    </>
  );

  renderSetPriceBody = () => (
    <>
      {this.state.tableBodyData.length === 0 ? (
        <TableRow>
          <TableCell className="ui-no-data" colSpan={5}>
            <NoRecordTypoContainer>No Records Found</NoRecordTypoContainer>
          </TableCell>
        </TableRow>
      ) : (
        <>
          {this.state.tableBodyData?.map((setPriceInventoryData) => (
            <TableRow key={setPriceInventoryData.id} className="hover">
              <TableCell data-test-id="product-name">
                <Box className="product-name-container">
                  <img
                    src={setPriceInventoryData.front_image}
                    className="img-container"
                    alt={setPriceInventoryData.product_name}
                  />
                  <Typography className="product-title">
                    {setPriceInventoryData.product_name}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Typography className="product-sku">
                  {setPriceInventoryData.sku}
                </Typography>
              </TableCell>
              <TableCell>
                <Box
                  className={`current-stock-container ${
                    +setPriceInventoryData.price === 0
                      ? "red-container"
                      : "green-container"
                  }`}
                >
                  <EditTextField
                    value={setPriceInventoryData.price}
                    onChange={(e) =>
                      this.handlePriceChange(
                        setPriceInventoryData.id,
                        e.target.value
                      )
                    }
                    data-test-id={`sp-price-${setPriceInventoryData.id}`}
                    onBlur={()=>this.handleInputBlur(setPriceInventoryData.id)}
                    inputProps={{ maxLength: 9 }}
                  />
                </Box>
              </TableCell>
              <TableCell>
                <Box className="current-stock-container green-container">
                  <EditTextField
                    value={setPriceInventoryData.discounted_percentage}
                    onChange={(e) =>
                      this.handleDiscountedPercentageChange(
                        setPriceInventoryData.id,
                        e.target.value
                      )
                    }
                    data-test-id={`sp-discounted-per-${setPriceInventoryData.id}`}
                    inputProps={{ maxLength: 2 }}
                  />
                </Box>
              </TableCell>
              <TableCell>
                <Box className="current-stock-container green-container">
                  <EditTextField
                    value={setPriceInventoryData.discounted_price}
                    onChange={(e) =>
                      this.handleDiscountedPriceChange(
                        setPriceInventoryData.id,
                        e.target.value
                      )
                    }
                    data-test-id={`sp-discounted-price-${setPriceInventoryData.id}`}
                    inputProps={{ maxLength: 6 }}
                    disabled
                  />
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </>
      )}
    </>
  );

  renderWaringContainer = () => (
    <WarningDialogContainer
      data-test-id="sp-warning-dialog"
      open={this.state.warningOpen}
    >
      <Box id="content-container" className="content-container">
        <Typography id="unsaved" className="text-container">
          You have unsaved changes.
        </Typography>
        <Typography id="leave" className="text-container">
          Are you sure you want to leave this page?
        </Typography>
        <Typography className="text-container">
          If <span className="success-color">No</span> Please click on Confirm
          Button Otherwise <span className="error-color">Cancel</span>
        </Typography>
      </Box>
      <BtnContainer className="btn-container">
        <Button
          variant="outlined"
          className={"no-btn"}
          onClick={() => this.handleCancel()}
          disableElevation
          data-test-id="sp-warning-cancel-test-id"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disableElevation
          className={"yes-btn"}
          data-test-id="sp-warning-confirm-test-id"
          onClick={() => this.handleConfirm()}
        >
          Confirm
        </Button>
      </BtnContainer>
    </WarningDialogContainer>
  );
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <>
        <CustomStyledTableUpdateInventory>
          <Table stickyHeader size="small" className="custom-table">
            <TableHead>
              <TableRow
                style={{ backgroundColor: "#f0f0f0" }}
                className="sp-table-head-row"
              >
                {this.state.tableHeadName.map(
                  (header: string, index: number) => (
                    <TableHeaderCell key={index} className="sp-table-head-cell">
                      <Typography className="table-head-title">
                        {header}
                      </Typography>
                    </TableHeaderCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.isLoading ? (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Loader
                      id="step-2-loader"
                      dataTestId="step-2-loader-test"
                    />
                  </TableCell>
                </TableRow>
              ) : (
                this.renderSetPriceBody()
              )}
            </TableBody>
          </Table>
        </CustomStyledTableUpdateInventory>
        {this.state.setPriceInventoryMeta.total_record > 10 &&
          this.renderSetPricePagination()}
        <ReusableSnackbar
          open={this.state.isAlert}
          message={this.state.message}
          onClose={this.onAlertSPSnackClose}
          severity={this.state.severity}
          dataTestId="alertTestId"
          autoHideDuration={3000}
        />
        {this.renderWaringContainer()}
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

// Customizable Area End
