Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Analytics";
exports.labelBodyText = "Analytics Body";

exports.btnExampleTitle = "CLICK ME";
exports.sellerEarnings = "accounts/seller_earnings?type"
exports.filterDate = "accounts/seller_earning_graph?filter"
exports.startEndDate = "accounts/seller_earning_graph"
exports.earningsDownload = "accounts/export_all_data?format"
exports.getTotalRevenueEndPoint = "/account_block/accounts/bussiness/get_total_revenue";
exports.getSalesReportProductEndPoint = "/account_block/accounts/bussiness/get_sales_revenue_report";
exports.getSalesReportProdEndPoint = "/accounts/bussiness/get_sales_revenue_report_for_product/";
exports.getVolumeStoreEndPoint = "/account_block/accounts/bussiness/get_sales_volume_report_for_store";
exports.getVolumeStoreProdEndPoint = "/accounts/bussiness/get_sales_volume_report_for_product/";
exports.getGrowthStoreEndPoint = "/account_block/accounts/bussiness/get_sales_growth_report";
exports.getCatalogueProductsEndPoint = "/bx_block_catalogue/catalogues";
exports.getStoreEndPoint = "/accounts/bussiness/seller_store";
exports.monthLabel = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
exports.weekLabel = [
  "Mon",
  "Tue",
  "Wed",
  "Thr",
  "Fri",
  "Sat",
  "Sun"
]
// Customizable Area End