import moment from "moment";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start

export interface ReturnSeller  {
  type: string;
  id: string;
  attributes: { 
    account: string;
    order_number: string; 
    sub_total: string;
    order_item_count: number;
    status: string;
    total: string;
    confirmed_at: string; 
    in_transit_at: string; 
    placed_at: string;
    process_at: string;
    delivered_at: string;
    shipped_at: string;
    return_cancel_at: string;
    return_at: string;
    cancelled_at: string; 
    refunded_at: string; 
    return_pick_at: string;
    deliver_by: string; 
    order_status_id: number; 
    returned_at: string;
    updated_at: string;
    order_deliver_time: string;
    created_at: string;
    order_deliver_date: string;
    delivery_addresses: {
      type: string;
      id: string;
      attributes: {
        country_code: string;
        name: string;
        contact_number: string; 
        phone_number: string;
        zip_code: string; 
        street: string;
        block: string; 
        area: string;
        house_or_building_number: string;
        city: string;
        is_default: boolean;
        address_name: string;
        longitude: number;
        latitude: number;
      }; 
    }; 
    order_return_time: string;
    order_return_date: string;
    payment_detail: unknown | null;
    order_items: ReturnItem[];
  } 
}
interface ReturnItem {
  id: string;
  type: string;
  attributes: {
    status: string;
    placed_at: string;
    confirmed_at: string;
    in_transit_at: string;
    delivered_at: string;
    cancelled_at: string | null;
    rejected_at: string | null;
    process_at: string;
    shipped_at: string | null;
    return_at: string;
    return_cancel_at: string;
    return_pick_at: string;
    return_placed_at: string;
    return_confirmed_at: string;
    return_reject_at: string;
    returned_assign_at: string;
    quantity: number;
    unit_price: string;
    total_price: string;
    reason_of_rejection: string | null;
    reason_of_return: string;
    refunded_cancel_at: string | null;
    reason_refunded_cancel: string | null;
    refunded_at: string | null;
    return_type: string | null;
    status_humanize: string;
    catalogue_name: string;
    brand_name: string;
    catalogue_variant_color: string;
    catalogue_variant_sku: string;
    catalogue_variant_size: string;
    catalogue_variant_front_image: string;
    catalogue_variant_back_image: string;
    catalogue_variant_side_image: string;
    driver_name: string | null;
    driver_latitude: string | null;
    driver_longitude: string | null;
    driver_phone_number: string | null;
    otp: string | null;
    store_name: string;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeStepRetun:number,
  returnDetailsList: ReturnSeller | {},
  profileName: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ReturnStatusController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getReturnStatusApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeStepRetun:0,
      returnDetailsList:{},
      profileName: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getReturnStatusApiCallId) {
      this.setState({ returnDetailsList: responseJson.data });
      switch (responseJson.data.attributes.order_items[0].attributes.status) {
        case "return_placed": 
          this.setState({ activeStepRetun: 0 });
          break;
        case "return_confirmed":
          this.setState({ activeStepRetun: 1 });
          break;
        case "out_for_delivery":
          this.setState({ activeStepRetun: 2 });
          break;
        case "return_order_shipped":
          this.setState({ activeStepRetun: 3 });
          break;
        case "returned":
          this.setState({ activeStepRetun: 4 });
          break;
        default:
          break;
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getReturnStatusApi()
    const profileHolder = await getStorageData("user_data")
    this.setState({ profileName: JSON.parse(profileHolder).first_name + " " + JSON.parse(profileHolder).last_name })
  }

  getReturnFormatDate = (dates: string) => {
    const formatDate = moment(dates).format("DD MMMM YYYY")
    return formatDate
  }

  getReturnFormatMonth = (dates: string) => {
    const formatDate = moment(dates).format("DD MMMM")
    return formatDate
  }

  getReturnFormatMonthDayTime = (dates: string) => {
    const formatDate = moment(dates).format("D MMMM YYYY h:mm A")
    return formatDate
  }

  proceedToReturnPage = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "OrderManagementPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  navigateToReturn = ({id,props,screenName}: {id: string | undefined,props: unknown,screenName: string}) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    id && message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    runEngine.sendMessage(message.id, message);
  }

  proceedToReturnHomePage = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  getReturnStatusApi = async () => {
    const getToken = await getStorageData("auth-token")
    const idData = this.props.navigation.getParam("navigationBarTitleText");
    
    const headers = {
      redirect: "follow",
      "token": getToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getReturnStatusApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_order_management/orders/${idData}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
