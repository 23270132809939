import { IBlock } from "../../../../../../framework/src/IBlock";
import { Message } from "../../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../../../../framework/src/Messages/MessageEnum";
import { ChangeEvent } from "react";
import { logoutSellerNavigation } from "../../../../../../components/src/Seller/logOut";
import { apiCall } from "../../../../../../components/src/APICall";
import { getStorageData } from "framework/src/Utilities";

export interface BulkActionErrorResp {
  errors: [
    {
      token?: string;
      message?: string;
    }
  ];
}

export interface BulkActionSuccessResp {
  success: [
    {
      message?: string;
    }
  ];
}
// Customizable Area End

export const configJSON = require("../../../config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  handleRedirect: (redirect: string) => void;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  btnData: { id: number; name: string; popUp: string }[];
  selectedBtn: string;
  popUpContain: string;
  isUploadOpen: boolean;
  uploadCSVFile: File | null;
  previewUpload: string;
  isError: boolean;
  errorMessage: string;
  uploadProgress: number;
  isSuccessOpen: boolean;
  successMessage: string;
  apiSuccessMessage: string;
  isAlert: boolean;
  alertMessage: string;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BulkActionController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  addProductAPICallId: string = "";
  editProductAPICallId: string = "";
  assignStoreAPICallId: string = "";
  updateInventoryAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      btnData: updateData,
      selectedBtn: "",
      popUpContain: "",
      isUploadOpen: false,
      uploadCSVFile: null,
      previewUpload: "",
      isError: false,
      errorMessage: "",
      uploadProgress: 0,
      isSuccessOpen: false,
      successMessage: "",
      apiSuccessMessage: "",
      isAlert: false,
      alertMessage: "",
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.success && !responseJson.message) {
        this.apiBulkUploadSuccessCalled(apiRequestCallId, responseJson);
      } else if (responseJson && (responseJson.errors || responseJson.message)) {
        this.apiBulkUploadFailureCalled(responseJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    const userData = await getStorageData("userRole", true);
    if(userData.userType==="buyer") history.back();
    // Customizable Area End
  }

  apiBulkUploadSuccessCalled = async (
    apiRequestCallId: string,
    responseJson: BulkActionSuccessResp
  ) => {
    switch (apiRequestCallId) {
      case this.addProductAPICallId:
        this.setState((prevState) => ({
          ...prevState,
          isSuccessOpen: true,
          apiSuccessMessage: responseJson.success[0].message as string,
          successMessage: "Products added successfully",
        }));
        break;
      case this.editProductAPICallId:
        this.setState((prevState) => ({
          ...prevState,
          successMessage: "Products edited successfully",
          isSuccessOpen: true,
          apiSuccessMessage: responseJson.success[0].message as string,
        }));
        break;
      case this.assignStoreAPICallId:
        this.setState((prevState) => ({
          ...prevState,
          apiSuccessMessage: responseJson.success[0].message as string,
          isSuccessOpen: true,
          successMessage: "Store Assigned successfully",
        }));
        break;
      case this.updateInventoryAPICallId:
        this.setState((prevState) => ({
          ...prevState,
          isSuccessOpen: true,
          apiSuccessMessage: responseJson.success[0].message as string,
          successMessage: "Inventory is successfully updated",
        }));
        break;
    }
    setTimeout(() => {
      this.handleUploadClose();
      this.handleSuccessClose();
    }, 2000);
  };

  
  apiBulkUploadFailureCalled = (responseJson: BulkActionErrorResp & { message: string}) => {
    if(responseJson.message === "You are not authorized!, Only seller & stylist user can update catalogues." || responseJson.message === "You are not authorized!, Only seller user can create catalogues." ){
      this.handleRedirectToHome("You are not an authorized person, Please Login with valid User Credential");
    }
    else if (responseJson.errors[0].token) {
      this.handleRedirectToHome(responseJson.errors[0].token);
    } else if (responseJson.errors[0].message) {
      this.setState((prevState) => ({
        ...prevState,
        isError: true,
        errorMessage: responseJson.errors[0].message as string,
      }));
    }
  };

  handleRedirectToHome = (message: string) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        isUploadOpen: !prevState.isUploadOpen,
        isAlert: !prevState.isAlert,
        alertMessage: message,
      }),
      () => {
        setTimeout(() => {
          this.props.handleRedirect("Home");
          logoutSellerNavigation();
        }, 2000);
      }
    );
  }

  onTokenAlertClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      isAlert: !prevState.isAlert,
    }));
  };

  handleSuccessClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      isSuccessOpen: false,
    }));
  };

  handleButtonClick = (name: string, popUp: string) => {
    this.setState((prevState) => ({
      ...prevState,
      selectedBtn: name,
      popUpContain: popUp,
      isUploadOpen: !prevState.isUploadOpen,
    }));
  };

  handleUploadClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      isUploadOpen: !prevState.isUploadOpen,
      uploadCSVFile: null,
      previewUpload: "",
      isError: false,
      errorMessage: "",
      uploadProgress: 0,
    }));
  };

  uploadFile = () => {
    const uploadSimulation = setInterval(() => {
      this.setState((prevState) => {
        const newProgress = Math.min(prevState.uploadProgress + 10, 100);
        if (newProgress === 100) {
          clearInterval(uploadSimulation);
          return {
            ...prevState,
            isError: false,
            errorMessage: "",
            uploadProgress: newProgress,
          };
        }
        return { ...prevState, uploadProgress: newProgress };
      });
    }, 500);
  };

  handleUploadChange = (event: ChangeEvent<HTMLInputElement>, btn: string) => {
    const file = event.target.files?.[0];

    if (file) {
      const isCSV = file.type === "text/csv";
      const isSizeValid = file.size <= 5 * 1024 * 1024;

      if (!isCSV) {
        this.setState((prevState) => ({
          ...prevState,
          isError: true,
          errorMessage: "Please upload a CSV file.",
        }));
        return;
      } else if (!isSizeValid) {
        this.setState((prevState) => ({
          ...prevState,
          isError: true,
          errorMessage: "CSV File size should be less than 5 MB.",
        }));
        return;
      } else {
        this.setState((prevState) => ({
          ...prevState,
          uploadCSVFile: file,
          previewUpload: URL.createObjectURL(file),
          isError: false,
          errorMessage: "",
          uploadProgress: btn === "reUpload" ? 0 : prevState.uploadProgress,
        }));
        this.uploadFile();
      }
    }
  };

  handleUploadSubmit = () => {
    const { uploadCSVFile, selectedBtn, uploadProgress, isError } = this.state;
    if (uploadCSVFile === null) {
      this.setState((prevState) => ({
        ...prevState,
        isError: true,
        errorMessage: "CSV File is Required. Please upload a CSV file.",
      }));
      return;
    }

    if (isError) {
      return;
    }

    if (uploadProgress !== 100) {
      this.setState((prevState) => ({
        ...prevState,
        isError: true,
        errorMessage: "Please hold on CSV file is uploading....",
      }));
      return;
    }

    switch (selectedBtn) {
      case "Update Inventory":
        this.updateInventoryAPICalled();
        break;
      case "Assign Store":
        this.assignStoreAPICalled();
        break;
      case "Add Products":
        this.addProductAPICalled();
        break;
      case "Edit Products":
        this.editProductAPICalled();
        break;
    }
  };

  updateInventoryAPICalled = async () => {
    const formData = new FormData();
    if (this.state.uploadCSVFile) {
      formData.append("catalogue_file", this.state.uploadCSVFile);
    }

    this.updateInventoryAPICallId = await apiCall({
      method: configJSON.httpPostMethod,
      endPoint: configJSON.bulkUpdateInventoryEndPoint,
      token: await this.getLocalToken(),
      body: formData,
      isJsonStringify: false,
    });
  };

  assignStoreAPICalled = async () => {
    const formData = new FormData();
    if (this.state.uploadCSVFile) {
      formData.append("csv_file", this.state.uploadCSVFile);
    }

    this.assignStoreAPICallId = await apiCall({
      method: configJSON.httpPostMethod,
      endPoint: configJSON.bulkAssignStoreEndPoint,
      token: await this.getLocalToken(),
      body: formData,
      isJsonStringify: false,
    });
  };

  addProductAPICalled = async () => {
    const formData = new FormData();
    if (this.state.uploadCSVFile) {
      formData.append("catalogue_file", this.state.uploadCSVFile);
    }

    this.addProductAPICallId = await apiCall({
      method: configJSON.httpPostMethod,
      endPoint: configJSON.bulkAddProductEndPoint,
      token: await this.getLocalToken(),
      body: formData,
      isJsonStringify: false,
    });
  };

  editProductAPICalled = async () => {
    const formData = new FormData();
    if (this.state.uploadCSVFile) {
      formData.append("catalogue_file", this.state.uploadCSVFile);
    }

    this.editProductAPICallId = await apiCall({
      method: configJSON.httpPatchMethod,
      endPoint: configJSON.bulkEditProductEndPoint,
      token: await this.getLocalToken(),
      body: formData,
      isJsonStringify: false,
    });
  };

  getLocalToken = async () => {
    return await getStorageData("auth-token");
  };
  // Customizable Area End
}
// Customizable Area Start
const updateData = [
  {
    id: 1,
    name: "Update Inventory",
    popUp: "Products",
  },
  { id: 2, name: "Assign Store", popUp: "Stores" },
  { id: 3, name: "Add Products", popUp: "Products" },
  { id: 4, name: "Edit Products", popUp: "Products" },
  { id: 5, name: "Delete Products", popUp: "Products" },
];
// Customizable Area End
