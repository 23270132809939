import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    Grid,
    FormControl,
    withStyles,
    Tabs,
    Tab,
    InputBase,
    IconButton,
    WithStyles,
    FormControlLabel,
    Button,
    Divider,
    Snackbar
} from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import clsx from 'clsx';
import { Search, calendarpng, Noimageavailablepng, scrollImg, modeofimage } from "./assets";
import { styled } from "@material-ui/core/styles";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Header from "../../../components/src/Header"
import Footer from "../../../components/src/Footer"
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import Sidebar from "../../../components/src/Sidebar";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
// Customizable Area End

import OrderManagementPageWebController, {
    Props,
} from "./OrderManagementPageWebController";

export default class CategoriessubcategoriesWebPage extends OrderManagementPageWebController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderCharge = () => {
        const { modeofreturn, deliveryCharges } = this.state;
        if (modeofreturn === "request_delivery_partner") {
            const charge = deliveryCharges && !isNaN(parseFloat(deliveryCharges)) ? `$${parseFloat(deliveryCharges).toFixed(2)}` : '$0.00';
            return <Typography style={webStyle.totelCharge}>{charge}</Typography>;
        } else {
            return <Typography style={webStyle.pickupCharge}>$0.00</Typography>;
        }
    }
    renderModeOfReturnButton = (orderId: string, orderStatus: string) => {
        return orderStatus === "confirmed" ? this.renderReturnStatusButton(orderId) : <StyledButton1
            style={{ fontSize: "18px" }}
            data-testid="navigateToModeofReturn"
            onClick={() => this.modeRetuneHandleOpen(orderId)}
        >
            Mode of Return
        </StyledButton1>;
    }

    renderReturnStatusButton = (orderId: string) => {
        return (
            <StyledButton1 style={{ fontSize: "18px" }} data-testid="navigateToDeliverdreturned" onClick={() => this.navigateTo({ id: orderId, props: this.props, screenName: "ReturnStatus" })}>Return Status</StyledButton1>
        );
    }

    renderOrderButtons = (orderId: string, orderStatus: string, returnOrderStatus: string) => {
        const isReturnOrder = ["return_order", "returned", "placed"].includes(orderStatus);
        const isReturnConfirmed = returnOrderStatus === 'return_confirmed';
        if (isReturnOrder) { return isReturnConfirmed ? this.renderModeOfReturnButton(orderId, orderStatus) : this.renderReturnStatusButton(orderId); }
        return this.renderModeOfReturnButton(orderId, orderStatus);
    }

    returnTates = (oderId: string, returnOrderStatus: string) => {
        return returnOrderStatus === 'return_confirmed' ? <StyledButton style={{ fontSize: "18px", marginRight: '12px' }} data-testid="cancelOrderOpenReturnOrder" onClick={() => this.retunOrder({ productId: oderId, props: this.props, screenName: "BuyerReturnOrderDetailsWebPage" })}>Return Order</StyledButton> : null
    }
    styledButton = (label: string, oderId: string, orderStatus: string, returnOrderStatus: string, returnModeStatus: string) => {
        switch (label) {
            case 'Processing':
                return <>
                    <Box>
                        <StyledButton style={{ fontSize: "18px", marginRight: '12px' }} data-testid="cancelOrderOpen" onClick={() => this.handleOpen1(label, oderId)}>Canel Order</StyledButton>
                        <StyledButton1 style={{ fontSize: "18px" }} data-testid="navigateTo" onClick={() => this.navigateTo({ id: oderId, props: this.props, screenName: "OrderDetails" })}>Order Status</StyledButton1>
                    </Box>
                </>
            case 'Deliverd':
                return <>
                    <Box>
                        <StyledButton style={{ fontSize: "18px", marginRight: '12px' }} data-testid="cancelOrderOpenReturnOrder" onClick={() => this.retunOrder({ productId: oderId, props: this.props, screenName: "BuyerReturnOrderDetailsWebPage" })}>Return Order</StyledButton>
                        <StyledButton1 style={{ fontSize: "18px" }} data-testid="navigateToDeliverd" onClick={() => this.navigateTo({ id: oderId, props: this.props, screenName: "OrderDetails" })}>Order Status</StyledButton1>
                    </Box>
                </>
            case 'Return':
                return <>
                    {["return_cancelled", "refunded", "returned"].includes(orderStatus) || returnOrderStatus === "return_cancelled" || returnModeStatus === "request_delivery_partner" ?
                        <StyledButton1 style={{ fontSize: "18px" }} data-testid="navigateTorefunded" onClick={() => this.navigateTo({ id: oderId, props: this.props, screenName: "ReturnStatus" })}>Order Status</StyledButton1> :
                        <Box>
                            <StyledButton style={{ fontSize: "18px", marginRight: '12px' }} data-testid="cancelOrderOpenReturn" onClick={() => this.cancelReturnOpen(oderId)}>Cancel Return</StyledButton>
                            {this.renderOrderButtons(oderId, orderStatus, returnOrderStatus)}
                        </Box>
                    }
                </>
            case 'All Orders':
                return <>
                    <Box>
                        {returnOrderStatus === "return_placed" ?
                            <StyledButton style={{ fontSize: "18px", marginRight: '12px' }} data-testid="cancelOrderOpenReturn" onClick={() => this.cancelReturnOpen(oderId)}>Cancel Return</StyledButton>
                            : this.returnTates(oderId, returnOrderStatus)}
                        <StyledButton1 style={{ fontSize: "18px" }} data-testid="navigateToAllOrder" onClick={() => this.navigateTo({ id: oderId, props: this.props, screenName: "OrderDetails" })}>Order Status</StyledButton1>
                    </Box>
                </>
            default:
                return null;
        }
    }
    deliveryAddresses = (label: string, delivery_addresses: {
        id: string;
        type: string;
        attributes: {
            name: string;
            country_code: string;
            phone_number: string;
            contact_number: string;
            street: string;
            zip_code: string;
            area: string;
            block: string;
            city: string;
            house_or_building_number: string;
            address_name: string;
            is_default: boolean;
            latitude: number;
            longitude: number;
        };
    }) => {
        const address = this.getAddress(delivery_addresses)
        return (
            <>
                <span style={webStyle.cusName}>{label === "All Orders" ? "Shipping Address" : "Address"} &nbsp;:</span>&nbsp;
                <span style={webStyle.customer}>{address}</span>
            </>
        )
    }
    dataPicker = () => (
        <Box position="relative">
            <Box style={webStyle.menBox}>
                <Box style={webStyle.picker}>
                    <Box>
                        <Box style={{ ...webStyle.customer, fontSize: "10px" }}>From</Box>
                        <Box style={{ ...webStyle.customer, fontSize: "12px" }}>{this.state.startDate ? this.state.startDate : 'DD-YY-MM'}</Box>
                    </Box>
                    <Box>
                        <Box style={{ ...webStyle.customer, fontSize: "10px" }}>To</Box>
                        <Box style={webStyle.pickerBox}>
                            <Box style={{ ...webStyle.customer, fontSize: "12px" }}>{this.state.endDate ? this.state.endDate : 'DD-YY-MM'}</Box>
                            <Box data-testid="handleInputClick" onClick={this.handleInputClick}><img width="14px" height="14px" src={calendarpng} /></Box>
                        </Box>
                    </Box>
                </Box>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Box style={webStyle.ShortBy}>Sort by:</Box>&nbsp;&nbsp;
                    <Box>
                        <CustomSelect variant="outlined" style={{ width: '196px', backgroundColor: '#ffffff' }}>
                            <MuiSelectSelect
                                style={webStyle.reminderName}
                                value={this.state.numberData}
                                onChange={this.handleSingleChangeNumber}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Select Option', "data-testid": "select-id", }}
                                data-testid='sub-categories-selection'
                                MenuProps={{
                                    getContentAnchorEl: null,
                                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
                                }}
                            >
                                <MenuItem value="" aria-label="Select Option" data-testid="select-option"><Box style={webStyle.newShort}>Most Recent</Box></MenuItem>
                                {this.state.short.map((select: string, index: number) => (
                                    <MenuItem key={index} data-testid="sub-cat-list" style={webStyle.newShort} value={select}>{select}</MenuItem>
                                ))}
                            </MuiSelectSelect>
                        </CustomSelect >
                    </Box>
                </Box>
            </Box>
            {this.state.showDatePicker && (
                <Box style={{ ...webStyle.datePick, position: "absolute" }}>
                    <DateRangePicker
                        onSelect={this.onChange}
                        singleDateRange={true}
                    />
                </Box>
            )}
        </Box>
    )
    tabData = (label: string) => (
        <Box>
            {this.state.myOrders && this.state.myOrders?.map((orderItem, index: number) => {
                return (
                    <>
                        <Box style={{ ...webStyle.orderContent, marginBottom: '22px' }} key={index}>
                            <Box style={webStyle.orderIdBox}>
                                <Box style={webStyle.orderId}>OrderId : #{orderItem.attributes && orderItem.attributes.order_number}</Box>
                                <Box style={{ ...webStyle.cusName, color: "#94A3B8" }}>{orderItem.attributes && orderItem.attributes.order_items.length} Product</Box>
                            </Box>
                            <Box style={webStyle.customerData}>
                                <Box>
                                    <Box style={{ paddingBottom: '7px' }}>
                                        <span style={webStyle.cusName}>Customer Name&nbsp;:</span>&nbsp;
                                        <span style={webStyle.customer}>{orderItem.attributes && orderItem.attributes.account}</span>
                                    </Box>
                                    <Box style={{ paddingBottom: '7px' }}>

                                        {this.deliveryAddresses(label, orderItem.attributes && orderItem.attributes.delivery_addresses)}
                                    </Box>
                                    {label !== "Processing" &&
                                        <Box style={{ paddingBottom: '7px' }}>
                                            <span style={webStyle.cusName}>Payment Method&nbsp;:</span>&nbsp;
                                            <span style={webStyle.customer}>Debit Card ending&nbsp;{orderItem.attributes && orderItem.attributes.payment_detail?.last_four_card_digit}</span>
                                        </Box>
                                    }
                                </Box>
                                <Box>
                                    <Box style={{ paddingBottom: '7px', ...webStyle.endContent }}>
                                        <Box style={webStyle.cusName}>
                                            Order Status&nbsp;:&nbsp;
                                            <span style={{ ...webStyle.customer, textTransform: "capitalize" }}>{orderItem.attributes && orderItem.attributes.status}</span>
                                        </Box>
                                    </Box>
                                    <Box style={{ ...webStyle.cusName, ...webStyle.endContent, paddingBottom: '7px' }}>
                                        {"Order Date"}&nbsp;:&nbsp;
                                        <div style={webStyle.customer}>{new Date(orderItem.attributes && orderItem.attributes.placed_at).toLocaleDateString('en-GB', {
                                            day: '2-digit',
                                            month: 'short',
                                            year: 'numeric',
                                        })}</div>
                                    </Box>

                                </Box>
                            </Box>
                            <Box style={webStyle.productContainer}>
                                <Box style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                                    {orderItem.attributes.order_items?.map((item, index: number) => {
                                        return (
                                            <>
                                                <Box style={webStyle.proData} key={index}>
                                                    <img src={item.attributes.catalogue_variant_front_image === "" ? Noimageavailablepng : item.attributes.catalogue_variant_front_image} width="96px" height="96px" style={{ borderRadius: "4px" }} />
                                                    <Box>
                                                        <div style={{ ...webStyle.proName, whiteSpace: "nowrap", textOverflow: "ellipsis", width: '100px' }}>{item.attributes.catalogue_name} </div>
                                                        <div style={{ ...webStyle.cusName, ...webStyle.proDetail }}>
                                                            <span style={{ ...webStyle.cusName, marginRight: "4px" }}>Colour :</span>
                                                            <div style={{ ...webStyle.colorBorder, position: "relative" }}>
                                                                <div style={{ ...webStyle.colorRound, position: "absolute", backgroundColor: item.attributes.catalogue_variant_color }} />
                                                            </div>
                                                            <span style={webStyle.sizes}>Size&nbsp;:</span>&nbsp;
                                                            <span style={webStyle.customer}>{item.attributes.catalogue_variant_size}</span>
                                                        </div>
                                                        <div style={{ ...webStyle.cusName, color: "#375280", paddingBottom: '5px' }}>Qty : <span style={webStyle.customer}> {item.attributes.quantity}</span></div>
                                                        <div style={{ ...webStyle.cusName, color: "#375280" }}>Total : <span style={webStyle.customer}>$&nbsp;{item.attributes.total_price}</span></div>
                                                        <Box style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                                                            <Box>
                                                                {label !== "Processing" &&
                                                                    <Box style={{ marginRight: '17px' }}>
                                                                        <span style={webStyle.cusName}>Store Name&nbsp;:</span>&nbsp;
                                                                        <span style={webStyle.customer}>{item.attributes.store_name}</span>
                                                                    </Box>
                                                                }
                                                            </Box>
                                                            <Box>
                                                                {label !== "Processing" &&
                                                                    <Box style={{ ...webStyle.cusName, ...webStyle.endContent }}>
                                                                        Delivery Partner&nbsp;:&nbsp;
                                                                        <div style={webStyle.customer}>{item.attributes?.driver_name}</div>
                                                                    </Box>
                                                                }
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </>
                                        )
                                    })}
                                </Box>
                                <Box style={{ display: "flex", gap: "10px", marginTop: "24px" }}>
                                    {this.styledButton(label, orderItem.id, orderItem.attributes.status, orderItem.attributes.order_items[0]?.attributes.status, orderItem.attributes.order_items[0]?.attributes.return_type)}
                                </Box>
                            </Box>
                        </Box>
                    </>
                )
            })}
        </Box>
    )
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <Header navigation={this.props.navigation} />
                <Grid style={{ backgroundColor: "#F8F8F8" }} container>
                    <Grid item lg={3} md={3}>
                        <div
                            style={webStyle.innerContainer11}>
                            <Typography style={webStyle.innerContainer23}>
                                Home
                            </Typography>
                            <NavigateNextRoundedIcon style={{ color: '#375280' }} />
                            <Typography style={webStyle.innerContainer23}>
                                My Account
                            </Typography>
                            <NavigateNextRoundedIcon style={{ color: '#375280' }} />
                            <Typography style={webStyle.innerContainer23}>
                                My Order
                            </Typography>
                        </div>
                        <div >
                            <Typography style={webStyle.innerContainer3}>
                                <img src={scrollImg} />
                                {this.state.profileName.split(' ')
                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                    .join(' ')}
                            </Typography>
                        </div>
                        <Typography style={webStyle.innerContainer4}>
                            Welcome to your Account
                        </Typography>
                        <Sidebar navigation={this.props.navigation} />
                    </Grid>
                    <Grid item lg={9} md={9}>
                        <Box style={{ backgroundColor: "#F8F8F8", margin: '90px 0px', marginRight: '92px' }}>
                            <Box style={webStyle.orderText}>
                                My Orders
                            </Box>
                            <Box>
                                <Box style={{ paddingBottom: "30px" }}>
                                    <Box style={webStyle.container}>
                                        <Box >
                                            <Tabs
                                                value={this.state.value}
                                                onChange={(event, newValue) => this.handleTabChange(event, newValue)}
                                                variant="scrollable"
                                                scrollButtons="auto"
                                                TabIndicatorProps={{ style: { ...webStyle.tabs } }}
                                            >
                                                <Tab data-testid="Processing" label="Processing" style={{ ...webStyle.allTab, textTransform: 'none' }} />
                                                <Tab data-testid="Deliverd" label="Delivered" style={{ ...webStyle.allTab, textTransform: 'none' }} />
                                                <Tab data-testid="Return" label="Return" style={{ ...webStyle.allTab, textTransform: 'none' }} />
                                                <Tab data-testid="All_Orders" label="All Orders" style={{ ...webStyle.allTab, textTransform: 'none' }} />
                                            </Tabs>
                                        </Box>
                                        <Box >
                                            <InputBase
                                                placeholder='Search for a product '
                                                style={webStyle.searchBox}
                                                startAdornment={
                                                    <IconButton style={{ padding: '10px' }} aria-label="search">
                                                        <img src={Search} width="20px" height="20px" />
                                                    </IconButton>
                                                }
                                            />
                                        </Box>
                                    </Box>
                                    {['Processing', 'Deliverd', 'Return', 'All Orders'].map((value, index) => {
                                        const isCurrentTab = this.state.value === index;
                                        const isLoading = this.state.tabeLoder;
                                        const isAlert = this.state.isAlert;
                                        const allOrderMessage = this.state.allOrderMasg;
                                        const content = (
                                            <div>
                                                {isLoading && <Box data-testid="loder" style={{ ...webStyle.tabsBox, textAlign: 'center', textAlignLast: 'center' }}><CircularProgress style={{ color: '#375280' }} /></Box>}
                                                {!isLoading && isAlert && <Box style={{ ...webStyle.tabeLable, textAlignLast: 'center', textAlign: 'center' }}>{allOrderMessage}</Box>}
                                                {!isLoading && !isAlert && <div> {this.dataPicker()}{this.tabData(value)}</div>}
                                            </div>
                                        );
                                        return isCurrentTab ? <>{content}</> : null;
                                    })}
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <StyledDrawer
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.open1}
                    onClose={this.handleClose1}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.open1} style={webStyle.fade}>
                        <div style={{ textAlign: "center", padding: "10px 30px 30px 30px" }}>
                            <h2 id="transition-modal-title" style={webStyle.fadeH2}>Are you sure you want to cancel this order ?</h2>
                            <div style={{ marginTop: "40px", display: "flex", gap: "20px", justifyContent: "space-between" }}>
                                <StyledButtonModel style={{ fontSize: "14px" }} data-testid="handleClose1" onClick={this.handleClose1}>No</StyledButtonModel>
                                <StyledButton1Model style={{ fontSize: "14px" }} data-testid={`cancelOrder-${this.state.modelId}`} onClick={() => this.cancelOrder(this.state.modelLable, this.state.modelId)}>Yes</StyledButton1Model>
                            </div>
                        </div>
                    </Fade>
                </StyledDrawer>
                <StyledDrawer
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.modeReturnOpen}
                    onClose={this.modeRetuneHandleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.modeReturnOpen} style={{ maxWidth: "501px", backgroundColor: "white" }}>
                        <div style={{ padding: "40px" }}>
                            <h2 id="transition-modal-title" style={{ ...webStyle.fadeMode, textAlign: 'center' }}>Mode of Return</h2>
                            <Box style={{ display: 'flex', justifyContent: "space-between" }}>
                                <Typography style={webStyle.fadeTypo}>Self-Drop Off</Typography>
                                <Box>
                                    <FormControlLabel
                                        control={<StyledRadioWithStyles />}
                                        label=""
                                        value="self_drop_off"
                                        defaultValue="self_drop_off"
                                        data-testid="self_drop_offRadio"
                                        onChange={this.handleGenderChange}
                                        checked={this.state.modeofreturn === 'self_drop_off'}
                                    />
                                </Box>
                            </Box>
                            <Typography style={{ fontSize: '14px', color: "#94A3B8", fontWeight: 400, fontFamily: "Lato", marginBottom: '9px' }}>Drop off the product at store by yourself</Typography>
                            <Divider style={{ margin: '24px 0px' }} />
                            <Box style={{ display: 'flex', justifyContent: "space-between" }}>
                                <Typography style={webStyle.fadeTypo}>Request a Delivery Partner</Typography>
                                <Box>
                                    <FormControlLabel
                                        control={<StyledRadioWithStyles />}
                                        label=""
                                        value="request_delivery_partner"
                                        data-testid="request_delivery_partnerRadio"
                                        onChange={this.handleGenderChange}
                                        checked={this.state.modeofreturn === 'request_delivery_partner'}
                                    />
                                </Box>
                            </Box>
                            <Typography style={webStyle.deilveryData}>Schedule a delivery partner to pick up the product. Delivery charges will be applicable.</Typography>
                            {this.state.modeofreturn === 'request_delivery_partner' && <Typography style={webStyle.deilveryNote}>Note - Pickup charges will be deducted from your refund</Typography>}
                            <Box style={{ display: 'flex', justifyContent: "space-between" }}>
                                <Typography style={webStyle.pickupCharge}>Estimated Pickup Charges</Typography>
                                {this.renderCharge()}
                            </Box>
                            <Divider style={{ margin: '24px 0px' }} />
                            <Box style={{ display: 'flex', justifyContent: "space-between", marginBottom: '40px' }}>
                                <Typography style={webStyle.totelCharge}>Total Charges</Typography>
                                {this.renderCharge()}
                            </Box>
                            <StyledButton1Model style={webStyle.submitButton} onClick={this.sellerReuturnType} data-testid="modeRetuneHandleSubmit">Submit</StyledButton1Model>
                        </div>
                    </Fade>
                </StyledDrawer>
                <StyledDrawer
                    aria-describedby="transition-modal-description"
                    BackdropComponent={Backdrop}
                    aria-labelledby="transition-modal-title"
                    onClose={this.handleReturnClose}
                    open={this.state.cancelReturnOrder}
                    closeAfterTransition
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.cancelReturnOrder} style={webStyle.fade}>
                        <div style={{ textAlign: "center", padding: "10px 30px 30px 30px" }}>
                            <h2 id="transition-modal-title" style={webStyle.fadeH2}>Are you sure you want to cancel this order ?</h2>
                            <div style={{ marginTop: "40px", display: "flex", gap: "20px", justifyContent: "space-between" }}>
                                <StyledButtonModel style={{ fontSize: "14px" }} data-testid="handleReturnClose" onClick={this.handleReturnClose}>No</StyledButtonModel>
                                <StyledButton1Model style={{ fontSize: "14px" }} data-testid={`cancelReturnOrder-${this.state.modelId}`} onClick={() => this.cancelReturnOrder()}>Yes</StyledButton1Model>
                            </div>
                        </div>
                    </Fade>
                </StyledDrawer>
                <StyledDrawer
                    aria-describedby="transition-modal-description"
                    BackdropComponent={Backdrop}
                    aria-labelledby="transition-modal-title"
                    onClose={this.modeOfsuccessClose}
                    open={this.state.modeOfsuccessOpen}
                    closeAfterTransition
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.modeOfsuccessOpen} style={webStyle.modeOffade}>
                        <div style={{ textAlign: "center", padding: "50px" }}>
                            <img src={modeofimage} />
                            <h2 id="transition-modal-title" style={webStyle.modeOffadeH2}>Self-Drop Off Confirmed</h2>
                            <Typography style={{ fontSize: '24px', fontFamily: 'Lato', fontWeight: 400, color: '#375280', margin: '43px 0px' }}>You must return the items within 24 hours.</Typography>
                            <div style={{ marginTop: "40px" }}>
                                <StyledButtonModelOfRefund style={{ width: '184px !important', fontSize: "14px", margin: '0px 6px' }} data-testid="modeOfsuccessClose" onClick={() => this.cancelReturnOpen(this.state.modeOfOrderId)}>Cancel Return</StyledButtonModelOfRefund>
                                <StyledButton1Model style={{ width: '184px', fontSize: "14px", margin: '0px 6px' }} data-testid={`cancelReturnOrder-${this.state.modelId}`} onClick={this.modeOfsuccessClose}>Mode of Return</StyledButton1Model>
                            </div>
                        </div>
                    </Fade>
                </StyledDrawer>
                <StyledDrawer
                    aria-describedby="transition-modal-description"
                    BackdropComponent={Backdrop}
                    aria-labelledby="transition-modal-title"
                    onClose={this.modedeliverysuccessOpen}
                    open={this.state.modedeliverysuccessOpen}
                    closeAfterTransition
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.modedeliverysuccessOpen} style={webStyle.modeOffade}>
                        <div style={{ textAlign: "center", padding: "50px" }}>
                            <img src={modeofimage} />
                            <h2 id="transition-modal-title" style={webStyle.modeOffadeH2}>Driver Requested</h2>
                            <Typography style={{ fontSize: '24px', fontFamily: 'Lato', fontWeight: 400, color: '#375280', margin: '43px 0px' }}>A delivery partner will be assigned soon</Typography>
                            <div style={{ marginTop: "40px" }}>
                                <StyledButton1Model style={{ width: '302px', fontSize: "18px" }} data-testid="trackReturn" onClick={this.modedeliverysuccessOpen}>Track Return</StyledButton1Model>
                            </div>
                        </div>
                    </Fade>
                </StyledDrawer>
                <Footer navigation={this.props.navigation} />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    deilveryNote: { fontSize: '14px', color: "#375280", fontWeight: 400, fontFamily: "Lato", marginTop: '96px', marginBottom: "24px" },
    deilveryData: { fontSize: '14px', color: "#94A3B8", fontWeight: 400, fontFamily: "Lato", marginBottom: '96px' },
    pickupCharge: { fontSize: '18px', color: "#375280", fontWeight: 500, fontFamily: "Lato", marginBottom: '9px' },
    totelCharge: { fontSize: '20px', color: "#375280", fontWeight: 700, fontFamily: "Lato", marginBottom: '9px' },
    submitButton: { fontWeight: 700, fontFamily: "Lato", fontSize: "18px", width: '100%', height: '64px' },
    fadeTypo: { fontSize: '18px', color: "#375280", fontWeight: 700, fontFamily: "Lato", marginBottom: '9px' },
    fadeMode: { fontSize: '30px', color: "#375280", fontWeight: 500, fontFamily: "Lato", marginBottom: '72px' },
    fadeH2: { width: "300px", fontSize: '18px', color: "#375280", fontWeight: 600, marginLeft: "10px", fontFamily: "Lato" },
    modeOffadeH2: { fontSize: '24px', color: "#375280", fontWeight: 800, fontFamily: "Lato", marginTop: '28px' },
    fade: { borderRadius: '10px', maxWidth: "320px", backgroundColor: "white" },
    modeOffade: { maxWidth: "529px", backgroundColor: "white" },
    tabeLable: { color: "rgb(55, 82, 128)", fontSize: '20px', fontWeight: 800, fontFamily: 'Lato', width: '100%', height: '75%', paddingBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
    tabsBox: { width: '100%', height: '75%', paddingBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
    menBox: { display: "flex", justifyContent: 'space-between', marginTop: "30px", marginBottom: "24px" },
    newShort: { color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato" },
    ShortBy: { color: "#94A3B8", fontSize: '14px', fontFamily: "Lato", fontWeight: 400 },
    reminderName: { backgroundColor: '#FFFFFF', height: '45px' },
    innerContainer11: {
        gap: "10px",
        display: "flex",
        fontWeight: 400,
        alignItems: "center",
        lineHeight: "22px",
        marginLeft: "40px",
        marginTop: "27px",
        color: "#475569",
    },
    innerContainer23: {
        fontSize: "14px",
        fontFamily: "Lato , sans-serif",
        padding: "4px 6px 4px 6px",
        fontStyle: "normal",
        lineHeight: "22px",
        color: "#375280"
    },
    innerContainer3: {
        alignItems: "center",
        display: "flex",
        marginTop: "31px",
        lineHeight: "33.5px",
        marginLeft: "40px",
        color: "#375280",
        fontWeight: 800,
        gap: "15px",
        fontStyle: "normal",
        fontSize: "28px",
        fontFamily: "Lato , sans-serif",
    },
    innerContainer4: {
        marginBottom: "37px",
        marginLeft: "40px",
        color: "#807D7E",
        fontWeight: 400,
        lineHeight: "33.5px",
        fontStyle: "normal",
        fontSize: "14px",
        fontFamily: "Lato , sans-serif",

    },
    pickerBox: { display: "flex", gap: '8px', alignItems: "center" },
    picker: { display: "flex", gap: "12px", width: "193px", height: "48px", borderRadius: "2px", backgroundColor: "#FFFFFF", padding: "4px 10px 4px 12px", justifyContent: 'center', alignItems: "center" },
    endContent: { display: "flex", justifyContent: "end" },
    orderText: { color: "#375280", fontFamily: "Lato", fontWeight: 800, fontSize: "20px", paddingBottom: "26px" },
    container: { display: "flex", justifyContent: "space-between" },
    tabs: { backgroundColor: '#375280', borderBottom: '1px solid #E2E8F0' },
    allTab: { fontSize: "14px", fontFamily: "Lato", fontWeight: 600, color: "#375280", borderBottom: '1px solid #E2E8F0' },
    searchBox: { width: "249px", height: "48px", border: "1px solid #CBD5E1", background: "#FFFFFF", color: "#94A3B8", fontSize: "16px", fontFamily: "Lato", fontWeight: 400, borderRadius: "2px", padding: "10px, 8px, 10px, 12px" },
    orderContent: { marginTop: "20px", backgroundColor: '#ffffff', padding: "28px 47px", borderRadius: '2px' },
    orderIdBox: { display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: "14px" },
    orderId: { fontSize: "20px", fontWeight: 800, color: "#375280", fontFamily: "Lato" },
    customerData: { display: "flex", justifyContent: "space-between", marginTop: "10px", alignItems: "center" },
    cusName: { fontSize: "14px", fontWeight: 700, color: "#375280", fontFamily: "Lato", },
    customer: { fontSize: "14px", fontWeight: 400, color: "#94A3B8", fontFamily: "Lato" },
    productContainer: { display: "flex", justifyContent: "space-between", marginTop: "36px" },
    proData: { display: "flex", justifyContent: "row", alignItems: "center", gap: "21px" },
    proName: { fontSize: "16px", fontWeight: 700, color: "#375280", fontFamily: "Lato", paddingBottom: '10px' },
    proDetail: { paddingBottom: '5px', display: "flex", alignItems: "center" },
    colorBorder: {
        display: 'inline-block',
        width: '10px',
        height: '10px',
        border: "1px solid #375280",
        borderRadius: "50%",
    },
    colorRound: {
        width: 'calc(100% - 2px)',
        height: 'calc(100% - 2px)',
        borderRadius: "50%",
        top: "1px",
        left: "1px",
    },
    sizes: { fontSize: "14px", fontWeight: 700, color: "rgba(55, 82, 128, 1)", marginLeft: "17px" },
    datePick: { zIndex: 1, backgroundColor: 'white', borderRadius: '4px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' },
};
const StyledButton = styled(Button)(() => ({
    fontWeight: 600,
    color: "#375280",
    border: "1px solid #CCBEB1",
    textTransform: "none",
    width: "170px",
    height: "50px",
    borderRadius: "2px",
    '&:hover': {
        backgroundColor: "white",
    },
}));

const StyledButton1 = styled(Button)(() => ({
    fontWeight: 600,
    color: "#fff",
    width: "182px",
    height: "50px",
    textTransform: "none",
    backgroundColor: '#CCBEB1',
    '&:hover': {
        backgroundColor: "#CCBEB1"
    },
}))
const CustomSelect = withStyles(theme => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#ffffff',
            },
            '&:hover fieldset': {
                borderColor: '#ffffff',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#ffffff',
            },
            "& .MuiSelect-select:focus": {
                backgroundColor: "transparent"
            }
        },
    },
}))(FormControl);
const MuiSelectSelect = styled(Select)({
    "&.MuiOutlinedInput-root": {
        borderRadius: '2px', color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato"
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#e9eef4',

    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderColor: "#e9eef4",
    },
})
const StyledDrawer = styled(Modal)((theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "none",
}));
const StyledButtonModel = styled(Button)(() => ({
    fontWeight: 500,
    color: "#375280",
    border: "1px solid #CCBEB1",
    textTransform: "none",
    width: "150px !important",
    height: "45px",
    borderRadius: "2px",
    '&:hover': {
        backgroundColor: "white",
    },
}))
const StyledButtonModelOfRefund = styled(Button)(() => ({
    fontWeight: 500,
    color: "#375280",
    border: "1px solid #CCBEB1",
    textTransform: "none",
    width: "184px !important",
    height: "45px",
    borderRadius: "2px",
    '&:hover': {
        backgroundColor: "white",
    },
}))
const StyledButton1Model = styled(Button)(() => ({
    fontWeight: 500,
    color: "#fff",
    width: "150px",
    height: "45px",
    textTransform: "none",
    backgroundColor: '#CCBEB1',
    '&:hover': {
        backgroundColor: "#CCBEB1"
    },
}))
const styles = {
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 20,
        height: 20,
        border: '1px solid #CCBEB1',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:disabled ~ &': {
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        border: '2px solid #cbbeb2',
        borderRadius: '50%',
        width: 20,
        height: 20,
        backgroundColor: '#fff',
        '&:before': {
            content: '""',
            display: 'block',
            width: 10,
            height: 10,
            backgroundColor: '#cbbeb2',
            borderRadius: '50%',
            marginTop: '25%',
            marginLeft: '25%',
        },
    },
};
const StyledRadioWithStyles = withStyles(styles)((props: WithStyles<typeof styles>) => (
    <Radio
        className={props.classes.root}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(props.classes.icon, props.classes.checkedIcon)} />}
        icon={<span className={props.classes.icon} />}
        inputProps={{ 'aria-label': 'customized-radio' }}
        {...props}
    />
));
// Customizable Area End