import React from "react";
// Customizable Area Start
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import { Grid, Dialog, Avatar, styled, Box, CardContent as MuiCardContent, Breadcrumbs, Typography, Divider, Tabs, Tab, Card, CardMedia, CardContent, IconButton, TextField, Button } from "@material-ui/core";
import HiredStylistController, {
    Props,
} from "./HiredStylistController.web";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { message as messageIcon, search } from "./assets"
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
// Customizable Area End

export default class HiredStylist extends HiredStylistController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    topPart = () => {
        return (<BreadcrumbStyle>
            <Breadcrumbs aria-label="breadcrumb" className="breadcrumb" separator={<KeyboardArrowRightRoundedIcon />}>
                <Typography color="inherit">
                    Stylist
                </Typography>
                <Typography color="inherit">
                    Stylists Portfolios
                </Typography>
                <Typography color="inherit">Stylist's Profile</Typography>
            </Breadcrumbs>
            <Box className="pageTitle">

                <IconButton className="iconButton">
                    <KeyboardArrowLeftRoundedIcon />
                </IconButton>
                <Typography className="text">Stylist's Profile</Typography>
            </Box>
        </BreadcrumbStyle>)
    }

    StylistCard = () => {
        return (
            <>
            
            <StylistCardContainer>
                <Box className="avatar-box">
                    <Avatar className="avatar" src="your-avatar-url-here" alt="Himawari Uzumaki" />
                    <Box>
                        <Typography variant="h6" className="name-text">Himawari Uzumaki</Typography>
                        <Typography variant="body2" className="available-text">Available to Hire</Typography>
                    </Box>

                    <IconButton className="favorite-icon">
                        <FavoriteBorderIcon />
                    </IconButton>
                </Box>

                <Typography variant="body2" className="description">
                    Experienced fashion stylist with a passion for creating unique and personalized looks that empower individuals
                    to express their authentic selves. With over 9 years in the fashion industry, I've honed my skills in wardrobe
                    selection, trend analysis, and outfit coordination.
                </Typography>


                <Box className="action-box">
                    <Box className="action-button">
                        <CallOutlinedIcon className="icon" />
                        <Typography variant="body2">Request a callback</Typography>
                    </Box>
                    <Box className="action-button">
                        <img src={messageIcon} className="icon" />
                        <Typography variant="body2">Chat with Annette</Typography>
                    </Box>
                </Box>

                <Divider style={{ margin: '16px 0' }} />
                <Button fullWidth className="hire-button">
                    Hire Stylist
                </Button>

            </StylistCardContainer>
            </>
        );
    };

    openStylistPortfolioDialog = () => (
        <DialogStylistPortfolio
            onClose={this.closeStylistPortfolio}
            open={this.state.openStylistPortfolio}
            className="dialog"
            data-testid="dialog"
        >
            <CloseOutlinedIcon className="closeIcon" onClick={this.closeStylistPortfolio} data-testid="closeIcon"/>
            <StylistRow>
                <div className="imageWrapper">
                    <img src="https://images.unsplash.com/photo-1522770179533-24471fcdba45" alt="img" />
                    <div className="nameInfo">
                        <Typography className="stylistName">Eleanor Pena</Typography>
                        <Typography className="stylistPosition">Available to Hire</Typography>
                    </div>
                </div>
                <div>
                    <IconButton className="favBtn">
                        <FavoriteBorderIcon fill="#375280" />
                    </IconButton>
                    <Button variant="contained" className="sourceButton" data-testid="sourceButton" style={{ marginBottom: 2, backgroundColor: '#c6b4a6' }} onClick={this.openStylistProfilePage}>
                        Stylist's Profile
                    </Button>
                </div>
            </StylistRow>
            <Typography variant="h5" component="h2" className="title">
                Explore Portfolios
            </Typography>

            <Typography variant="body1" className="description">
                Person is dressed with a Levi's white T-Shirt and a Highlander denim jacket. Paired with a Levis black jeans and black belt.
            </Typography>

            <img src="https://images.unsplash.com/photo-1522770179533-24471fcdba45" alt="portfolio image" className="portfolioImage" />

        </DialogStylistPortfolio>
    )
    generateRow = (largeIndex: number, smallStartIndex: number) => (
        <Grid container spacing={2} >
            <Grid item xs={12} sm={6}>
                <img src={itemData[largeIndex].img} alt={itemData[largeIndex].title} className="largeImage" />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                    {itemData.slice(smallStartIndex, smallStartIndex + 6).map((item, index) => (
                        <Grid item xs={6} sm={4} key={index}>
                            <img src={item.img} alt={item.title} className="smallImage" />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );

    reverseGenerateRow = (largeIndex: number, smallStartIndex: number) => (
        <Grid container spacing={2} >
            <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                    {itemData.slice(smallStartIndex, smallStartIndex + 4).map((item, index) => (
                        <Grid item xs={6} sm={6} key={index}>
                            <img src={item.img} alt={item.title} className="smallImage" />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
                <img src={itemData[largeIndex].img} alt={itemData[largeIndex].title} className="largeImage" />
            </Grid>
        </Grid>
    )

    renderExplorePortfolio = () => {
        return (
            <>
                <ImageTitle>Explore Portfolio</ImageTitle>
                <ImageCollage>
                    {this.generateRow(0, 1)}
                    {this.reverseGenerateRow(9, 5)}
                </ImageCollage>
                <Box className="viewMore">
                    <Button variant="outlined" className="viewMoreButton">
                        View More
                    </Button>
                </Box>
            </>
        )
    }

    renderProductSourcing = () => {
        return (
            <>
                <ProductSource>
                    <BtnTitle>Product sourcing
                        <Button variant="contained" className="sourceButton" style={{ marginBottom: 2, backgroundColor: '#c6b4a6' }}>
                            Source a Product
                        </Button>
                    </BtnTitle>
                    {cardData.map((item, index) => {
                        return  <ProductCard className="card">
                            <Box display="flex" flexGrow={1}>
                                <img
                                    src={item.image}
                                    alt={item.title}
                                    className="image"
                                />
                                <CardContent className="content">
                                    <Typography component="div" variant="h6" className="title">
                                        {item.title}
                                    </Typography>
                                    <Typography variant="subtitle1" className="quotes" component="div">
                                        Quotes - {item.quotes}
                                    </Typography>
                                    <Typography component="div" variant="h6" className="fees">
                                        {item.priceRange}
                                    </Typography>
                                </CardContent>
                                <Box className="actions">
                                    <Button variant="outlined" className="deleteBtn" color="secondary">
                                        Delete
                                    </Button>
                                    <Button variant="contained" className="viewButton">
                                        View
                                    </Button>
                                </Box>
                            </Box>
                        </ProductCard>
                    })}
                </ProductSource>
            </>
        )
    }

    renderStylistUI = () => {
        return (
            <Box pt={3}>
                <TextField
                    variant="outlined"
                    placeholder="Search Stylist"
                    fullWidth
                    InputProps={{ startAdornment: <img src={search} className="searchIcon"/> }}
                    className="searchBox"
                />
                <Grid container spacing={3}>
                    {itemData.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                            <StylishCard>
                                <CardMedia component="img" height="200" image={item.img} alt={item.title} />
                                <CardContent className="cardContent">
                                    <Box className="content">
                                    <Typography variant="h6" component="div" className="title">
                                        {item.title}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" className="description">
                                        I take pride in creating memorable styles
                                    </Typography>
                                    </Box>
                                    <IconButton className="favBtn">
                                        <FavoriteBorderIcon fill="#375280" />
                                    </IconButton>
                                </CardContent>
                            </StylishCard>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        )
    }

    renderRequestedStylist = () => {
        return (
            <>
                <ImageTitle>Stylists</ImageTitle>
                {cardData.map((item) => <RequestedStylist>
                    <StylistRow>
                        <div className="imageWrapper">
                            <img src={item.image} alt="img" />
                            <div className="nameInfo">
                                <Typography className="stylistName">Eleanor Pena</Typography>
                                <Typography className="stylistPosition" style={{ color: '#FF9800', fontWeight: 400}}>Requested</Typography>
                            </div>
                        </div>
                    </StylistRow>
                    <Box className="actions">
                        <img src={messageIcon} style={{ color: '#375280', marginRight: '16px' }} />
                        <Button variant="outlined"  className="deleteBtn" >
                            Delete
                        </Button>
                        <Button variant="contained" className="viewButton">
                            Edit Request
                        </Button>
                    </Box>
                </RequestedStylist>)}
            </>
        )
    }

    renderProducts = () => {
        return (
            <Grid container spacing={3} style={{ marginTop: "15px"}}>
                {itemData.map((item, index) =>
                    <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                        <ProductCardWrapper>
                            <div className="card">
                                <img className="productImage" src={item.img} alt="Black Sweatshirt" />
                                <div className="discountBadge">15% Off</div>
                                <IconButton className="favoriteIcon">
                                    <FavoriteBorderIcon />
                                </IconButton>
                                <div className="productInfo">
                                    <div><p className="productName">Black Sweatshirt with...</p>
                                        <p className="brandName">Jhanvi’s Brand</p></div>
                                    <p className="price">$123.00</p>
                                </div>
                            </div>
                        </ProductCardWrapper>
                    </Grid>
                )}
            </Grid>
        )
    }
    renderPricingTab = () => {
        return (
            <Grid container spacing={3} style={{ marginTop: "15px" }}>
                {pricing.map((item, index) =>
                    <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                        <CardWrapper>
                            <MuiCardContent className="content">
                                <div className="titleRow">
                                    <Typography className="title" variant="h6">
                                        {item.title}
                                    </Typography>
                                    <Typography className="price" variant="h6">
                                        ${item.price}/-
                                    </Typography>
                                </div>
                                <div className="divider" />
                                <ul className="featureList">
                                    <li className="featureItem">{item.line1}</li>
                                    <li className="featureItem">{item.line2}</li>
                                    <li className="featureItem">{item.line3}</li>
                                </ul>
                                <Button className="planButton" fullWidth>
                                    Plan Buy
                                </Button>
                            </MuiCardContent>
                        </CardWrapper>
                    </Grid>
                )}
            </Grid>
        )
    }
    // Customizable Area End
    render() {
        // Customizable Area Start 
        return (
            <>
            <PageWrapper>
                <Header navigation={this.props.navigation} />
                <Grid container style={{ maxWidth: "1440px", margin: "40px auto", width: "100%" }}>
                    <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                        {this.state.openStylistProfile && this.topPart()}
                        <Grid container spacing={3}>
                            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} >
                                {this.state.openStylistProfile 
                                ? 
                                    <LeftBox>
                                        {this.StylistCard()}
                                    </LeftBox>
                                : <LeftBox>
                                    <ImageTitle>Hired Stylists</ImageTitle>
                                    <Divider />
                                    <StylistRow>
                                        <div className="imageWrapper" onClick={() => this.openStylistPortfolio()} data-testid="stylistRow">
                                            <img src="https://images.unsplash.com/photo-1522770179533-24471fcdba45" alt="img" />
                                            <div className="nameInfo">
                                                <Typography className="stylistName">Eleanor Pena</Typography>
                                                <Typography className="stylistPosition">Hired</Typography>
                                            </div>
                                        </div>
                                        <img src={messageIcon} className="messageIcon" />
                                    </StylistRow>
                                    <Divider />
                                    <StylistRow>
                                        <div className="imageWrapper" onClick={() => this.openStylistPortfolio()} data-testid="stylistRow">
                                            <img src="https://images.unsplash.com/photo-1551782450-a2132b4ba21d" alt="img" />
                                            <div className="nameInfo">
                                                <Typography className="stylistName">Eleanor Pena</Typography>
                                                <Typography className="stylistPosition">Hired</Typography>
                                            </div>
                                        </div>
                                        <img src={messageIcon} className="messageIcon" />
                                    </StylistRow>
                                    <Divider />
                                    <StylistRow>
                                        <div className="imageWrapper" onClick={() => this.openStylistPortfolio()} data-testid="stylistRow">
                                            <img src="https://images.unsplash.com/photo-1551963831-b3b1ca40c98e" alt="img" />
                                            <div className="nameInfo">
                                                <Typography className="stylistName">Eleanor Pena</Typography>
                                                <Typography className="stylistPosition">Hired</Typography>
                                            </div>
                                        </div>
                                        <img src={messageIcon} className="messageIcon" />
                                    </StylistRow>
                                </LeftBox>}
                            </Grid>
                            <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                                {this.state.openStylistProfile 
                                ? <WrapperBox>
                                    <CustomTabs value={this.state.selected} data-testid="exploreStylist" onChange={this.handleChange}>
                                        <CustomTab selected={this.state.selected === 0} label="Pricing" />
                                        <CustomTab selected={this.state.selected === 1} label="Products by Himawari Uzumaki" />
                                        <CustomTab selected={this.state.selected === 2} label="Explore Portfolio" />
                                    </CustomTabs>
                                    {this.state.selected === 0 && this.renderPricingTab()}
                                    {this.state.selected === 1 && this.renderProducts()}
                                    {this.state.selected === 2 && this.renderExplorePortfolio()}
                                </WrapperBox>
                                :  <WrapperBox>
                                    <CustomTabs value={this.state.selected} data-testid="explore" onChange={this.handleChange}>
                                        <CustomTab selected={this.state.selected === 0} label="Explore Portfolio" />
                                        <CustomTab selected={this.state.selected === 1} label="Product Sourcing" />
                                        <CustomTab selected={this.state.selected === 2} label="Stylists" />
                                        <CustomTab selected={this.state.selected === 3} label="Requested Stylists" />
                                    </CustomTabs>
                                    {this.state.selected === 0 && this.renderExplorePortfolio()}
                                    {this.state.selected === 1 && this.renderProductSourcing()}
                                    {this.state.selected === 2 && this.renderStylistUI()}
                                    {this.state.selected === 3 && this.renderRequestedStylist()}
                                </WrapperBox>
                                }
                               
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Footer navigation={this.props.navigation} />
            </PageWrapper>
            {this.openStylistPortfolioDialog()}
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const pricing = [
    {
        title: "Weekly Plan",
        price: "200",
        line1: "3 stylings per week ",
        line2: "6 hours of discussion time",
        line3: "Voice and Video call facility",
    },
    {
        title: "Monthly Plan",
        price: "350",
        line1: "3 stylings per week ",
        line2: "6 hours of discussion time",
        line3: "Voice and Video call facility",
    },
    {
        title: "Quarterly Plan",
        price: "600",
        line1: "3 stylings per week ",
        line2: "6 hours of discussion time",
        line3: "Voice and Video call facility",
    }
]
const itemData = [
    {
        img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
        title: "Large Image 1",
    },
    {
        img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
        title: "Small Image 1",
    },
    {
        img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45", 
        title: "Small Image 2",
    },
    {
        img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
        title: "Small Image 3",
    },
    {
        img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
        title: "Small Image 4",
    },
    {
        img: "https://images.unsplash.com/photo-1712652056542-58ca6baac1d3?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8ZnJlZSUyMGltYWdlc3xlbnwwfHwwfHx8MA%3D%3D",
        title: "Small Image 1",
    },
    {
        img: "https://images.unsplash.com/photo-1706694442016-bd539e1d102b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZnJlZSUyMGltYWdlc3xlbnwwfHwwfHx8MA%3D%3D",
        title: "Small Image 2",
    },
    {
        img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
        title: "Small Image 3",
    },
    {
        img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
        title: "Small Image 4",
    },
    {
        img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
        title: "Large Image 2",
    },
];

const cardData = [
    {
        image:"https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
        title:"Formal Blazer for Woman Solid",
        quotes:2,
        priceRange:"$50 - $250"
    }, 
    {
        image:"https://images.unsplash.com/photo-1533827432537-70133748f5c8",
        title:"Formal Blazer for Woman Solid",
        quotes:2,
        priceRange:"$50 - $250"
    }
]
const BreadcrumbStyle = styled(Box)({
    margin: "20px",
    "& .pageTitle": {
        display: "flex",
        alignItems: "center",
        margin: "20px 0"
    },
    "& .iconButton": {
        backgroundColor: '#fff',
        borderRadius: '50%',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)', 
        padding: '8px', 
        marginRight: '8px',
    },
    "& .text": {
        fontFamily: 'Lato',
        fontSize: '18px',
        fontWeight: 600, 
        color: '#375280',
        marginLeft: "5px"
    },
    "& .breadcrumb": {
        fontFamily: 'Lato',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        textAlign: 'left',
        textDecoration: 'none',
        color: '#475569', 
    }
})
const DialogStylistPortfolio = styled(Dialog)({ 
    "& .MuiDialog-paper": {
        padding: "40px 25px 25px", 
        width: "800px"
    }, 
    "& .sourceButton": {
        color: "#fff", 
        textTransform: "none",
        fontSize: "20px", 
        boxShadow: "none", 
        fontWeight: "600", 
        padding: "10px 35px"
    }, 
    "& .title": {
        fontFamily: 'Lato',
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '24px',
        color: "#375280",
    }, 
    "& .description": {
        fontFamily: 'Lato',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '24px',
        textAlign: 'left',
        color: '#94A3B8',
        padding: '8px 0px',
    }, 
    "& .portfolioImage": {
        height: "300px", 
        objectFit: "cover",
        marginTop: "20px"
    },
    '& .favBtn svg': {
        fill: "#375280", 
        height: "30px", 
        width: "30px"
   },
   '& .closeIcon': {
        position: 'absolute',
        right: '20px',
        top: '20px',
        height: "16px",
        width: "16px",
        borderRadius: '50%',
        padding: '5px',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
        cursor: 'pointer'
   },
   "& .imageWrapper": {
        "& img": {
            height: "70px !important",
            width: "70px !important",
            borderRadius: "50% !important",
            border: 'none !important'
        },
    },
    "& .nameInfo": {
        "& .stylistName": {
            fontSize: '20px !important',
        },
        "& .stylistPosition": {
            paddingTop: "0px !important"
        }
    },
})
const CustomTabs = styled(Tabs)({
    borderRadius: '2px',
    '& .MuiTabs-indicator': {
        display: 'none',
    },
});

const WrapperBox = styled(Box)({
    margin: '20px', 
    '@media(min-width: 821px)': {
        margin: '0px',
    },
    "& .viewMore": {
        display: "flex", 
        justifyContent: "center"
    },
    "& .viewMoreButton": {
        borderColor: '#CCBEB1', 
        color: '#375280', 
        textTransform: 'none', 
        fontWeight: 700,
        fontSize: '16px',
        padding: '8px 24px',
        borderRadius: '2px',
        marginTop: '20px',
        cursor: "pointer",
        width: "156px",
        background: "#fff",
        '&:hover': {
          backgroundColor: '#f0f0f0', 
        },
    },
    "& .searchBox": {
        marginBottom: '24px',  
        width: '380px',      
        borderRadius: '2px', 
        background: "white",
        border: '2px solid #CBD5E1', 
        float: "right" , 
        "& fieldset": {
            borderColor: "white", 
            '&:focus': {
                outline: 'none',
                border: 'none',
            }
        }, 
        "& .searchIcon": {
            marginRight: "15px", 
            height: "20px"
        },
        '& .MuiInputBase-input::placeholder': {
            fontFamily: 'Lato',
            fontSize: '16px',
            fontWeight: 400,
            color: '#94A3B8', 
        },
        "& .Mui-focused": {
            borderColor: "white", 
            outline: "none", 
            border: 'none',
        }, 
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'white', 
            },
            '&:hover fieldset': {
              borderColor: 'white', 
            },
            '&.Mui-focused fieldset': {
              borderColor: 'white',
              borderWidth: 0, 
            },
          },
          '& .MuiInputBase-input': {
            '&:focus': {
              outline: 'none',
            },
        },
    },
})

const CustomTab = styled(Tab)({
    height: '40px',
    borderRadius: '2px',
    padding: '11px 20px',
    color: '#375280',
    backgroundColor: '#fff',
    fontWeight: 500,
    border: "none",
    textTransform: "none",
    marginRight: "8px",
    cursor: "pointer",
    '&:hover': {
        backgroundColor: '#ddd',
        color: '#375280',
    },
    "&.Mui-selected": {
        backgroundColor: '#375280',
        color: '#fff',
        fontWeight: 700,
        '&:hover': {
            backgroundColor: '#375280'
        },
    }
});

const StylistRow = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "18px 0",
    "& .imageWrapper": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: "-5px",
        "& img": {
            height: "80px",
            width: "80px",
            borderRadius: "50%",
            objectFit: "cover",
            border: '4px solid #375280'
        },
    },
    "& .nameInfo": {
        display: "block",
        marginLeft: "20px",
        "& .stylistName": {
            fontFamily: 'Lato',
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '28px',
            color: '#375280',
        },
        "& .stylistPosition": {
            fontFamily: 'Lato',
            fontWeight: 900,
            fontSize: '16px',
            lineHeight: '24px',
            marginBottom: '8px',
            color: '#059669',
            paddingTop: "10px"
        }
    },
    "& .messageIcon": {
        fill: "#375280",
        height: "32px",
        width: "32px",
        cursor: "pointer", 
        marginRight: "10px"
    }
});

const ImageTitle = styled(Box)({
    fontWeight: 700,
    fontSize: "24px",
    color: "#375280",
    fontFamily: "Lato",
    padding: "25px 0",
    display: "flex", 
    justifyContent: "space-between", 
    alignItems: "center"
});

const BtnTitle = styled(Box)({
    fontWeight: 700,
    fontSize: "24px",
    color: "#375280",
    fontFamily: "Lato",
    padding: "15px 0",
    display: "flex", 
    justifyContent: "space-between", 
    alignItems: "center"
});

const LeftBox = styled(Box)({
    background: "white",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    padding: "20px 30px",
    margin: "0 20px"
})

const PageWrapper = styled(Box)({
    background: "#F8F8F8"
})
const ImageCollage = styled(Box)({
    "& .largeImage": {
        width: "100%",
        height: "296px",
        objectFit: "cover",
        padding: "0px 16px 8px 0px", 
    },
    "& .smallImage": {
        width: "100%",
        height: "140px",
        objectFit: "cover",
    },
});

const StylishCard = styled(Card)({
    position: "relative",
    height: "207px",
    "& .favBtn": {
        right: '15px', 
        top: '15px', 
        padding: '5px', 
        position: 'absolute', 
        background: 'white', 
        "& svg": {
            fill:"#375280",
        }
    },
    "& .cardContent": {
        padding: 0,
    },
    "& .content": {
        fontFamily: 'Lato', 
        color: 'white' , 
        padding: "10px",
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '64px',
        background: 'linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
        "& .title": {
            fontWeight: 700, 
            fontSize: '16px', 
            lineHeight: '26px', 
            marginBottom: '4px',
        }, 
        "& .description": {
            fontWeight: 400, 
            fontSize: '12px', 
            lineHeight: '14.4px' ,
            color: "white"
        }
    }
})

const RequestedStylist = styled(Box)({
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 45px',
    backgroundColor: '#fff',
    borderRadius: '2px',
    marginBottom: "16px",
    "& .actions": {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '8px',
        width: "50%",
        paddingRight: '16px',
    },
    "& .deleteBtn": {
        width: "150px", 
        color: "#F87171", 
        border: "1px solid #F87171", 
        padding: "8px",
        fontSize: "16px", 
        textTransform: "none", 
        marginRight: "16px",
        cursor: "pointer"
    },
    "& .viewButton": {
        color: "white",
        backgroundColor: '#c6b4a6',
        width: "150px",
        padding: "8px",
        fontSize: "16px",
        textTransform: "none",
        boxShadow: "none",
        cursor: "pointer",
        '&:hover': {
            boxShadow: "none",
            backgroundColor: '#b39a89',
        },
    },
})
const ProductCard = styled(Card)({
    boxShadow: "none",
    marginBottom: "20px",
    "& .card": {
        display: 'flex',
        marginBottom: '16px',
    },
    "& .image": {
        height: "150px",
        width: "200px", 
        objectFit: "cover"
    },
    "& .media": {
        width: 150,
    },
    "& .content": {
        display: "flex",
        width: "45%",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 45px",
        "& .title": {
            fontFamily: 'Lato',
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '24px',
            color: '#375280',
            marginBottom: '12px',
            maxWidth: "145px"
        },
        "& .quotes": {
            borderRadius: '2px',
            padding: '6px 10px',
            gap: '6px',
            background: '#E2E8F0',
            fontFamily: 'Lato',
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '18px',
            marginBottom: '8px',
            color: '#375280',
        }, 
        "& .fees": {
            fontFamily: 'Lato',
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '26px',
            marginBottom: '12px',
            color: '#375280',
        }
    },
    "& .actions": {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingRight: '16px',
        paddingBottom: '8px',
        width: "30%"
    },
    "& .deleteBtn": {
        color: "#F87171", 
        border: "1px solid #F87171", 
        width: "45%", 
        padding: "8px",
        textTransform: "none", 
        marginRight: "12px",
        fontSize: "16px", 
        cursor: "pointer"
    },
    "& .viewButton": {
        backgroundColor: '#c6b4a6',
        width: "55%",
        color: "white",
        padding: "8px",
        textTransform: "none",
        boxShadow: "none",
        fontSize: "16px",
        cursor: "pointer",
        '&:hover': {
            backgroundColor: '#b39a89',
            boxShadow: "none",
        },
    },
})

const ProductSource = styled(Box)({
    "& .sourceButton": {
        marginBottom: '16px',
        backgroundColor: '#c6b4a6',
        color: "white",
        padding: "8px 12px",
        textTransform: "none",
        boxShadow: "none",
        fontWeight: "700", 
        fontSize: "16px",
        cursor: "pointer",
        '&:hover': {
            backgroundColor: '#b39a89',
            boxShadow: "none",
        },
    },
})

const StylistCardContainer = styled(Card)({
    borderRadius: '0px',
    padding: '0px',
    boxShadow: "none",
    '& .avatar-box': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '16px',
    },
    '& .avatar': {
      width: 56,
      height: 56,
      marginRight: '12px',
    },
    '& .name-text': {
      fontWeight: 600,
    },
    '& .available-text': {
      color: '#059669',
      fontSize: '14px',
    },
    '& .description': {
      marginTop: '16px',
      color: '#6B7280',
      fontFamily: "Lato"
    },
    '& .action-box': {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '16px 0 0',

    },
    '& .action-button': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: "column", 
      justifyContent: "space-between", 
      width: "50%", 
      "&:first-child": {
        borderRight: "1px solid #E2E8F0"
      },
      "& p": {
        fontFamily: 'Lato',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '24px',
        textAlign: 'center',
        color: '#375280',
      }
    },
    '& .icon': {
      marginRight: '8px',
      fill: "#375280", 
      height: '25px',
      marginBottom: '10px',  
    },
    '& .hire-button': {
      backgroundColor: '#D1BEA8',
      textTransform: "none",
      fontSize: "20px",
      color: 'white',
      fontWeight: 'bold',
      height: '56px',   
      padding: '10px 16px', 
      borderRadius: '2px 0px 0px 0px',
      "& span": {
        borderRadius: '2px 0px 0px 0px',
      },
      '&:hover': {
        backgroundColor: '#b59f8d',
      },
    },
    '& .favorite-icon':{
        marginLeft: 'auto',
    '& svg': {
      fill: "#375280"
    }},
  });

const ProductCardWrapper = styled(Box)({
    position: "relative",
    width: "100%",
    borderRadius: "2px",
    backgroundColor: "#fff",
    transition: "0.3s ease-in-out",
    cursor: "pointer",
  
    "& .discountBadge": {
      position: "absolute",
      top: "15px",
      left: "15px",
      backgroundColor: "#fff",
      color: "#375280",
      fontSize: "12px",
      fontWeight: "700",
      padding: "4px 8px",
      borderRadius: "2px",
      fontFamily: "Lato"
    },
  
    "& .favoriteIcon": {
      position: "absolute",
      right: "15px",
      fontSize: "24px",
      color: "#375280",
      width: "30px",
      height: "30px",
      top: "15px",
      padding: "8px",
      background: "white"
    },
  
    "& .productImage": {
      width: "100%",
      height: "350px",
      objectFit: "cover"
    },
  
    "& .productInfo": {
      padding: "16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& .productName": {
        color: "#375280",
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 700, 
        lineHeight: "19.2px",
        textAlign: "left",
        margin: "10px 0 5px"
      },
      "& .brandName": {
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 500, 
        lineHeight: "16.8px",
        textAlign: "left",
        margin: "5px 0 10px"
      },
      "& .price": {
        backgroundColor: '#F8F8F8',
        color: '#375280',
        fontFamily: 'Lato',
        padding: '10px',
        fontWeight: "700", 
        borderRadius: "8px"
      },
    },
})

const CardWrapper = styled(Card)({
    maxWidth: '400px',
    borderRadius: '2px',
    padding: '20px',
    border: "1px solid #D5D5D5",
    boxShadow: "none",
    "& .content": {
        padding: "0px"
    },
    "& .titleRow": {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '8px',
    },
    "& .title": {
        fontFamily: 'Lato',
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '24px',
        textAlign: 'left',
        color: "#375280"
    },
    "& .price": {
        fontFamily: 'Lato',
        fontSize: '20px',
        fontWeight: 800,
        lineHeight: '24px',
        textAlign: 'right',
        color: '#375280',
    },
    "& .divider": {
      height: '1px',
      backgroundColor: '#e0e0e0',
      margin: '16px 0',
    },
    "& .featureList": {
      padding: '0 20px',
      margin: '0',
      listStyle: 'disc',
      color: '#6b6b6b',
      fontFamily: 'Lato',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '24px',
      textAlign: 'left',
    },
    "& .featureItem": {
      padding: '2px 0',
      fontSize: '16px',
      color: '#375280',
    },
    "& .planButton": {
        backgroundColor: '#cbb9a8',
        color: 'white',
        marginTop: '70px',
        borderRadius: '2px',
        textTransform: "none",
        fontFamily: 'Lato',
        fontSize: '20px',
        fontWeight: 800,
        lineHeight: '26px',
        letterSpacing: '0.01em',
        padding: "10px",
        '&:hover': {
            backgroundColor: '#bda893',
        },
    },
})
  
// Customizable Area End
