import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import i18n from "../../../components/src/i18next/i18n";
import {
  getStorageData,
  setStorageData
} from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userType: string;
  language: string;
  currency: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SplashScreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      userType: "0",
      language: "en",
      currency: "0"
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    const currentLang = await getStorageData("lang");
    if (currentLang) {
      this.setState({ language: currentLang });
      i18n.changeLanguage(currentLang);
    }
  }

  handleSubmit = async () => {
    const { userType, language, currency } = this.state;
    if (!userType || !currency || !language) {
      return;
    }
    await setStorageData("lang", language);
    i18n.changeLanguage(language);
    this.openLoginScreen();
  };

  onChangeHandler = (stateKey: keyof S, value: string) => {
    // @ts-ignore
    this.setState({ [stateKey]: value });
  };

  openRegScreen = async () => {
    const tempRole = {
      userType: this.state.userType,
      language: this.state.language,
      currency: this.state.currency
    };
    await setStorageData("userRole", JSON.stringify(tempRole));
    const signupRole = new Message(getName(MessageEnum.NavigationMessage));
    signupRole.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    signupRole.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountRegistration"
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationPayLoadMessage),
      this.props
    );
    signupRole.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseMessage
    );
    runEngine.sendMessage(signupRole.id, signupRole);
  };

  openLoginScreen = async () => {
    const localStorageData = {
      userType: this.state.userType,
      language: this.state.language,
      currency: this.state.currency
    };
    await setStorageData("userRole", JSON.stringify(localStorageData));
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLoginBlock"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };
  // Customizable Area End
}
